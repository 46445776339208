import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdHelp,
} from "react-icons/io";
import { useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import Loader from "@/components/v1/Common/Loader";
import Button from "@/components/v1/Common/Button";
import Platform from "@/model/api/Platform";
import MaintenanceRecord from "@/model/api/Maintenance";
import MaintenanceItem from "../ItemCards/MaintenanceItem";
import MaintenanceForm from "../MaintenanceForm";

interface UserAccessState {
  userAccess: UserAccess;
}
interface MaintenanceDialogProps {
  api: any;
  platform: Platform;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onConfirmation?: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
}

export default function MaintenanceDialog({
  api,
  platform,
  showMessage,
  onConfirmation,
}: MaintenanceDialogProps) {
  const [isAdd, setIsAdd] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [maintenanceList, setMaintenanceList] = useState<MaintenanceRecord[]>(
    []
  );
  const [selectedMaintenanceRecord, setSelectedMaintenanceRecord] =
    useState<MaintenanceRecord>({
      record_uuid: "",
      platform_uuid: "",
      maintenance_date: "",
      purpose: "",
      defect: "",
      rectification: "",
      conducted_by: "",
      time_created: "",
    });

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const getMaintenanceList = async () => {
    try {
      setIsLoading(true);
      const { platform_uuid } = platform;
      const res = await api.getMaintenanceData({ platform_uuid });

      const sortedMaintenanceData = res.data.sort(
        (a: MaintenanceRecord, b: MaintenanceRecord) => {
          const timeStartA = new Date(a.maintenance_date);
          const timeStartB = new Date(b.maintenance_date);
          return timeStartB.getTime() - timeStartA.getTime();
        }
      );
      setMaintenanceList(sortedMaintenanceData);
    } catch (err) {
      let message = "Error obtaining maintenance data";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      setIsLoading(false);
      showMessage && showMessage(message, false, true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!platform) return;
    getMaintenanceList();
  }, []);

  const handleSelectMaintenanceRecord = (
    maintenanceRecord: MaintenanceRecord
  ) => {
    setIsEditMode(true);
    setSelectedMaintenanceRecord(maintenanceRecord);
    setIsAdd(true);
  };

  const handleSelectedItem = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    }
  };

  const handleNewMaintenanceClick = () => {
    setIsEditMode(false);
    setSelectedMaintenanceRecord({
      record_uuid: "",
      platform_uuid: platform.platform_uuid,
      maintenance_date: "",
      purpose: "",
      defect: "",
      rectification: "",
      conducted_by: "",
      time_created: "",
    });
    setIsAdd(true);
  };

  const handleDeleteItem = () => {
    if (onConfirmation) {
      onConfirmation(
        "Delete Confirmation",
        "Are you sure you want to delete the selected item?",
        (result) => {
          if (result) {
            deleteSelectedItems();
          }
        }
      );
    }
  };

  const deleteSelectedItems = async () => {
    setIsLoading(true);
    showMessage && showMessage("Deleting items... Please wait");

    try {
      let isAPIError = false;

      const promises: Promise<void>[] = selectedIds.map(async (item) => {
        let success = false;
        success = await api.deleteMaintenance(item);
        if (!success) {
          isAPIError = true;
        }
      });

      await Promise.all(promises);
      getMaintenanceList();

      if (isAPIError) {
        showMessage && showMessage("Error deleting items", false, true);
      } else {
        showMessage && showMessage("Items deleted successfully", true);
      }

      setSelectedIds([]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showMessage && showMessage("Error deleting items", false, true);
    }
  };

  const handleSaveSuccess = () => {
    setIsEditMode(false);
    setSelectedMaintenanceRecord({
      record_uuid: "",
      platform_uuid: "",
      maintenance_date: "",
      purpose: "",
      defect: "",
      rectification: "",
      conducted_by: "",
      time_created: "",
    });
    setIsAdd(false);
    getMaintenanceList();
  };
  return (
    <div className="flex-col h-[620px]" onClick={(e) => e.stopPropagation()}>
      <div
        className={`flex-col ${isAdd ? "h-[100%]" : "h-[85%]"} overflow-auto`}
      >
        {!isAdd &&
          !isLoading &&
          maintenanceList.length > 0 &&
          maintenanceList.map((singleMaintenanceRecord) => (
            <MaintenanceItem
              key={singleMaintenanceRecord.record_uuid}
              data={singleMaintenanceRecord}
              onClick={handleSelectMaintenanceRecord}
              onSelect={handleSelectedItem}
            />
          ))}

        {!isAdd && !isLoading && maintenanceList.length === 0 && (
          <p className="p-8 text-center">No Data Available</p>
        )}
        {!isAdd && (
          <Loader isLoading={isLoading} isError={false} errorText="" />
        )}
        {/* Forms */}
        {isAdd && (
          <div className="flex-col h-[100%] overflow-auto">
            {/* Navigation & Form Title */}
            <div className="flex py-2 items-center content-center">
              <button onClick={() => setIsAdd(false)} className="p-2">
                <IoIosArrowBack size={24} className="m-auto" />
              </button>
              <h5 className="font-medium ml-2">
                {platform?.platform_callsign}
              </h5>
            </div>
            <hr />
            <MaintenanceForm
              api={api}
              maintenanceRecord={selectedMaintenanceRecord}
              platformUuid={platform.platform_uuid}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              hasWritePrivilege={userAccess.privileges.includes(
                "assets.maintenance.write"
              )}
              isEditMode={isEditMode}
            />
          </div>
        )}
      </div>
      {!isAdd && userAccess.privileges.includes("assets.maintenance.write") && (
        <div className="h-[10%] px-4 items-center content-center">
          {selectedIds.length === 0 ? (
            <Button
              type="primary"
              size="medium"
              text="New Maintenance"
              className="my-5"
              isLoading={false}
              disabled={false}
              onClick={handleNewMaintenanceClick}
            />
          ) : (
            <Button
              type="danger"
              size="medium"
              text="Delete Record"
              className="my-5"
              isLoading={false}
              disabled={false}
              onClick={handleDeleteItem}
            />
          )}
        </div>
      )}
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import Tracker from "@/model/api/Tracker";
import useWebsocket from "@/hooks/useWebsocket";
import { channels } from "@/config/channels";
import { useSelector } from "react-redux";
import { messageTypes } from "@/config/messageTypes";
import { parseJwt } from "@/api/operator";
import { OperationJson } from "@/model/api/Operation";
import { MdOutlineSensors, MdOutlineSensorsOff } from "react-icons/md";
import Loader from "@/components/v1/Common/Loader";
import TrackerMonitorItem from "./TrackerMonitorItem";
import PuckServer from "../../../../../services/puckserver";

interface TrackerMonitoringProps {
  api: any;
  trackers: Tracker[];
  selectedFlightData: OperationJson[];
  operationsSummary: number;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
}

export default function TrackerMonitoring({
  api,
  trackers,
  selectedFlightData,
  operationsSummary: operationSummary,
  showMessage,
}: TrackerMonitoringProps) {
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });
  const envVar = useSelector((state: any) => state.envVar);

  const [env, setEnv] = useState("");

  /* API State */
  const [loadingPlatforms, setLoadingPlatforms] = useState(false);
  const [isError, setIsError] = useState(false);

  const [trackerList, setTrackerList] = useState<Tracker[]>(trackers);
  const [monitoringTrackerList, setMonitoringTrackerList] = useState<any>([]); // trackers that are enabled in tracker tab
  const [trackerOperationUuid, setTrackerOperationUuid] = useState<any>({}); // relation between tracker uuid and operation uuid

  /* Telemetry */
  const [telemetryData, setTelemetryData] = useState({});
  const [recievingTelemetry, setRecievingTelemetry] = useState<any[]>([]);
  const [recievingTelemetryNoCoordinates, setRecievingTelemetryNoCoordinates] =
    useState<any[]>([]);
  const [timerId, setTimerId] = useState();

  /* Toggle Tracker State  */
  const [enableTrackerOverlay, setEnableTrackerOverlay] = useState<any>([]);
  const [disableTrackerOverlay, setDisableTrackerOverlay] = useState<any>([]);

  const [pilotUuid, setPilotUuid] = useState<string>("");

  /* Ref */
  const selectedTrackerDataRef = useRef([]);
  const selectedPlatformDataRef = useRef([]);
  const keyRef = useRef<any>({});
  const teleObjRef = useRef<any>({});

  const userAccess = useSelector((state: any) => state.userAccess);

  const checkSensorType = (tracker: Tracker): React.ReactNode => {
    if (recievingTelemetry.includes(tracker.tracker_sn)) {
      if (recievingTelemetryNoCoordinates.includes(tracker.tracker_sn))
        return <MdOutlineSensors color="#FFBF00" size={24} />;
      return <MdOutlineSensors color="#2a9461" size={24} />;
    }
    return (
      <MdOutlineSensorsOff key={`${tracker.tracker_sn}-offswitch`} size={24} />
    );
  };

  const checkTelemetryData = () => {
    if (Object.keys(teleObjRef.current).length > 0) {
      const telemetryDataEdited: any[] = [];
      const telemetryNoCoordinatesEdited: any[] = [];
      const currentTime = Date.now();
      Object.keys(teleObjRef.current).forEach((singleTelemetry) => {
        // check if message older than 20 seconds
        const timePassed =
          currentTime - teleObjRef.current[singleTelemetry].timestamp;
        if (timePassed < 20 * 1000) {
          telemetryDataEdited.push(
            teleObjRef.current[singleTelemetry].trackerSn
          );
        }
        if (
          teleObjRef.current[singleTelemetry].puckResponses[0][1].position
            .lat === null ||
          teleObjRef.current[singleTelemetry].puckResponses[0][1].position
            .lng === null ||
          (teleObjRef.current[singleTelemetry].puckResponses[0][1].position
            .lat === 0 &&
            teleObjRef.current[singleTelemetry].puckResponses[0][1].position
              .lng === 0)
        )
          telemetryNoCoordinatesEdited.push(
            teleObjRef.current[singleTelemetry].trackerSn
          );
      });
      setRecievingTelemetry(telemetryDataEdited);
      setRecievingTelemetryNoCoordinates(telemetryNoCoordinatesEdited);
    } else {
      setRecievingTelemetry([]);
      setRecievingTelemetryNoCoordinates([]);
    }
  };

  const getTrackerStatus = async () => {
    try {
      // not accessable by airspace manager so no need to account for api.aas.getAllOperationSelection
      const response = await api.getAllOperationSelection();
      if (!response.data) return;
      const listOfMonitoredTrackers = response.data.map(
        (singleOperation: any) => {
          if (
            singleOperation.operation_state === "Activated" &&
            singleOperation.tag === "rid"
          ) {
            return singleOperation.operation_json.request.tracker_uuid[0];
          }
          return "";
        }
      );
      setMonitoringTrackerList(listOfMonitoredTrackers);
      const trackerOperationList: any = {};
      response.data.forEach((singleOperation: any) => {
        if (singleOperation.operation_state === "Activated") {
          trackerOperationList[
            singleOperation.operation_json.request.tracker_uuid[0]
          ] = singleOperation.operation_uuid;
        }
        return "";
      });
      const updatedTrackerOperationUuid = {
        ...trackerOperationList,
      };
      setTrackerOperationUuid(updatedTrackerOperationUuid);
    } catch (e) {
      console.log("Error ", e);
    }
  };

  const getTrackers = async () => {
    try {
      setLoadingPlatforms(true);

      const response = await api.getTracker();
      if (response.data) {
        // response.data.sort(compare);
        setTrackerList(response.data);
        setLoadingPlatforms(false);
      }
    } catch (err: any) {
      setLoadingPlatforms(false);
      setIsError(true);
      showMessage && showMessage(err.message, false, true);
    }
  };

  const pairUserTracker = async (tracker: Tracker) => {
    const tokenData = parseJwt(api.jwtToken);
    const trackerUserPairing = {
      tracker_sn: tracker.tracker_sn,
      approved_user_list: [
        {
          user: tokenData.username,
          userGroups: tokenData["cognito:groups"],
        },
      ],
      env,
    };
    try {
      // user pairing testing
      const puckResponse = await PuckServer.pairUserTracker(trackerUserPairing);
      if (!puckResponse) {
        showMessage("Error Pairing Tracker", false, true);
      }
    } catch (e) {
      showMessage("Error Pairing Tracker", false, true);
    }
  };

  const unPairUserTracker = async (tracker: Tracker) => {
    try {
      const puckResponse = await PuckServer.unpairUserTracker(
        tracker.tracker_sn
      );
      if (!puckResponse) {
        showMessage("Error Unpairing Tracker", false, true);
      }
    } catch (e) {
      showMessage("Error Unpairing Tracker", false, true);
    }
  };

  const handleToggleMonitor = async (tracker: Tracker, enabled: boolean) => {
    // setLoadingPlatforms(true);
    if (enabled) {
      const enableOverlay: any = [...enableTrackerOverlay, tracker.tracker_sn];
      setEnableTrackerOverlay(enableOverlay);
      pairUserTracker(tracker);
      const depatureTime = new Date();
      depatureTime.setMinutes(depatureTime.getMinutes() + 5);
      const arrivalTime = new Date();
      arrivalTime.setMinutes(arrivalTime.getMinutes() + 10);
      const operationDetails = {
        ...tracker,
        depatureTime: depatureTime.toISOString(),
        arrivalTime: arrivalTime.toISOString(),
      };
      const sampleOperation = createSampleOperation(operationDetails);
      try {
        const submitOperationResponse = await api.submitOperation(
          sampleOperation
        );

        if (submitOperationResponse?.status === 201) {
          const takeoffOperationResponse = await api.updateOperation(
            submitOperationResponse?.data?.operations[0]?.reference?.id,
            "Activated"
          );
          if (
            takeoffOperationResponse?.status === 200 ||
            takeoffOperationResponse?.status === 201
          ) {
            showMessage("Monitoring Enabled", true);

            const filteredEnableOverlay = enableOverlay.filter(
              (element: any) => element !== tracker.tracker_sn
            );
            setEnableTrackerOverlay(filteredEnableOverlay);

            const updatedMonitoringTrackerList = [
              ...monitoringTrackerList,
              tracker.tracker_uuid,
            ];
            setMonitoringTrackerList(updatedMonitoringTrackerList);
          }
        }
      } catch (e) {
        console.log("Error ", e);
        showMessage && showMessage("Error Enabling Monitoring", false, true);
        const filteredEnableOverlay = enableOverlay.filter(
          (element: any) => element !== tracker.tracker_sn
        );
        setEnableTrackerOverlay(filteredEnableOverlay);
      }
    } else {
      const disableOverlay: any = [
        ...disableTrackerOverlay,
        tracker.tracker_sn,
      ];
      setDisableTrackerOverlay(disableOverlay);
      try {
        await unPairUserTracker(tracker);
        const endedOperationResponse = await api.updateOperation(
          trackerOperationUuid[tracker.tracker_uuid],
          "Ended"
        );
        if (endedOperationResponse?.status === 200) {
          showMessage && showMessage("Monitoring Disabled", true);
        }
      } catch (e) {
        console.log("error ", e);
        showMessage && showMessage("Error Disabling Monitoring", false, true);
        const filteredDisableOverlay = disableOverlay.filter(
          (element: any) => element !== tracker.tracker_sn
        );
        setDisableTrackerOverlay(filteredDisableOverlay);
      }
    }
    // prevent error of user turn and and off monitor and TrackerOperationUuid not populated
    getTrackerStatus();
    // getTrackers();
  };

  const createSampleOperation = (trackerInfo: any) => {
    return {
      pilot_uuid: [pilotUuid],
      platform_uuid: [process.env.REACT_APP_RID_OPERATION_PLATFORM_UUID],
      tracker_uuid: [trackerInfo.tracker_uuid],
      waypoints: [
        [124.14698913971807, 84.02912511174881],
        [124.15454652184047, 84.02924605527377],
      ],
      vertical_buffer: 25,
      lateral_buffer: 25,
      time_buffer: 30,
      elevation: 0,
      max_segment_length: 500,
      airspace_optimised: false,
      altitude: 60,
      altitude_reference: "W84",
      time_start: trackerInfo.depatureTime,
      time_end: trackerInfo.arrivalTime,
      ground_speed: 5,
      operation_type: "vlos",
      description: `Monitor: ${trackerInfo.tracker_name}`,
      contingency_plans: {
        landing_point: [[124.14698913971807, 84.02912511174881]],
        safe_altitude: 60,
      },
      tag: "rid",
    };
  };

  const getPilotUuid = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const { username } = user;
    const response = await api.getPilots();
    const pilot = response.data.find(
      (singlePilot: any) => singlePilot.pilot_username === username
    );
    setPilotUuid(pilot.pilot_uuid);
  };

  useEffect(() => {
    getPilotUuid();
  }, [Auth]);

  useEffect(() => {
    getTrackerStatus();
    checkTelemetryData();
  }, [operationSummary]);

  useEffect(() => {
    const checkTrackerImei = (trackerSn: string) => {
      const dataTracker: any[] = [];
      selectedTrackerDataRef.current.map((data: any) => {
        return data.map((datas: any) => {
          return dataTracker.push(datas);
        });
      });
      const filterDataTracker = dataTracker.filter(function (tracker) {
        return tracker.data.tracker_sn === trackerSn;
      });
      return filterDataTracker;
    };
    const handleMessage = (data: any) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid: string) => {
          const dataPlatform: any[] = [];
          selectedPlatformDataRef.current.map((p: any) => {
            return p.map((datas: any) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (platform) {
            return platform.data.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        const key = [data.extras.tracker_sn, data.operation_id].join("/");
        if (!keyRef.current[key]) {
          // making sure no duplicate keys in keyRef
          keyRef.current = {
            ...keyRef.current,
            [key]: true,
          };
        }
        // setTeleObj({
        //   ...teleObj,
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerSn: data.tracker_sn,
            trackerImei: data.extras?.imei,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.imei),
                checkTrackerImei(data.extras.imei)
                  ? checkTrackerImei(data.extras.imei)
                  : [],
              ],
            ],
          },
        };
        setTelemetryData(teleObjRef.current);
      }
    };

    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      handleMessage
    );

    return () => {
      removeListener?.();
    };
  }, [channel, userAccess]);

  useEffect(() => {
    if (enableTrackerOverlay.length > 0 || disableTrackerOverlay.length > 0) {
      const listOfOperations = selectedFlightData.map((singleFlightData) => {
        // const trackerSn = singleFlightData?.details?.platform_tracker_pairs[0].tracker_sn;
        return singleFlightData?.details?.platform_tracker_pairs[0].tracker_sn;
      });
      const enableTrackerList = enableTrackerOverlay.filter(
        (element: any) => !listOfOperations.includes(element)
      );
      const disableTrackerList = disableTrackerOverlay.filter((element: any) =>
        listOfOperations.includes(element)
      );

      setEnableTrackerOverlay(enableTrackerList);
      setDisableTrackerOverlay(disableTrackerList);
    }
  }, [selectedFlightData]);

  useEffect(() => {
    // after 10 seconds of not recieving telemetry, will clear data. does not trigger if constantly recieve telemetry
    if (timerId) clearTimeout(timerId);
    checkTelemetryData();
    const timeoutAfterNoTelemetry: any = setTimeout(() => {
      setRecievingTelemetry([]);
      setRecievingTelemetryNoCoordinates([]);
    }, 1000 * 20);

    setTimerId(timeoutAfterNoTelemetry);

    return () => {
      clearTimeout(timeoutAfterNoTelemetry);
    };
  }, [telemetryData]);

  useEffect(() => {
    const currenteEnvironment =
      envVar["base_url-sp"].Value === "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";
    setEnv(currenteEnvironment);
  }, []);

  return (
    <div className="flex-col">
      {trackerList.map((tracker) => (
        <TrackerMonitorItem
          key={tracker.tracker_uuid}
          data={tracker}
          checked={monitoringTrackerList.includes(tracker.tracker_uuid)}
          isLoading={
            enableTrackerOverlay.includes(tracker.tracker_sn) ||
            disableTrackerOverlay.includes(tracker.tracker_sn)
          }
          icon={checkSensorType(tracker)}
          onChange={(isChecked) => handleToggleMonitor(tracker, isChecked)}
        />
      ))}
      <Loader isLoading={loadingPlatforms} isError={isError} />
    </div>
  );
}

import React, { useState } from "react";
import BellNotificationType from "@/model/api/BellNotification";
import { format, isSameDay } from "date-fns";
import { IoIosAlert } from "react-icons/io";
import Button from "../Common/Button";

interface NotificationDropdownProps {
  bellNotifications?: BellNotificationType[];
  className?: string;
  onTabSelected?: (tab: number) => void;
  handleGetOlderBellNotificationsClick: () => void;
}

export default function NotificationDropdown({
  className,
  bellNotifications,
  handleGetOlderBellNotificationsClick,
}: NotificationDropdownProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const switchTab = (tab: number) => {
    setSelectedTab(tab);
  };

  const hexToColorMap = {
    GREEN: "#95f985",
    YELLOW: "#FFF300",
    RYELLOW: "#ffff01",
    ORANGE: "#ffa500",
    RED: "#AA4A44",
    BLUE: "#9FC5EC",
    GREY: "#dddddd",
  };

  const getSeverityColor = (severity: string) => {
    if (severity === "Information") {
      return hexToColorMap.BLUE;
    }
    if (severity === "Informational") {
      return hexToColorMap.BLUE;
    }
    if (severity === "Error") {
      return hexToColorMap.YELLOW;
    }
    if (severity === "Important") {
      return hexToColorMap.RYELLOW;
    }
    if (severity === "Warning") {
      return hexToColorMap.ORANGE;
    }
    if (severity === "Emergency") {
      return hexToColorMap.RED;
    }
    // return if none of the severities match, should not occur
    return "#dddddd";
  };

  const formatNotificationMessage = (
    row: BellNotificationType,
    todayMessagesOnly: boolean
  ) => {
    try {
      const key = row.SK;
      const rowInfoSplit = row.SK.split("#");
      const ts = rowInfoSplit[1];
      const severity = rowInfoSplit[5];
      const type = rowInfoSplit[3];
      const { message } = row.data;
      const datetime = format(Number.parseInt(ts, 10), "yyyy-MM-dd HH:mm:ss");
      const severityColor = getSeverityColor(severity);
      const parsedDatetime = new Date(datetime);
      const isToday = isSameDay(parsedDatetime, new Date());
      if (todayMessagesOnly && !isToday) return;
      return {
        severity,
        severityColor,
        message,
        datetime,
        type,
        isToday,
        key,
      };
    } catch (e) {
      console.log(e);
    }
    return {};
  };

  return (
    <div className={`${className} notification-dropdown`}>
      <h5 className="font-medium mx-4 mt-4 flex items-center">
        Notifications
        <span className="flex-grow" />
        <button onClick={handleGetOlderBellNotificationsClick}>
          <span className="text-primary-500 text-sm mr-2">Get Older Logs</span>
        </button>
      </h5>
      <div className="classic-tab">
        <ul>
          <li>
            <button onClick={() => switchTab(0)}>
              <span
                className={
                  selectedTab === 0
                    ? "classic-tab-item-active"
                    : "classic-tab-item"
                }
              >
                Today
              </span>
            </button>
          </li>
          <li className="me-2">
            <button onClick={() => switchTab(1)}>
              <span
                className={
                  selectedTab === 1
                    ? "classic-tab-item-active"
                    : "classic-tab-item"
                }
              >
                All
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div className="h-[84%] flex-col overflow-auto p-4">
        {bellNotifications?.map((singleNotification) => {
          const bellNotification = formatNotificationMessage(
            singleNotification,
            selectedTab === 0
          );
          if (!bellNotification) return;
          return (
            <div
              className="p-4 rounded-container flex mb-2 cursor-pointer"
              key={bellNotification.key}
            >
              <div className="flex flex-col">
                <p className="text-sm mb-2 flex items-center space-x-1">
                  <IoIosAlert
                    style={{ color: bellNotification.severityColor }}
                  />
                  <span>{bellNotification.datetime}</span>
                </p>
                <p className="text-sm">{bellNotification.message}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { Content } from "@/model/api/Permit";

interface PermitItemProps {
  data: Content;
  onClick: (data: Content) => void;
  onSelect: (id: string, isSelected: boolean) => void;
}

export default function PermitItem({
  data,
  onClick,
  onSelect,
}: PermitItemProps) {
  const extractFileName = (filePath: string) => {
    const parts = filePath.split("/");
    const lastPart = parts[parts.length - 1];

    const fileName = lastPart.replace(
      / \d{4}-\d{2}-\d{2} \d{4}-\d{2}-\d{2}/,
      ""
    );

    return fileName;
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(data.Key, e.target.checked);
  };

  return (
    <div className="relative p-4 rounded-container mb-2 cursor-pointer">
      <div className="flex-col" key={data.Key} onClick={() => onClick(data)}>
        <h5 className="font-medium">{extractFileName(data.Key)}</h5>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}

{
  /* <div className="relative p-4 rounded-container mb-2 cursor-pointer">
<div
  className="flex-col"
  key={data.platform_type_uuid}
  onClick={() => onClick(data)}
>
  <h5 className="font-medium">{data.model}</h5>
</div>
<input
  type="checkbox"
  className="input-checkbox absolute top-2 right-2"
  onChange={handleCheckboxChange}
/>
</div> */
}

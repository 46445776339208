import React, { useEffect, useState } from "react";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import UserAccess from "@/model/UserAccess";
import toast from "react-hot-toast";
import Button from "@/components/v1/Common/Button";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import InputWaypoint from "@/components/v1/InputWaypoint";
import LatLng from "@/model/LatLng";
import Waypoint from "@/model/Waypoint";

import { FaDrawPolygon, FaMapPin } from "react-icons/fa";
import { MdDisplaySettings } from "react-icons/md";
import { useSelector } from "react-redux";
import moment from "moment";
import { convertZuluToLocalTime } from "@/api/timeConvert";
import Constraint from "@/model/api/Constraint";
import axios from "axios";
import MapState from "@/model/MapState";
import { CreateOperationType } from "@/enum/CreateOperationType";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import { PiMapPinArea } from "react-icons/pi";
import { IoMdInformationCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { calculatePolygonFloorArea } from "@/utils/utils";
import { getFutureDate } from "@/utils/date_utils";
import { GroupedOperator } from "@/model/api/GroupedOperator";
import recurringConstraintMessageHandler from "./recurringConstraintMessageHandler";
import data from "./data";
import WhiteListContraintForm from "../WhitelistForm";
import ConstraintWhitelistModal from "../WhitelistModal";
import ReccuringConstraintForm from "./RecurringConstraintForm";

interface TimeCondition {
  format: string;
  value: string;
}

interface Condition {
  time_start: TimeCondition;
  time_end: TimeCondition;
  recurring: string;
}

interface RuleConditions {
  authorisation_conditions: Condition;
  main_conditions: Condition;
  prohibited_conditions: Condition;
}

interface OperationForm {
  name: string;
  description: string;
  altitude_reference: string;
  elevation: number;
  ceiling: number;
  time_start: string;
  time_end: string;
  rules: {
    prohibited: boolean;
    authorisation_required: boolean;
    conditional: boolean;
    information_only: boolean;
  };
  prohibited_additional: {
    users: string[];
    groups: string[];
    time_start: string;
    time_end: string;
  };
  authorisation_additional: {
    users: string[];
    groups: string[];
    time_start: string;
    time_end: string;
  };
  conditional_additional: {
    no_camera: boolean;
    power_tethered: boolean;
    noise_threshold: number;
    others: string;
  };
}

interface UserAccessState {
  userAccess: UserAccess;
}

type RecurrenceRuleTypes = "main" | "authrequired" | "prohibited";

interface CreateConstraintFormFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  selectedAreaWaypoints: number[][];
  // selectedCircleWaypoint: number[][];
  // selectedCircleProperties: SelectedCircleProperties;
  // className?: string;
  // pilots: Pilot[];
  // platforms: Platform[];
  // trackers: Tracker[];
  /* Circle Drawing */
  selectedCircleWaypoints: number[][];
  setSelectedCircleWaypoints: (waypoints: number[][]) => void;
  selectedCircleProperties: SelectedCircleProperties;
  setSelectedCircleProperties: (properties: SelectedCircleProperties) => void;
  onConstraintSubmitted: () => void;
  /* Others */
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  editConstraintData?: Constraint;
  handleOperationFormWaypointChange: (
    formData: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => void;
}

export default function CreateConstraintForm({
  api,
  selectedAreaWaypoints,
  selectedCircleWaypoints,
  setSelectedCircleWaypoints,
  selectedCircleProperties,
  setSelectedCircleProperties,
  onConstraintSubmitted,
  onConfirmation,
  showMessage,
  goToLocation,
  onOpenModal,
  onCloseModal,
  editConstraintData,
  handleOperationFormWaypointChange,
}: CreateConstraintFormFormProps) {
  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );
  const envVar = useSelector((state: any) => state.envVar);
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  /* Input Coordinate Configuration */
  const [inputWaypointConfiguration, setInputWaypointConfiguration] = useState({
    keyLabel: "",
    addButtonText: "Add Waypoint",
    showRadius: true,
    allowMultiple: false,
    useTakeOffLandingPoint: false,
    minWaypointCount: 1,
    latLabel: "LAT (Center)",
    lngLabel: "LONG (Center)",
  });

  /* Form State */
  const [isLoading, setIsLoading] = useState(false);
  const [isPermanent, setIsPermanent] = useState(false);

  /* Operation Date */
  const [startDate, setStartDate] = useState<Date | null>(getFutureDate(3));
  const [endDate, setEndDate] = useState<Date | null>(getFutureDate(60));

  /* Coordinates */
  const [floorArea, setFloorArea] = useState<number>(0);
  const [areaWaypoints, setAreaWaypoints] = useState<number[][]>([]);
  const [operationWaypoints, setOperationWaypoints] = useState<Waypoint[]>(
    data.initialCircleWaypoint
  );

  /* WhiteList Data */
  const [operatorGroups, setOperatorGroups] = useState<string[]>([]);
  const [groupedOperators, setGroupedOperators] = useState<GroupedOperator[]>(
    []
  );

  /* Recurring Operation Data */
  const [recurrenceMainDescription, setRecurrenceMainDescription] =
    useState("");
  const [
    recurrenceAuthorizationRequiredDescription,
    setRecurrenceAuthorizationRequiredDescription,
  ] = useState("");
  const [recurrenceProhibitedDescription, setRecurrenceProhibitedDescription] =
    useState("");
  const [recurringRules, setRecurringRules] = useState<RuleConditions>({
    authorisation_conditions: {
      time_start: {
        format: "RFC3339",
        value: "",
      },
      time_end: {
        format: "RFC3339",
        value: "",
      },
      recurring: "",
    },
    main_conditions: {
      time_start: {
        format: "RFC3339",
        value: "",
      },
      time_end: {
        format: "RFC3339",
        value: "",
      },
      recurring: "",
    },
    prohibited_conditions: {
      time_start: {
        format: "",
        value: "",
      },
      time_end: {
        format: "RFC3339",
        value: "",
      },
      recurring: "",
    },
  });

  const { username } = useCognitoAuth();
  const spBaseUrl = envVar["base_url-sp"].Value;

  const handleWaypointChange = (waypoints: Waypoint[]) => {
    setOperationWaypoints(waypoints);

    const newWaypoints: number[][] = [];
    waypoints.forEach((waypoint) => {
      newWaypoints.push([waypoint.longitude, waypoint.latitude]);
    });
    setSelectedCircleWaypoints(newWaypoints);
  };

  const handleRadiusChange = (radius: number) => {
    if (selectedCircleProperties) {
      setSelectedCircleProperties({
        ...selectedCircleProperties,
        radiusInKm: radius,
      });
    }
  };

  const handleOnDraw = (drawMode: CreateOperationType) => {
    if (mapboxController) {
      mapboxController.removeDrawFeaturesAll();
      let message = "";
      if (drawMode === CreateOperationType.CIRCLE) {
        message = "Pick a center point on the map";
        mapboxController.changeDrawMode("draw_circle");
      } else if (drawMode === CreateOperationType.POLYGON) {
        message = "Draw polygon on the map";
        mapboxController.changeDrawMode("draw_polygon");
      }
      showMessage && showMessage(message);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "number"
          ? parseInt(e.target.value, 10)
          : e.target.value,
    });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: string
  ) => {
    if (
      type === "pilot_uuid" ||
      type === "platform_uuid" ||
      type === "tracker_uuid"
    ) {
      let { value } = e.target;
      if (e.target.value === "Select") {
        value = "";
      }
      setForm((prevForm) => ({
        ...prevForm,
        [type]: [value],
      }));
      return;
    }

    setForm({
      ...form,
      [type]: e.target.value,
    });
  };

  const handleRulesCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    setForm({
      ...form,
      rules: {
        ...form.rules,
        [name]: checked,
      },
    });
  };

  const handleDateChange = (date: Date | null, type: string) => {
    let formField = "";
    if (type === "start") {
      setStartDate(date);
      formField = "time_start";
    }
    if (type === "end") {
      setEndDate(date);
      formField = "time_end";
    }

    if (
      (!form.rules.prohibited || !form.rules.authorisation_required) &&
      date
    ) {
      const formattedDate = moment(date).toISOString();
      const updatedForm = {
        ...form,
        ...(!form.rules.prohibited && {
          prohibited_additional: {
            ...form.prohibited_additional,
            [formField]: formattedDate,
          },
        }),
        ...(!form.rules.authorisation_required && {
          authorisation_additional: {
            ...form.authorisation_additional,
            [formField]: formattedDate,
          },
        }),
        [formField]: formattedDate,
      };
      setForm(updatedForm);
    } else if (date) {
      const formattedDate = moment(date).toISOString();
      setForm({
        ...form,
        [formField]: formattedDate,
      });
    }
  };

  const handleSelectWhiteList = (type: string, whitelistFor: string) => {
    openWhiteListAutocomplete(type, whitelistFor);
  };

  const [form, setForm] = useState<OperationForm>({
    name: "",
    description: "",
    altitude_reference: "W84",
    elevation: 0,
    ceiling: 100,
    time_start: getFutureDate(3).toISOString(),
    time_end: getFutureDate(60).toISOString(),
    rules: {
      prohibited: false,
      authorisation_required: false,
      conditional: false,
      information_only: false,
    },
    prohibited_additional: {
      users: [],
      groups: [],
      time_start: getFutureDate(3).toISOString(),
      time_end: getFutureDate(60).toISOString(),
    },
    authorisation_additional: {
      users: [],
      groups: [],
      time_start: getFutureDate(3).toISOString(),
      time_end: getFutureDate(60).toISOString(),
    },
    conditional_additional: {
      no_camera: false,
      power_tethered: false,
      noise_threshold: 40,
      others: "",
    },
  });

  const [formError, setFormError] = useState({
    name: "",
    description: "",
    elevation: "",
    ceiling: "",
    time_start: "",
    time_end: "",
    waypoints: "",
  });

  const validateForm = () => {
    let isValid = true;
    const error = {
      name: "",
      description: "",
      elevation: "",
      ceiling: "",
      time_start: "",
      time_end: "",
      waypoints: "",
    };

    if (!form.name) {
      error.name = "Name is required";
      isValid = false;
    }

    // if (!form.description) {
    //   error.description = "Description is required";
    //   isValid = false;
    // }

    if (!form.time_start) {
      error.time_start = "Start Date Time is required";
      isValid = false;
    }

    if (!form.time_end) {
      error.time_end = "End Date Time is required";
      isValid = false;
    }

    if (Number.isNaN(form.elevation)) {
      error.elevation = "Elevation is required";
      isValid = false;
    }

    if (form.ceiling === 0 || Number.isNaN(form.ceiling)) {
      error.ceiling = "Ceiling is required";
      isValid = false;
    }

    const isValidWaypoints = operationWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidWaypoints) {
      error.waypoints = "Area is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleResetForm = () => {
    onConfirmation(
      "Reset Confirmation",
      "Are you sure you to reset the form ?",
      (result) => {
        if (result) {
          resetForm();
        }
      }
    );
  };

  const handleSubmitConstraint = async () => {
    if (!validateForm()) {
      showMessage && showMessage("Please fill all required fields");
      return;
    }

    showMessage && showMessage("Submitting Constraint, Please Wait");

    const extents = [
      {
        time_end: {
          format: recurringRules.main_conditions.recurring
            ? recurringRules.main_conditions.time_end.format
            : "RFC3339",
          value: recurringRules.main_conditions.recurring
            ? recurringRules.main_conditions.time_end.value
            : form.time_end,
        },
        time_start: {
          format: recurringRules.main_conditions.recurring
            ? recurringRules.main_conditions.time_start.format
            : "RFC3339",
          value: recurringRules.main_conditions.recurring
            ? recurringRules.main_conditions.time_start.value
            : form.time_start,
        },
        volume: {
          altitude_lower: {
            reference: form.altitude_reference,
            units: "M",
            value: form.elevation,
          },
          altitude_upper: {
            reference: form.altitude_reference,
            units: "M",
            value: form.ceiling,
          },
          outline_polygon: {
            coordinates: [areaWaypoints],
            type: "Polygon",
          },
        },
      },
    ];

    /* Handle Recurring Data */
    // move time start and end to {prohibited_conditions.recurrence_range} and modify original values
    let prohibited_conditions;

    if (recurringRules.prohibited_conditions.recurring) {
      prohibited_conditions = {
        whitelist: {
          users: form.prohibited_additional.users,
          usergroups: form.prohibited_additional.groups,
        },
        recurring: recurringRules.prohibited_conditions.recurring,
        time_end: recurringRules.prohibited_conditions.time_end,
        time_start: recurringRules.prohibited_conditions.time_start,
        recurrence_range: {
          time_start: {
            format: "RFC3339",
            value:
              form.prohibited_additional.time_start !== ""
                ? form.prohibited_additional.time_start
                : form.time_start,
          },
          time_end: {
            format: "RFC3339",
            value:
              form.prohibited_additional.time_end !== ""
                ? form.prohibited_additional.time_end
                : form.time_end,
          },
        },
      };
    } else {
      prohibited_conditions = {
        whitelist: {
          users: form.prohibited_additional.users,
          usergroups: form.prohibited_additional.groups,
        },
        time_start: {
          format: "RFC3339",
          value:
            form.prohibited_additional.time_start !== ""
              ? form.prohibited_additional.time_start
              : form.time_start,
        },
        time_end: {
          format: "RFC3339",
          value:
            form.prohibited_additional.time_end !== ""
              ? form.prohibited_additional.time_end
              : form.time_end,
        },
      };
    }

    let authorisation_conditions;

    if (recurringRules.authorisation_conditions.recurring) {
      authorisation_conditions = {
        whitelist: {
          users: form.authorisation_additional.users,
          usergroups: form.authorisation_additional.groups,
        },
        recurring: recurringRules.authorisation_conditions.recurring,
        time_end: recurringRules.authorisation_conditions.time_end,

        time_start: recurringRules.authorisation_conditions.time_start,

        recurrence_range: {
          time_start: {
            format: "RFC3339",
            value:
              form.authorisation_additional.time_start !== ""
                ? form.authorisation_additional.time_start
                : form.time_start,
          },
          time_end: {
            format: "RFC3339",
            value:
              form.authorisation_additional.time_end !== ""
                ? form.authorisation_additional.time_end
                : form.time_end,
          },
        },
      };
    } else {
      authorisation_conditions = {
        whitelist: {
          users: form.authorisation_additional.users,
          usergroups: form.authorisation_additional.groups,
        },
        time_start: {
          format: "RFC3339",
          value:
            form.authorisation_additional.time_start !== ""
              ? form.authorisation_additional.time_start
              : form.time_start,
        },
        time_end: {
          format: "RFC3339",
          value:
            form.authorisation_additional.time_end !== ""
              ? form.authorisation_additional.time_end
              : form.time_end,
        },
      };
    }

    let main_conditions;

    if (recurringRules.main_conditions.recurring) {
      main_conditions = {
        recurring: recurringRules.main_conditions.recurring,
        recurrence_range: {
          time_end: {
            format: "RFC3339",
            value: form.time_end,
          },
          time_start: {
            format: "RFC3339",
            value: form.time_start,
          },
        },
      };
    }

    /* END OF Handle Recurring Data */

    const data = {
      extents: [...extents],
      old_version: 0,
      name: form.name,
      description: form.description,
      rule: {
        prohibited: form.rules.prohibited,
        authorisation_required: form.rules.authorisation_required,
        conditional: form.rules.conditional,
        information_only: form.rules.information_only,
      },
      prohibited_conditions,
      authorisation_conditions,
      conditions: {
        noise_threshold:
          form.conditional_additional.noise_threshold !== 0
            ? form.conditional_additional.noise_threshold
            : 40,
        no_camera: form.conditional_additional.no_camera,
        power_tethered: form.conditional_additional.power_tethered,
        others: form.conditional_additional.others,
      },
      constraint_owner: username,
      uss_base_url: spBaseUrl,
      ...(main_conditions && main_conditions),
    };
    showMessage && showMessage("Saving constraint details", false);
    setIsLoading(true);

    try {
      if (editConstraintData) {
        const withRequringUpdated = {
          ...data,
          old_version: editConstraintData?.old_version,
          uss_base_url: editConstraintData?.uss_base_url,
          constraint_id: editConstraintData.constraint_id,
          constraint_owner: editConstraintData.constraint_owner,
        };
        let updateResponse;
        if (isPermanent) {
          updateResponse = await api.submitUpdateAuthorityAreaConstrainPerm(
            withRequringUpdated
          );
        } else {
          updateResponse = await api.submitUpdateAuthorityAreaConstrain(
            withRequringUpdated
          );
        }

        if (updateResponse.data) {
          showMessage?.("Constraint Updated", true);
          mapboxController.removeDrawFeaturesAll();
        } else {
          showMessage?.("Error Updating Constraint", false, true);
        }
        setIsLoading(false);
        resetForm();
        onConstraintSubmitted();
        return;
      }
      let response;
      if (isPermanent) {
        response = await api.submitAuthorityAreaConstrainPerm(data);
      } else {
        response = await api.submitAuthorityAreaConstrain(data);
      }
      if (response.data) {
        mapboxController.removeDrawFeaturesAll();
        showMessage && showMessage?.("Consraints Succesfully Created", true);
        resetForm();
        onConstraintSubmitted();
      }
    } catch (err) {
      let message = "Error getting response, please try again";
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message || err.message;
        if (errorMessage) {
          message = errorMessage;
        }
        if (message && message.startsWith("Airspace already reserved")) {
          const time = message.split(" ").at(-2);
          if (time) {
            message = `Airspace already reserved. Try again after: ${convertZuluToLocalTime(
              time
            )}`;
          }
        }
        if (err.code === "ECONNABORTED") {
          message = "Network Error";
        }
      }
      showMessage && showMessage(message, false, true);
    }

    setIsLoading(false);
  };

  const resetForm = () => {
    mapboxController.removeDrawFeaturesAll();
    handleOperationFormWaypointChange([], "ResetForm");
    setFloorArea(0);
    setAreaWaypoints([]);
    setOperationWaypoints(data.initialCircleWaypoint);
    setStartDate(getFutureDate(3));
    setEndDate(getFutureDate(60));

    setForm({
      name: "",
      description: "",
      altitude_reference: "W84",
      elevation: 0,
      ceiling: 100,
      time_start: getFutureDate(3).toISOString(),
      time_end: getFutureDate(60).toISOString(),
      rules: {
        prohibited: false,
        authorisation_required: false,
        conditional: false,
        information_only: false,
      },
      prohibited_additional: {
        users: [],
        groups: [],
        time_start: getFutureDate(3).toISOString(),
        time_end: getFutureDate(60).toISOString(),
      },
      authorisation_additional: {
        users: [],
        groups: [],
        time_start: getFutureDate(3).toISOString(),
        time_end: getFutureDate(60).toISOString(),
      },
      conditional_additional: {
        no_camera: false,
        power_tethered: false,
        noise_threshold: 0,
        others: "",
      },
    });
    setFormError({
      name: "",
      description: "",
      elevation: "",
      ceiling: "",
      time_start: "",
      time_end: "",
      waypoints: "",
    });

    setRecurringRules({
      authorisation_conditions: {
        time_start: {
          format: "RFC3339",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      },
      main_conditions: {
        time_start: {
          format: "RFC3339",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      },
      prohibited_conditions: {
        time_start: {
          format: "",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      },
    });
    setRecurrenceMainDescription("");
    setRecurrenceAuthorizationRequiredDescription("");
    setRecurrenceProhibitedDescription("");
  };

  const openWhiteListAutocomplete = (type: string, whitelistFor: string) => {
    const content = (
      <>
        <ConstraintWhitelistModal
          groupedOperators={groupedOperators}
          operatorGroups={operatorGroups}
          selectedGroups={
            whitelistFor === "prohibited"
              ? form.prohibited_additional.groups
              : form.authorisation_additional.groups
          }
          selectedOperators={
            whitelistFor === "prohibited"
              ? form.prohibited_additional.users
              : form.authorisation_additional.users
          }
          type={type}
          onSave={(usernames) => {
            if (type === "user" && whitelistFor === "prohibited") {
              setForm({
                ...form,
                prohibited_additional: {
                  ...form.prohibited_additional,
                  users: usernames,
                },
              });
            } else if (type === "group" && whitelistFor === "prohibited") {
              setForm({
                ...form,
                prohibited_additional: {
                  ...form.prohibited_additional,
                  groups: usernames,
                },
              });
            } else if (type === "user" && whitelistFor === "authorisation") {
              setForm({
                ...form,
                authorisation_additional: {
                  ...form.authorisation_additional,
                  users: usernames,
                },
              });
            } else if (type === "group" && whitelistFor === "authorisation") {
              setForm({
                ...form,
                authorisation_additional: {
                  ...form.authorisation_additional,
                  groups: usernames,
                },
              });
            }

            onCloseModal();
          }}
        />
      </>
    );
    onOpenModal("Whitelist", content, true, true);
  };

  // handle the result of polygon drawing
  useEffect(() => {
    let areaOperations: number[][] = [];
    if (selectedAreaWaypoints.length > 0) {
      areaOperations = selectedAreaWaypoints;
    }

    const areaSqm = calculatePolygonFloorArea(areaOperations);

    setFloorArea(areaSqm ?? 0);
    setAreaWaypoints(areaOperations);

    const newWaypoints: Waypoint[] = [];
    selectedAreaWaypoints.forEach((waypoint, index) => {
      newWaypoints.push({
        position: index + 1,
        latitude: waypoint[1],
        longitude: waypoint[0],
        isFirst: index === 0,
        isLast: index === selectedAreaWaypoints.length - 1,
      });
    });
    setOperationWaypoints(newWaypoints);

    setInputWaypointConfiguration({
      keyLabel: "",
      addButtonText: "Add",
      showRadius: false,
      allowMultiple: true,
      useTakeOffLandingPoint: false,
      minWaypointCount: 3,
      latLabel: "Latitude",
      lngLabel: "Longitude",
    });
  }, [selectedAreaWaypoints]);

  // handle the result of circle drawing
  useEffect(() => {
    let areaOperations: number[][] = [];
    if (selectedCircleWaypoints.length > 0) {
      areaOperations = selectedCircleWaypoints;
    }
    const areaSqm = calculatePolygonFloorArea(areaOperations);

    setFloorArea(areaSqm ?? 0);
    setAreaWaypoints(areaOperations);
  }, [selectedCircleWaypoints]);

  // handle the result of cirlce properties
  useEffect(() => {
    if (selectedCircleProperties && selectedCircleProperties.isCircle) {
      const newWaypoints: Waypoint[] = [];
      newWaypoints.push({
        position: 1,
        latitude: selectedCircleProperties.center[1],
        longitude: selectedCircleProperties.center[0],
        radius: selectedCircleProperties.radiusInKm,
        isFirst: true,
        isLast: true,
      });
      setOperationWaypoints(newWaypoints);

      setInputWaypointConfiguration({
        keyLabel: "",
        addButtonText: "",
        showRadius: true,
        allowMultiple: false,
        useTakeOffLandingPoint: false,
        minWaypointCount: 1,
        latLabel: "LAT (Center)",
        lngLabel: "LONG (Center)",
      });
    }
  }, [selectedCircleProperties]);

  useEffect(() => {
    if (!userAccess.privileges.includes("airspace.organisations.read")) return;
    const getAssignedOperators = async () => {
      const res = await api.aas.getOrganisationManagedList();
      setOperatorGroups(res?.data);
    };
    getAssignedOperators();
  }, []);

  useEffect(() => {
    if (!operatorGroups.length) return;
    if (!userAccess.privileges.includes("airspace.organisations.read")) return;
    const getOperators = async () => {
      const ArrayOperators = operatorGroups?.map(
        async (singleOrganisation: any) => {
          const res2Data = await api.aas.getOrganisationManagedPilots(
            singleOrganisation
          );
          const dataNested = {
            id: Math.random(),
            operatorName: singleOrganisation,
            operatorUser: res2Data?.data?.data,
          };
          return dataNested;
        }
      );
      const allUserOperators = await Promise.all(ArrayOperators);
      setGroupedOperators(allUserOperators);
    };
    getOperators();
  }, [operatorGroups]);

  /* Handle Edit Constraint */

  useEffect(() => {
    if (!editConstraintData) return;
    if (editConstraintData.constraint_type === "LTC") setIsPermanent(true);
    setStartDate(new Date(editConstraintData.extents[0].time_start.value));
    setEndDate(new Date(editConstraintData.extents[0].time_end.value));
    setForm({
      name: editConstraintData.name,
      description: editConstraintData.description,
      altitude_reference:
        editConstraintData.extents[0].volume.altitude_lower.reference,
      elevation: editConstraintData.extents[0].volume.altitude_lower.value,
      ceiling: editConstraintData.extents[0].volume.altitude_upper.value,
      time_start: editConstraintData.recurrence_range?.time_start?.value
        ? editConstraintData.recurrence_range.time_start.value
        : editConstraintData.extents[0].time_start.value,
      time_end: editConstraintData.recurrence_range?.time_end?.value
        ? editConstraintData.recurrence_range?.time_end?.value
        : editConstraintData.extents[0].time_end.value,
      rules: {
        prohibited: editConstraintData?.rule?.prohibited,
        authorisation_required:
          editConstraintData?.rule?.authorisation_required,
        conditional: editConstraintData?.rule?.conditional,
        information_only: editConstraintData?.rule?.information_only,
      },
      prohibited_additional: {
        users: editConstraintData?.prohibited_conditions?.whitelist?.users,
        groups:
          editConstraintData?.prohibited_conditions?.whitelist?.usergroups,
        time_start:
          editConstraintData?.prohibited_conditions?.time_start?.value,
        time_end: editConstraintData?.prohibited_conditions?.time_end?.value,
      },
      authorisation_additional: {
        users: editConstraintData?.authorisation_conditions?.whitelist?.users,
        groups:
          editConstraintData?.authorisation_conditions?.whitelist?.usergroups,
        time_start:
          editConstraintData?.authorisation_conditions?.time_start?.value,
        time_end: editConstraintData?.authorisation_conditions?.time_end?.value,
      },
      conditional_additional: {
        no_camera: editConstraintData?.conditions?.no_camera,
        power_tethered: editConstraintData?.conditions?.power_tethered,
        noise_threshold: editConstraintData?.conditions?.noise_threshold,
        others: editConstraintData?.conditions?.others,
      },
    });

    setRecurringRules({
      authorisation_conditions: {
        time_start: {
          format: editConstraintData.authorisation_conditions?.recurrence_range
            ?.time_start?.format
            ? editConstraintData.authorisation_conditions.recurrence_range
                .time_start.format
            : "RFC3339",
          value: editConstraintData.authorisation_conditions?.recurrence_range
            ?.time_start?.value
            ? editConstraintData.authorisation_conditions.recurrence_range
                ?.time_start.value
            : "",
        },
        time_end: {
          format: editConstraintData.authorisation_conditions?.recurrence_range
            ?.time_end?.format
            ? editConstraintData.authorisation_conditions.recurrence_range
                .time_end.format
            : "RFC3339",
          value: editConstraintData.authorisation_conditions?.recurrence_range
            ?.time_end?.value
            ? editConstraintData.authorisation_conditions.recurrence_range
                ?.time_end.value
            : "",
        },
        recurring: editConstraintData.authorisation_conditions?.recurring
          ? editConstraintData.authorisation_conditions.recurring
          : "",
      },
      prohibited_conditions: {
        time_start: {
          format: editConstraintData.prohibited_conditions?.recurrence_range
            ?.time_start?.format
            ? editConstraintData.prohibited_conditions.recurrence_range
                .time_start.format
            : "RFC3339",
          value: editConstraintData.prohibited_conditions?.recurrence_range
            ?.time_start?.value
            ? editConstraintData.prohibited_conditions.recurrence_range
                ?.time_start.value
            : "",
        },
        time_end: {
          format: editConstraintData.prohibited_conditions?.recurrence_range
            ?.time_end?.format
            ? editConstraintData.prohibited_conditions.recurrence_range.time_end
                .format
            : "RFC3339",
          value: editConstraintData.prohibited_conditions?.recurrence_range
            ?.time_end?.value
            ? editConstraintData.prohibited_conditions.recurrence_range
                ?.time_end.value
            : "",
        },
        recurring: editConstraintData.prohibited_conditions?.recurring
          ? editConstraintData.prohibited_conditions.recurring
          : "",
      },
      main_conditions: {
        time_start: {
          format: editConstraintData.recurrence_range?.time_start?.format
            ? editConstraintData.recurrence_range.time_start.format
            : "RFC3339",
          value: editConstraintData.recurrence_range?.time_start?.value
            ? editConstraintData.recurrence_range?.time_start.value
            : "",
        },
        time_end: {
          format: editConstraintData.recurrence_range?.time_end?.format
            ? editConstraintData.recurrence_range.time_end.format
            : "RFC3339",
          value: editConstraintData.recurrence_range?.time_end?.value
            ? editConstraintData.recurrence_range?.time_end.value
            : "",
        },
        recurring: editConstraintData.recurring
          ? editConstraintData.recurring
          : "",
      },
    });
    // Handle description message
    // Authorization Required Recurring
    if (
      editConstraintData.authorisation_conditions?.recurring &&
      editConstraintData.authorisation_conditions?.recurrence_range?.time_start
        ?.value &&
      editConstraintData.authorisation_conditions?.recurrence_range?.time_end
        ?.value
    ) {
      const startDate = new Date(
        editConstraintData.authorisation_conditions?.recurrence_range?.time_start?.value
      );
      const endDate = new Date(
        editConstraintData.authorisation_conditions?.recurrence_range?.time_end?.value
      );
      const recurringType =
        editConstraintData.authorisation_conditions?.recurring;
      const message =
        recurringConstraintMessageHandler(startDate, endDate, recurringType) ||
        "";
      setRecurrenceAuthorizationRequiredDescription(message);
    }
    // Prohibited Recurring
    if (
      editConstraintData.prohibited_conditions?.recurring &&
      editConstraintData.prohibited_conditions?.recurrence_range?.time_start
        ?.value &&
      editConstraintData.prohibited_conditions?.recurrence_range?.time_end
        ?.value
    ) {
      const startDate = new Date(
        editConstraintData.prohibited_conditions?.recurrence_range?.time_start?.value
      );
      const endDate = new Date(
        editConstraintData.prohibited_conditions?.recurrence_range?.time_end?.value
      );
      const recurringType = editConstraintData.prohibited_conditions?.recurring;
      const message =
        recurringConstraintMessageHandler(startDate, endDate, recurringType) ||
        "";
      setRecurrenceProhibitedDescription(message);
    }
    // Constraint Recurring
    if (
      editConstraintData.recurring &&
      editConstraintData.recurrence_range?.time_start?.value &&
      editConstraintData.recurrence_range?.time_end?.value
    ) {
      const startDate = new Date(
        editConstraintData.recurrence_range?.time_start?.value
      );
      const endDate = new Date(
        editConstraintData.recurrence_range?.time_end?.value
      );
      const recurringType = editConstraintData.recurring;
      const message =
        recurringConstraintMessageHandler(startDate, endDate, recurringType) ||
        "";
      setRecurrenceMainDescription(message);
    }
    setAreaWaypoints(
      editConstraintData.extents[0].volume.outline_polygon.coordinates[0]
    );
  }, [editConstraintData]);

  /* Handle Recurring Constraint */

  const handleRecurringConfirmClick = (
    recurrenceRule: RecurrenceRuleTypes,
    recurrenceType: string,
    startDate: Date,
    endDate: Date,
    recurrenceDescription: string
  ) => {
    if (recurrenceRule === "main") {
      setRecurrenceMainDescription(recurrenceDescription);

      const updatedRules = recurringRules;
      updatedRules.main_conditions = {
        time_start: {
          format: "RFC3339",
          value: startDate.toISOString(),
        },
        time_end: {
          format: "RFC3339",
          value: endDate.toISOString(),
        },
        recurring: recurrenceType,
      };
      setRecurringRules(updatedRules);
    }
    if (recurrenceRule === "prohibited") {
      setRecurrenceProhibitedDescription(recurrenceDescription);

      const updatedRules = recurringRules;
      updatedRules.prohibited_conditions = {
        time_start: {
          format: "RFC3339",
          value: startDate.toISOString(),
        },
        time_end: {
          format: "RFC3339",
          value: endDate.toISOString(),
        },
        recurring: recurrenceType,
      };
      setRecurringRules(updatedRules);
    }
    if (recurrenceRule === "authrequired") {
      setRecurrenceAuthorizationRequiredDescription(recurrenceDescription);

      const updatedRules = recurringRules;
      updatedRules.authorisation_conditions = {
        time_start: {
          format: "RFC3339",
          value: startDate.toISOString(),
        },
        time_end: {
          format: "RFC3339",
          value: endDate.toISOString(),
        },
        recurring: recurrenceType,
      };
      setRecurringRules(updatedRules);
    }
  };
  const handleClearRecurringClick = (
    recurrenceRuleType: RecurrenceRuleTypes
  ) => {
    const editRecurringRules = recurringRules;
    if (recurrenceRuleType === "main") {
      editRecurringRules.main_conditions = {
        time_start: {
          format: "RFC3339",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      };
    }
    if (recurrenceRuleType === "prohibited") {
      editRecurringRules.prohibited_conditions = {
        time_start: {
          format: "RFC3339",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      };
    }
    if (recurrenceRuleType === "authrequired") {
      editRecurringRules.authorisation_conditions = {
        time_start: {
          format: "RFC3339",
          value: "",
        },
        time_end: {
          format: "RFC3339",
          value: "",
        },
        recurring: "",
      };
    }
    setRecurringRules(editRecurringRules);
  };

  const handleRecurringButtonClick = (
    recurrenceRuleType: RecurrenceRuleTypes
  ) => {
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col ring-1 ring-black ring-opacity-5 mt-50`}
        >
          <ReccuringConstraintForm
            handleRecurringCancelClick={() => {
              toast.dismiss(t.id);
            }}
            handleRecurringConfirmClick={(
              recurrenceType: string,
              startDate: Date,
              endDate: Date,
              recurrenceDescription: string
            ) => {
              handleRecurringConfirmClick(
                recurrenceRuleType,
                recurrenceType,
                startDate,
                endDate,
                recurrenceDescription
              );
              toast.dismiss(t.id);
            }}
          />
        </div>
      ),
      {
        duration: Infinity,
      }
    );
  };
  return (
    <div className="h-full flex-col px-4">
      <h5 className="font-medium mb-2 mt-4">Constraint Detail</h5>
      <ul className="rounded-radio-container mb-2">
        <li
          className="w-full border-r dark:border-gray-600 cursor-pointer"
          onClick={() => setIsPermanent(false)}
        >
          <div className="flex items-center ps-3">
            <span className="flex items-center pointer-events-none">
              <input
                type="radio"
                value=""
                name="list-radio"
                className="input-radio"
                disabled
                checked={!isPermanent}
              />
              <label className="label user-select-none">Temporary</label>
            </span>
          </div>
        </li>
        <li
          className="w-full cursor-pointer"
          onClick={() => setIsPermanent(true)}
        >
          <div className="flex items-center ps-3">
            <span className="flex items-center pointer-events-none">
              <input
                type="radio"
                value=""
                name="list-radio"
                className="input-radio"
                disabled
                checked={isPermanent}
              />
              <label className="label user-select-none">Permanent</label>
            </span>
          </div>
        </li>
      </ul>

      <div className="mb-2">
        <label htmlFor="intent" className="label">
          Name of Constraint
        </label>
        <input
          type="text"
          name="name"
          className="input-text"
          defaultValue={form.name}
          onChange={handleTextChange}
        />
        {formError.name && (
          <p className="text-red-500 text-sm mt-1">{formError.name}</p>
        )}
      </div>
      <div className="mb-6">
        <label htmlFor="description" className="label">
          Description
        </label>
        <textarea
          className="input-text"
          name="description"
          defaultValue={form.description}
          onChange={handleTextAreaChange}
        />
        {formError.description && (
          <p className="text-red-500 text-sm mt-1">{formError.description}</p>
        )}
      </div>

      {/* Waypoints */}
      <div className="flex mb-2 items-center">
        <PiMapPinArea size={18} color="gray" />
        <h4 className="ms-2 font-medium grow">Area</h4>
        <div
          className="flex items-center px-2 cursor-pointer"
          onClick={() => handleOnDraw(CreateOperationType.CIRCLE)}
        >
          <FaMapPin className="fill-blue-500" />
          <p className="ml-1 text-blue-500 text-sm font-medium">Draw Circle</p>
        </div>
        <span className="font-medium text-blue-500">/</span>
        <div
          className="flex items-center px-2 cursor-pointer"
          onClick={() => handleOnDraw(CreateOperationType.POLYGON)}
        >
          <FaDrawPolygon className="fill-blue-500" />
          <p className="ml-1 text-blue-500 text-sm font-medium">Draw Polygon</p>
        </div>
      </div>
      <div className="rounded-container flex mb-2">
        <span className="grow">Constraint floor area</span>
        <span className="mx-2">:</span>
        <span className="grow font-medium">{floorArea} ㎡</span>
      </div>
      <div className="rounded-container p-2 mb-4">
        <InputWaypoint
          keyLabel={inputWaypointConfiguration.keyLabel}
          addButtonText={inputWaypointConfiguration.addButtonText}
          allowMultiple={inputWaypointConfiguration.allowMultiple}
          showRadius={inputWaypointConfiguration.showRadius}
          minWaypointCount={inputWaypointConfiguration.minWaypointCount}
          latLabel={inputWaypointConfiguration.latLabel}
          lngLabel={inputWaypointConfiguration.lngLabel}
          useTakeOffLandingPoint={
            inputWaypointConfiguration.useTakeOffLandingPoint
          }
          waypointsData={operationWaypoints}
          onRadiusChanged={handleRadiusChange}
          onWaypointChanged={handleWaypointChange}
          goToLocation={goToLocation}
        />
      </div>
      {formError.waypoints && (
        <p className="text-red-500 text-sm -mt-2 mb-4">{formError.waypoints}</p>
      )}

      {/* Constraint Configuration */}
      <div className="mb-2">
        <label className="label">Altitude Reference</label>
        <select
          name="altitude_reference"
          className="input-select"
          onChange={(e) => handleSelectChange(e, "altitude_reference")}
        >
          <option value="W84">W84</option>
          <option value="AGL">AGL</option>
        </select>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="elevation" className="label">
            Elevation (m)
          </label>
          <input
            type="number"
            className="input-text"
            name="elevation"
            defaultValue={form.elevation}
            onChange={handleTextChange}
          />
          {formError.elevation && (
            <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="ceiling" className="label">
            Ceiling (m)
          </label>
          <input
            type="number"
            name="ceiling"
            className="input-text"
            defaultValue={form.ceiling}
            onChange={handleTextChange}
          />
          {formError.ceiling && (
            <p className="text-red-500 text-sm mt-1">{formError.ceiling}</p>
          )}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="Start Date Time (Local Time)"
            value={startDate}
            className="mr-2"
            onChange={(date) => handleDateChange(date, "start")}
          />
          {formError.time_start && (
            <p className="text-red-500 text-sm mt-1">{formError.time_start}</p>
          )}
        </div>
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="End Date Time (Local Time)"
            value={endDate}
            onChange={(date) => handleDateChange(date, "end")}
          />
          {formError.time_end && (
            <p className="text-red-500 text-sm mt-1">{formError.time_end}</p>
          )}
        </div>
      </div>
      {isPermanent && (
        <div className="flex mb-4">
          {recurringRules.main_conditions.recurring ? (
            <div className="flex items-center px-2 cursor-pointer">
              <IoMdCloseCircle
                size={15}
                onClick={() => handleClearRecurringClick("main")}
              />
              <p className="ml-1 text-blue-500 text-sm font-medium">
                {recurrenceMainDescription}
              </p>
            </div>
          ) : (
            <div
              className="flex items-center px-2 cursor-pointer"
              onClick={() => handleRecurringButtonClick("main")}
            >
              <FaMapPin className="fill-blue-500" />
              <p className="ml-1 text-blue-500 text-sm font-medium">
                Enable Recurring
              </p>
            </div>
          )}
        </div>
      )}
      <div className="flex mb-2 items-center">
        <MdDisplaySettings size={18} color="gray" />
        <h4 className="ms-2 font-medium grow">Constraint Rules</h4>
      </div>
      <div className="rounded-container-orange mb-2">
        <div className="flex">
          <div className="flex items-center w-full">
            <input
              type="checkbox"
              className="input-checkbox"
              name="prohibited"
              checked={form.rules.prohibited}
              onChange={handleRulesCheckboxChange}
            />
            <label className="ml-2 text-sm">Prohibited</label>
          </div>
          <div className="flex items-center w-full">
            <input
              type="checkbox"
              className="input-checkbox"
              name="authorisation_required"
              checked={form.rules.authorisation_required}
              onChange={handleRulesCheckboxChange}
            />
            <label className="ml-2 text-sm">Authorisation Required</label>
          </div>
        </div>
        <div className="flex">
          <div className="flex items-center w-full">
            <input
              type="checkbox"
              className="input-checkbox"
              name="conditional"
              checked={form.rules.conditional}
              onChange={handleRulesCheckboxChange}
            />
            <label className="ml-2 text-sm">Conditional</label>
          </div>
          <div className="flex items-center w-full">
            <input
              type="checkbox"
              className="input-checkbox"
              name="information_only"
              checked={form.rules.information_only}
              onChange={handleRulesCheckboxChange}
            />
            <label className="ml-2 text-sm">Information Only</label>
          </div>
        </div>
      </div>

      {/* Additional Rules */}
      {(form.rules.prohibited ||
        form.rules.authorisation_required ||
        form.rules.conditional) && (
        <div className="flex-col">
          <div className="flex mb-2 items-center">
            <IoMdInformationCircleOutline size={18} color="gray" />
            <h5 className="ms-2 font-medium grow">Additional Rules</h5>
          </div>
          {form.rules.prohibited && (
            <div>
              <WhiteListContraintForm
                title="Prohibited"
                selectedUserCount={form.prohibited_additional.users.length}
                selectedGroupCount={form.prohibited_additional.groups.length}
                onSelectUser={() => handleSelectWhiteList("user", "prohibited")}
                onSelectGroup={() =>
                  handleSelectWhiteList("group", "prohibited")
                }
                onStartDateChange={(date) =>
                  setForm({
                    ...form,
                    prohibited_additional: {
                      ...form.prohibited_additional,
                      time_start: date,
                    },
                  })
                }
                onEndDateChange={(date) =>
                  setForm({
                    ...form,
                    prohibited_additional: {
                      ...form.prohibited_additional,
                      time_end: date,
                    },
                  })
                }
                formStartDate={form.prohibited_additional.time_start}
                formEndDate={form.prohibited_additional.time_end}
              />
              {isPermanent && (
                <div>
                  {recurringRules.prohibited_conditions.recurring ? (
                    <div className="flex items-center mb-1 px-2 cursor-pointer mb-1">
                      <IoMdCloseCircle
                        size={15}
                        onClick={() => handleClearRecurringClick("prohibited")}
                      />
                      <p className="ml-1 text-blue-500 text-sm font-medium">
                        {recurrenceProhibitedDescription}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="flex items-center mb-1 px-2 cursor-pointer"
                      onClick={() => handleRecurringButtonClick("prohibited")}
                    >
                      <FaMapPin className="fill-blue-500" />
                      <p className="ml-1 text-blue-500 text-sm font-medium">
                        Enable Recurring
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {form.rules.authorisation_required && (
            <div>
              <WhiteListContraintForm
                title="Authorisation"
                selectedUserCount={form.authorisation_additional.users.length}
                selectedGroupCount={form.authorisation_additional.groups.length}
                onSelectUser={() =>
                  handleSelectWhiteList("user", "authorisation")
                }
                onSelectGroup={() =>
                  handleSelectWhiteList("group", "authorisation")
                }
                onStartDateChange={(date) =>
                  setForm({
                    ...form,
                    authorisation_additional: {
                      ...form.authorisation_additional,
                      time_start: date,
                    },
                  })
                }
                onEndDateChange={(date) =>
                  setForm({
                    ...form,
                    authorisation_additional: {
                      ...form.authorisation_additional,
                      time_end: date,
                    },
                  })
                }
                formStartDate={form.authorisation_additional.time_start}
                formEndDate={form.authorisation_additional.time_end}
              />
              {isPermanent && (
                <div className="flex mb-4">
                  {recurringRules.authorisation_conditions.recurring ? (
                    <div className="flex items-center px-2 cursor-pointer">
                      <IoMdCloseCircle
                        size={15}
                        onClick={() =>
                          handleClearRecurringClick("authrequired")
                        }
                      />
                      <p className="ml-1 text-blue-500 text-sm font-medium">
                        {recurrenceAuthorizationRequiredDescription}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="flex items-center px-2 cursor-pointer"
                      onClick={() => handleRecurringButtonClick("authrequired")}
                    >
                      <FaMapPin className="fill-blue-500" />
                      <p className="ml-1 text-blue-500 text-sm font-medium">
                        Enable Recurring
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {form.rules.conditional && (
            <div className="flex-col mb-2 rounded-container-orange">
              <h5 className="font-medium mb-2">Conditional</h5>
              <div className="flex mb-1">
                <div className="flex items-center w-full">
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    checked={form.conditional_additional.no_camera}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        conditional_additional: {
                          ...form.conditional_additional,
                          no_camera: e.target.checked,
                        },
                      });
                    }}
                  />
                  <label className="ml-2 text-sm">No Camera</label>
                </div>
                <div className="flex items-center w-full">
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    checked={form.conditional_additional.power_tethered}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        conditional_additional: {
                          ...form.conditional_additional,
                          power_tethered: e.target.checked,
                        },
                      });
                    }}
                  />
                  <label className="ml-2 text-sm">Power Tethered</label>
                </div>
              </div>
              <div className="flex">
                <div className="w-full mr-2">
                  <label htmlFor="elevation" className="label">
                    Noise Treshold
                  </label>
                  <input
                    type="number"
                    className="input-text"
                    name="elevation"
                    defaultValue={form.conditional_additional.noise_threshold}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        conditional_additional: {
                          ...form.conditional_additional,
                          noise_threshold: parseInt(e.target.value, 10),
                        },
                      })
                    }
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="altitude" className="label">
                    Others
                  </label>
                  <input
                    type="text"
                    name="altitude"
                    className="input-text"
                    defaultValue={form.conditional_additional.others}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        conditional_additional: {
                          ...form.conditional_additional,
                          others: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex mt-4 mb-8 pb-8">
        {!editConstraintData && (
          <Button
            type="light"
            size="medium"
            text="Reset"
            className="mr-2"
            disabled={isLoading}
            onClick={handleResetForm}
          />
        )}

        <Button
          type="primaryDark"
          size="medium"
          text="Submit"
          isLoading={isLoading}
          onClick={handleSubmitConstraint}
        />
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useApi } from "@/api/useApi";
import { useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import Button from "@/components/v1/Common/Button";
import Pilot from "@/model/api/Pilot";
import {
  convertDatetoInputMask,
  convertInputMasktoISODate,
} from "@/utils/date_utils";
import { useState } from "react";
import moment from "moment";
import InputMask from "react-input-mask";

interface PilotFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  pilot?: Pilot | null;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onSaveSucess?: () => void;
  hasWritePrivilege: boolean;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function PilotForm({
  api,
  pilot,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
}: PilotFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    name: pilot?.given_name || "",
    username: pilot?.pilot_username || "",
    email: pilot?.email || "",
    contact: pilot?.contact || "",
    license: pilot?.pilot_registration || "",
    expiry: pilot ? convertDatetoInputMask(pilot?.registration_expiry) : "",
  });

  const [formError, setFormError] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    license: "",
    expiry: "",
  });

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      name: "",
      username: "",
      email: "",
      phone: "",
      license: "",
      expiry: "",
    };

    try {
      if (form.expiry !== "") {
        const date = moment(form.expiry, "DD/MM/YYYY", true);

        if (!date.isValid()) {
          throw new Error("Invalid date");
        }
      }
    } catch {
      error.expiry = "Invalid date";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async () => {
    // validate if form has an empty field
    // no longer able to create pilot, can only create user and update user/pilot
    // all fields optional for update
    if (!validateForm()) {
      return;
    }

    const newDate = convertInputMasktoISODate(form.expiry);
    const updatedForm = { ...form, expiry: newDate };
    setIsLoading(true);
    try {
      if (pilot) {
        if (userAccess.privileges.includes("assets.pilot.write")) {
          showMessage && showMessage("Updating user details", false);
          await api.updatePilot(pilot.pilot_uuid, { ...updatedForm });
        }
        if (userAccess.privileges.includes("assets.pilot.write")) {
          showMessage && showMessage("Updating pilot registration details");
          await api.updateUserDetails(updatedForm);
        }
      }
      setIsLoading(false);
      showMessage && showMessage("Pilot details updated", true);
      onSaveSucess && onSaveSucess();
    } catch (err) {
      setIsLoading(false);
      if (showMessage) {
        showMessage(
          (err as any).response?.data?.message || (err as any).message,
          false,
          true
        );
      }
    }
  };

  return (
    <div className="flex-col p-4 w-full" onClick={(e) => e.stopPropagation()}>
      <div className="mb-2">
        <h5 className="font-medium">
          Username:{" "}
          <span className="text-blue-500 text-sm">{form.username}</span>
        </h5>
      </div>
      {userAccess.privileges.includes("general.user.write") && (
        <div>
          <div className="mb-2">
            <label htmlFor="name" className="label">
              Pilot Name
            </label>
            <input
              type="text"
              id="name"
              className="input-text"
              defaultValue={form.name}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
            {formError.name && (
              <p className="text-red-500 text-sm mt-1">{formError.name}</p>
            )}
          </div>
          <div className="mb-2">
            <label htmlFor="username" className="label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="input-text"
              defaultValue={form.email}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
            {formError.email && (
              <p className="text-red-500 text-sm mt-1">{formError.email}</p>
            )}
          </div>
          <div className="mb-2">
            <label htmlFor="username" className="label">
              Contact
            </label>
            <input
              type="text"
              id="contact"
              className="input-text"
              defaultValue={form.contact}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
            {formError.phone && (
              <p className="text-red-500 text-sm mt-1">{formError.phone}</p>
            )}
          </div>
        </div>
      )}
      {userAccess.privileges.includes("assets.pilot.write") && (
        <div>
          <div className="mb-2">
            <label htmlFor="username" className="label">
              Registration Number
            </label>
            <input
              type="text"
              id="license"
              className="input-text"
              defaultValue={form.license}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
            {formError.license && (
              <p className="text-red-500 text-sm mt-1">{formError.license}</p>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="username" className="label">
              Registration Expiration Date
            </label>
            <InputMask
              id="expiry"
              mask="99/99/9999"
              maskChar={null}
              placeholder="dd/mm/yyyy"
              className="input-text"
              defaultValue={form.expiry}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
            {formError.expiry && (
              <p className="text-red-500 text-sm mt-1">{formError.expiry}</p>
            )}
          </div>
        </div>
      )}
      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}

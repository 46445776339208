/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { FaPlus, FaTrash } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdHelp,
} from "react-icons/io";
import LatLng from "@/model/LatLng";
import Button from "@/components/v1/Common/Button";
import Pilot from "@/model/api/Pilot";
import Tracker from "@/model/api/Tracker";
import PlatformType from "@/model/api/PlatformType";
import Platform from "@/model/api/Platform";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import UserAccess from "@/model/UserAccess";
import { Content, Permit } from "@/model/api/Permit";
import Loader from "../../Common/Loader";
import PilotForm from "./PilotForm";
import TrackerForm from "./TrackerForm";
import PilotItem from "./ItemCards/PilotItem";
import TrackerItem from "./ItemCards/TrackerItem";
import PlatformTypeItem from "./ItemCards/PlatformTypeItem";
import PlatformItem from "./ItemCards/PlatformItem";
import PlatformForm from "./PlatformForm";
import PlatformTypeForm from "./PlatformTypeForm";
import PermitItem from "./ItemCards/PermitItem";
import PermitForm from "./PermitForm";
import MaintenanceDialog from "./Maintenance/MaintenanceDialog";

interface AssetManagementSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  goToLocation: (point: LatLng) => void;
  onConfirmation?: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function AssetManagementSidebar({
  api,
  onConfirmation,
  goToLocation,
  showMessage,
  onOpenModal,
}: AssetManagementSidebarProps) {
  const [formTitle, setFormTitle] = useState("Create New");
  const [selectedOption, setSelectedOption] = useState("Pilot");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);

  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isError, setIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  /* Selected Item */
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  /* Data List */
  const [selectedPilot, setSelectedPilot] = useState<Pilot | null>(null);
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(
    null
  );
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [selectedPlatformType, setSelectedPlatformType] =
    useState<PlatformType | null>(null);
  const [platformTypes, setPlatformTypes] = useState<PlatformType[]>([]);
  const [selectedTracker, setSelectedTracker] = useState<Tracker | null>(null);
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [selectedPermitContent, setSelectedPermitContent] =
    useState<Content | null>(null);
  const [permits, setPermits] = useState<Permit[]>([]);

  const [hasWritePrivilege, setHasWritePrivilege] = useState(false);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const isAdministrator = userAccess.role === "administrator";

  useEffect(() => {
    // update available option based on user access
    const updateOptions = [];
    if (userAccess.privileges.includes("assets.pilot.read"))
      updateOptions.push("Pilot");
    if (
      userAccess.privileges.includes("assets.platform.read") &&
      !isAdministrator
    )
      updateOptions.push("Platforms");
    if (
      userAccess.privileges.includes("assets.platformtype.read") &&
      !isAdministrator
    )
      updateOptions.push("Platform Types");
    if (
      userAccess.privileges.includes("assets.tracker.read") &&
      !isAdministrator
    )
      updateOptions.push("Tracker");
    if (
      userAccess.privileges.includes("assets.permit.read") &&
      !isAdministrator
    )
      updateOptions.push("Permits");
    setOptions(updateOptions);
  }, [userAccess]);

  useEffect(() => {
    // reset selected option if invalid
    if (!options.includes(selectedOption) || selectedOption === "No Access") {
      setSelectedOption(options[0] || "No Access");
    }
  }, [options]);

  useEffect(() => {
    // reset selected option if invalid
    let checkPrivilege = false;
    if (userAccess.role !== "airspace_manager") {
      if (
        selectedOption === "Pilot" &&
        userAccess.privileges.includes("assets.pilot.write")
      )
        checkPrivilege = true;
      if (
        selectedOption === "Platforms" &&
        userAccess.privileges.includes("assets.platform.write")
      )
        checkPrivilege = true;
      if (
        selectedOption === "Platform Types" &&
        userAccess.privileges.includes("assets.platformtype.write")
      )
        checkPrivilege = true;
      if (
        selectedOption === "Tracker" &&
        userAccess.privileges.includes("assets.tracker.write")
      )
        checkPrivilege = true;
      if (
        selectedOption === "Permits" &&
        userAccess.privileges.includes("assets.permit.write")
      )
        checkPrivilege = true;
    }
    setHasWritePrivilege(checkPrivilege);
  }, [selectedOption, userAccess]);

  const handleSelectedOption = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setSelectedIds([]);
  };

  const handleSelectPilot = (pilot: Pilot) => {
    setSelectedPilot(pilot);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleSelectTracker = (tracker: Tracker) => {
    setSelectedTracker(tracker);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleSelectPlatformType = (platformType: PlatformType) => {
    setSelectedPlatformType(platformType);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleSelectPlatform = (platform: Platform) => {
    setSelectedPlatform(platform);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleSelectPermit = (content: Content) => {
    setSelectedPermitContent(content);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleSelectedItem = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    }
  };

  const handleMaintenancePlatform = (platform: Platform) => {
    const content = (
      <>
        <MaintenanceDialog
          api={api}
          platform={platform}
          showMessage={showMessage}
          onConfirmation={onConfirmation}
        />
      </>
    );
    onOpenModal("Maintenance", content);
  };

  const handleSaveSuccess = async () => {
    showMessage && showMessage("Data saved successfully", true);
    setIsAdd(false);
    loadDataList();
  };

  const handleAddNew = () => {
    setIsAdd(true);
    setSelectedPilot(null);
    setSelectedPlatform(null);
    setSelectedPlatformType(null);
    setSelectedTracker(null);
    setSelectedPermitContent(null);
    setFormTitle("Create New");
  };

  const handleDeleteItem = () => {
    if (onConfirmation) {
      onConfirmation(
        "Delete Confirmation",
        "Are you sure you want to delete the selected item?",
        (result) => {
          if (result) {
            deleteSelectedItems();
          }
        }
      );
    }
  };

  const deleteSelectedItems = async () => {
    setIsDeleting(true);
    showMessage && showMessage("Deleting items... Please wait");

    try {
      let isAPIError = false;

      const promises: Promise<void>[] = selectedIds.map(async (item) => {
        let success = false;
        if (selectedOption === "Pilot") {
          success = await api.deletePilot(item);
        }
        if (selectedOption === "Platforms") {
          success = await api.deletePlatform(item);
        }
        if (selectedOption === "Platform Types") {
          success = await api.deletePlatformType(item);
        }
        if (selectedOption === "Tracker") {
          success = await api.deleteTracker(item);
        }
        if (selectedOption === "Permits") {
          const user = await Auth.currentAuthenticatedUser();
          success = await api.deletePermit(user.username, item);
        }
        if (!success) {
          isAPIError = true;
        }
      });

      await Promise.all(promises);
      loadDataList();

      if (isAPIError) {
        showMessage && showMessage("Error deleting items", false, true);
      } else {
        showMessage && showMessage("Items deleted successfully", true);
      }

      setSelectedIds([]);
      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      showMessage && showMessage("Error deleting items", false, true);
      loadDataList();
    }
  };

  const fetchPilotData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await api.getPilots();
      if (response.data) {
        // response.data.sort(compare);
        setPilots(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const fetchPlatformTypes = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await api.getPlatformTypes();
      if (response.data) {
        // response.data.sort(compare);
        setPlatformTypes(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const fetchPlatforms = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await api.getPlatforms();
      if (response.data) {
        // response.data.sort(compare);
        setPlatforms(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const fetchTrackerData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await api.getTracker();
      if (response.data) {
        // response.data.sort(compare);
        setTrackers(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const fetchPermits = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await api.getPermits(user.username);
      if (response.data) {
        // response.data.sort(compare);
        setPermits(response.data);
        // console.log(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const loadDataList = () => {
    if (selectedOption === "Pilot") {
      fetchPilotData();
    }
    if (selectedOption === "Platforms") {
      fetchPlatformTypes();
      fetchPlatforms();
    }
    if (selectedOption === "Platform Types") {
      fetchPlatformTypes();
    }
    if (selectedOption === "Tracker") {
      fetchTrackerData();
    }
    if (selectedOption === "Permits") {
      fetchPermits();
    }
  };

  useEffect(() => {
    loadDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <div className="absolute top-0 bottom-0 w-full">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div
            className={`h-[${
              hasWritePrivilege && selectedOption !== "Pilot" ? 85 : 100
            }%] flex-col`}
          >
            {/* Header */}
            <div className="flex p-4 h-[8%]">
              <h4>Assets Management</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex">
                <IoMdHelp className="fill-blue-500 m-auto" size={16} />
              </div>
            </div>
            <hr />
            <div className="flex-col">
              <div className="flex px-4 py-2 mt-2">
                <h5 className="font-medium ml-2">Select Asset Type</h5>
                <span className="grow" />
                <button
                  onClick={() => showMessage && showMessage("Coming Soon")}
                >
                  <span className="text-primary-600 text-sm font-medium">
                    Sort by: Name
                  </span>
                </button>
              </div>

              {/* Dropdown Option Picker */}
              <div className="relative px-4">
                <button
                  className="input-select"
                  type="button"
                  onClick={() => {
                    if (!isDeleting) setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <span className="grow">{selectedOption}</span>
                  {isDropdownOpen ? (
                    <IoIosArrowUp size={18} />
                  ) : (
                    <IoIosArrowDown size={18} />
                  )}
                </button>

                {isDropdownOpen && (
                  <div className="absolute top-14 w-[92%] z-[150]">
                    <ul className="dropdown-ul">
                      {options.map((option) => (
                        <li
                          key={option}
                          className="dropdown-li"
                          onClick={() => handleSelectedOption(option)}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Content */}
            <div
              className={`${
                hasWritePrivilege && selectedOption !== "Pilot"
                  ? "h-[75%]"
                  : "h-[80%]"
              } flex-col overflow-auto p-4`}
            >
              {/* List Of Pilots */}
              {selectedOption === "Pilot" &&
                pilots.length > 0 &&
                pilots.map((pilot) => (
                  <PilotItem
                    key={pilot.pilot_uuid}
                    data={pilot}
                    onClick={handleSelectPilot}
                    onSelect={handleSelectedItem}
                  />
                ))}

              {/* List Of Platforms */}
              {selectedOption === "Platforms" &&
                platforms.length > 0 &&
                platforms.map((platform) => (
                  <PlatformItem
                    key={platform.platform_uuid}
                    data={platform}
                    hasReadMaintenanceRights={userAccess.privileges.includes(
                      "assets.maintenance.read"
                    )}
                    onClick={handleSelectPlatform}
                    onSelect={handleSelectedItem}
                    onMaintenanceClick={handleMaintenancePlatform}
                  />
                ))}

              {/* List Of PlatformTypes */}
              {selectedOption === "Platform Types" &&
                platformTypes.length > 0 &&
                platformTypes.map((platformType) => (
                  <PlatformTypeItem
                    key={platformType.platform_type_uuid}
                    data={platformType}
                    onClick={handleSelectPlatformType}
                    onSelect={handleSelectedItem}
                  />
                ))}

              {/* List Of Trackers */}
              {selectedOption === "Tracker" &&
                trackers.length > 0 &&
                trackers.map((tracker) => (
                  <TrackerItem
                    key={tracker.tracker_uuid}
                    data={tracker}
                    onClick={handleSelectTracker}
                    onSelect={handleSelectedItem}
                  />
                ))}

              {/* List Of Permits */}
              {selectedOption === "Permits" &&
                permits?.length > 0 &&
                permits[0]?.downloadURL?.Contents?.map((permitContent) => (
                  <PermitItem
                    key={permitContent.Key}
                    data={permitContent}
                    onClick={handleSelectPermit}
                    onSelect={handleSelectedItem}
                  />
                ))}

              {!isLoading &&
                pilots.length === 0 &&
                platforms.length === 0 &&
                platformTypes.length === 0 &&
                trackers.length === 0 &&
                permits?.length === 0 && (
                  <p className="p-8 text-center">No Data Available</p>
                )}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          {hasWritePrivilege && selectedOption !== "Pilot" && (
            <div className="h-[15%] px-4 items-center content-center">
              {selectedIds.length > 0 ? (
                <Button
                  type="danger"
                  size="medium"
                  text="Delete"
                  className="my-5"
                  isLoading={isDeleting}
                  disabled={isDeleting}
                  icon={<FaTrash size={12} color="white" />}
                  onClick={handleDeleteItem}
                />
              ) : (
                selectedOption !== "Pilot" && (
                  <Button
                    type="primaryDark"
                    size="medium"
                    text={
                      selectedOption === "Permits"
                        ? "Upload Permit"
                        : "Create New"
                    }
                    className="my-5"
                    icon={<FaPlus size={12} color="white" />}
                    onClick={handleAddNew}
                  />
                )
              )}
            </div>
          )}
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={() => setIsAdd(false)} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">
              {`${formTitle} ${selectedOption}`}
            </h5>
          </div>
          <hr />

          {/* Pilot Form */}
          {selectedOption === "Pilot" && (
            <PilotForm
              api={api}
              pilot={selectedPilot}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              hasWritePrivilege={hasWritePrivilege}
            />
          )}

          {/* Platform Form */}
          {selectedOption === "Platforms" && (
            <PlatformForm
              api={api}
              platform={selectedPlatform}
              platformTypes={platformTypes}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              hasWritePrivilege={hasWritePrivilege}
            />
          )}

          {/* Platform Type Form */}
          {selectedOption === "Platform Types" && (
            <PlatformTypeForm
              api={api}
              platformType={selectedPlatformType}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              hasWritePrivilege={hasWritePrivilege}
            />
          )}

          {/* Tracker Form */}
          {selectedOption === "Tracker" && (
            <TrackerForm
              api={api}
              tracker={selectedTracker}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              hasWritePrivilege={hasWritePrivilege}
            />
          )}

          {/* Permit Form */}
          {selectedOption === "Permits" && (
            <PermitForm
              api={api}
              selectedPermitContent={selectedPermitContent}
              showMessage={showMessage}
              onSaveSucess={handleSaveSuccess}
              goToLocation={goToLocation}
            />
          )}
        </div>
      )}
    </div>
  );
}

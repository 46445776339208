import UserInformation from "@/model/UseInformation";
import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setUserAccess } from "@/store/actions";
import subscriptionStatus from "@/store/reducers/subscriptions";

import { FaIdCardAlt } from "react-icons/fa";
import { LuLogOut, LuShieldAlert } from "react-icons/lu";
import {
  MdLanguage,
  MdOutlinePersonOutline,
  MdOutlineGroups,
} from "react-icons/md";

import { useApi } from "../../../api/useApi";

interface UserDropdownProps {
  userInformation: UserInformation;
  className?: string;
  onMenuClicked: (id: string) => void;
  onOrganisationManagementMenuSelected(): void;
  isDJIDevice: boolean;
  showMessage?: (message: string, isError?: boolean) => void;
}

type UserRoles =
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot";

export default function UserDropdown({
  userInformation,
  className,
  onMenuClicked,
  onOrganisationManagementMenuSelected,
  isDJIDevice,
  showMessage,
}: UserDropdownProps) {
  const [isSelectingRole, setIsSelectingRole] = useState(false);
  const [availableRolesForAssignment, setAvailableRolesForAssignment] =
    useState<UserRoles[]>([]);

  const api = useApi();

  const userAccess = useSelector((state: any) => state.userAccess);
  const dispatch = useDispatch();

  useEffect(() => {
    setAvailableRolesForAssignment(userAccess.user_roles);
  }, [userAccess]);

  const handleSwitchRoleClick = () => {
    setIsSelectingRole((s) => !s);
  };

  const handleSwitchRoleSelected = async (selectedRole: string) => {
    try {
      await api.updateUserRole({
        username: userInformation.username,
        current_user_role: selectedRole,
      });
      dispatch(setUserAccess({ role: selectedRole }));
      showMessage &&
        showMessage(`Switched to ${toProperCase(selectedRole)} role`, true);
    } catch (e) {
      console.log("error : ", e);
    }
    setIsSelectingRole(false);
    onMenuClicked("switch-role");
  };

  function toProperCase(str: string) {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div
      className={`${className} user-dropdown ${
        isDJIDevice && "overflow-auto h-32"
      }`}
    >
      {/* User Information */}
      <div className="flex p-4 items-center content-center">
        <img
          className="rounded-full w-10 h-10 object-contain border-4 border-gray-400/30"
          src="/default_avatar.png"
          alt="profile"
        />
        <div className="flex flex-col ml-2">
          <span className="label-blue font-semibold">
            {userInformation.username}
          </span>
          <span className="label-secondary text-sm">
            {userInformation.email}
          </span>
        </div>
      </div>
      {/* Divider */}
      <hr />
      {/* Menu */}
      <ul>
        {!isSelectingRole ? (
          <div>
            <li onClick={() => onMenuClicked("user-profile")}>
              <MdOutlinePersonOutline size={20} color="gray" />
              <span className="ml-2">User Profile</span>
            </li>
            <li onClick={() => onOrganisationManagementMenuSelected()}>
              <MdOutlineGroups size={20} color="gray" />
              <span className="ml-2">Organisation Details</span>
            </li>
            <li onClick={() => handleSwitchRoleClick()}>
              <FaIdCardAlt size={20} color="gray" />
              <span className="grow ml-2">Switch Role</span>
              <span className="new-badge new-badge-info">
                {toProperCase(userAccess.role).replace("Manager", "Mgr")}
              </span>
            </li>
            <li onClick={() => onMenuClicked("language")}>
              <MdLanguage size={20} color="gray" />
              <span className="ml-2">Language : English</span>
            </li>
            <li onClick={() => onMenuClicked("privacy policy")}>
              <LuShieldAlert size={20} color="gray" />
              <span className="ml-2">Privacy Policy</span>
            </li>{" "}
            <hr />
            <li onClick={() => onMenuClicked("signout")}>
              <LuLogOut size={20} color="gray" />
              <span className="ml-2">Sign Out</span>
            </li>
          </div>
        ) : (
          <div>
            {availableRolesForAssignment?.length &&
              availableRolesForAssignment?.map((singleRole: UserRoles) => {
                return (
                  <li
                    key={singleRole}
                    onClick={() => handleSwitchRoleSelected(singleRole)}
                  >
                    <MdOutlinePersonOutline size={20} color="gray" />
                    <span className="ml-2">{toProperCase(singleRole)}</span>
                  </li>
                );
              })}
            <hr />
            <li onClick={() => handleSwitchRoleClick()}>
              <LuShieldAlert size={20} color="gray" />
              <span className="ml-2">Cancel</span>
            </li>
          </div>
        )}
      </ul>
    </div>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import LatLng from "@/model/LatLng";
import MapState from "@/model/MapState";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import Constraint from "@/model/api/Constraint";
import Waypoint from "@/model/Waypoint";
import { CreateOperationType } from "@/enum/CreateOperationType";
import Button from "../../Common/Button";
import CreateConstraintForm from "./CreateConstraintForm";
import ItemConstraint from "./ItemCards/ItemConstraint";

interface ConstraintManagementSidebarProps {
  api: any;
  constraintList: Constraint[];
  /* Polygon Drawing */
  selectedAreaWaypoints: number[][];
  /* Circle Drawing */
  selectedCircleWaypoints: number[][];
  setSelectedCircleWaypoints: (waypoints: number[][]) => void;
  selectedCircleProperties: SelectedCircleProperties;
  setSelectedCircleProperties: (properties: SelectedCircleProperties) => void;
  /* Others */
  setIsFormOpen: (value: boolean) => void;
  onWaypointUpdated: (waypoints: number[][]) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  handleConstraintWaypoints: (constraint: Constraint) => void;
  handleOperationFormWaypointChange: (
    formData: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => void;
}

export default function ConstraintManagementSidebar({
  api,
  constraintList,
  selectedAreaWaypoints,
  selectedCircleWaypoints,
  setSelectedCircleWaypoints,
  selectedCircleProperties,
  setSelectedCircleProperties,
  setIsFormOpen,
  onWaypointUpdated,
  onConfirmation,
  showMessage,
  goToLocation,
  onOpenModal,
  onCloseModal,
  handleConstraintWaypoints,
  handleOperationFormWaypointChange,
}: ConstraintManagementSidebarProps) {
  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );

  interface UserAccessState {
    userAccess: UserAccess;
  }

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [editConstraintData, setEditConstraintData] = useState<
    Constraint | undefined
  >();

  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const handleFocusConstraint = (item: any) => {
    const lat = item.extents[0].volume.outline_polygon.coordinates[0][0][1];
    const lng = item.extents[0].volume.outline_polygon.coordinates[0][0][0];
    mapboxController.flyTo({ lat, lng });
  };

  const handleSelectedItem = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleDeleteSelected = () => {
    onConfirmation(
      "Delete Selected Constraints",
      "Are you sure you want to delete the selected constraints?",
      (result) => {
        if (result) {
          deleteSelectedIds();
        }
      }
    );
  };

  const handleAddNew = () => {
    setShowMoreOptions(false);
    setIsAdd(true);
    setIsFormOpen(true);
  };

  const handleReturnToList = () => {
    mapboxController.removeDrawFeaturesAll();
    setIsAdd(false);
    setIsFormOpen(false);
    setEditConstraintData(undefined);
    setSelectedIds([]);
    setShowMoreOptions(false);
  };

  const handleOnConstraintSubmitted = () => {
    setIsAdd(false);
    setIsFormOpen(false);
    onWaypointUpdated([]);
    setSelectedIds([]);
  };

  const deleteSelectedIds = () => {
    setShowMoreOptions(false);
    setIsDeleting(true);

    const promises = selectedIds.map((id) =>
      api.deleteAuthorityAreaConstrain(id)
    );
    Promise.all(promises)
      .then(() => {
        showMessage && showMessage("Constraints Deleted", true);
        setSelectedIds([]);
      })
      .catch(() => {
        showMessage && showMessage("Failed to delete constraints", false, true);
      });

    setIsDeleting(false);
    setSelectedIds([]);
  };

  /* Edit Constraint */

  const handleEditClick = () => {
    if (selectedIds.length !== 1) {
      showMessage?.("Invalid Selection");
      return;
    }
    const selectedConstraintData = constraintList.filter((singleConstraint) => {
      return singleConstraint.constraint_id === selectedIds[0];
    });
    handleFocusConstraint(selectedConstraintData[0]);
    handleConstraintWaypoints(selectedConstraintData[0]);
    setEditConstraintData(selectedConstraintData[0]);
    setShowMoreOptions(false);
    setIsAdd(true);
    setIsFormOpen(true);
  };

  return (
    <div className="absolute top-0 bottom-0 w-full">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[92%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%]">
              <h4>Constraints Management</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex">
                <IoIosMore className="fill-blue-500 m-auto" size={16} />
              </div>
            </div>
            <hr />
            <div className="flex-col px-4">
              <div className="flex px-4 py-2 mt-2">
                <h5 className="font-medium ml-2">Select Contraint</h5>
                <span className="grow" />
                <button
                  onClick={() => showMessage && showMessage("Coming Soon")}
                >
                  <span className="text-primary-600 text-sm font-medium">
                    Sort by: Created Date
                  </span>
                </button>
              </div>
            </div>
            {/* Content */}
            <div
              className={`${
                userAccess.privileges.includes("constraints.write")
                  ? "h-[85%]"
                  : "h-[90%]"
              } flex-col overflow-auto p-4`}
            >
              {constraintList.length > 0 ? (
                constraintList.map((item) => (
                  <ItemConstraint
                    key={item.constraint_id}
                    data={item}
                    onClick={() => {
                      handleFocusConstraint(item);
                    }}
                    onSelected={handleSelectedItem}
                  />
                ))
              ) : (
                <p className="p-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
          {userAccess.privileges.includes("constraints.write") && (
            <div className="h-[8%] px-4 py-2 items-center content-center flex">
              {selectedIds.length > 0 ? (
                <Button
                  type="danger"
                  size="medium"
                  text="Delete"
                  className="mr-2"
                  onClick={handleDeleteSelected}
                  isLoading={isDeleting}
                />
              ) : (
                <Button
                  type="primaryDark"
                  size="medium"
                  text="Create New"
                  className="mr-2"
                  icon={<FaPlus size={12} color="white" />}
                  onClick={handleAddNew}
                />
              )}
              <Button
                type="light"
                size="medium"
                fixedWidth={true}
                className="mr-2 w-20"
                // isLoading={isDisplaying || isDeleting}
                icon={<IoEllipsisHorizontal size={24} />}
                onClick={() => setShowMoreOptions(!showMoreOptions)}
                // isLoading={isDeleting}
                // disabled={isDeleting || isDisplaying}
                // onClick={handleDeleteOperation}
              />
            </div>
          )}
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">Create Constraint</h5>
          </div>
          <hr />

          {/* Form */}
          <CreateConstraintForm
            api={api}
            selectedAreaWaypoints={selectedAreaWaypoints}
            selectedCircleWaypoints={selectedCircleWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            setSelectedCircleProperties={setSelectedCircleProperties}
            onConstraintSubmitted={handleOnConstraintSubmitted}
            onConfirmation={onConfirmation}
            showMessage={showMessage}
            goToLocation={goToLocation}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            editConstraintData={editConstraintData}
            handleOperationFormWaypointChange={
              handleOperationFormWaypointChange
            }
            // handleReturnToList={handleReturnToList}
          />
        </div>
      )}
      {/* Button Options */}
      {showMoreOptions && (
        <div className="w-40 h-9 absolute bottom-40 right-6 popup-menu">
          {/* Options concisted of export, replay, etc */}
          <ul className="flex-col">
            <li onClick={handleEditClick}>Edit</li>
          </ul>
        </div>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "@/store";
import axios from "axios";

import { WeatherForecastData } from "@/model/api/WeatherForecast";
import MapState from "@/model/MapState";

import { IoIosArrowUp, IoIosArrowDown, IoMdPin } from "react-icons/io";
import { MdMyLocation } from "react-icons/md";
import { FaCloudRain } from "react-icons/fa6";

import WeatherPanelItem from "./WeatherPanelItem";

interface OperationPanelProps {
  api: any;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

export default function WeatherPanel({
  api,
  showMessage,
  onOpenModal,
  onCloseModal,
}: OperationPanelProps) {
  const [operationPanelExpanded, setOperationPanelExpanded] = useState(false);
  const [opPanelSize, setOpPanelSize] = useState(
    "h-4 mx-2 rounded-t-none rounded-b-lg"
  );

  const [location, setLocation] = useState("");
  const [weatherForecastList, setWeatherForecastList] = useState<
    WeatherForecastData[]
  >([]);

  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );

  /* Handler Function */
  const handleToggleOperationPanel = () => {
    setOperationPanelExpanded(!operationPanelExpanded);
    if (!operationPanelExpanded) {
      setOpPanelSize("h-[205px] mx-0 mt-1 rounded-lg mb-1");
    } else {
      setOpPanelSize("h-4 mx-2 rounded-t-none rounded-b-lg");
    }
  };

  const getWeatherForecast = async (lng: string, lat: string) => {
    try {
      const openWeatherApiKey =
        store.getState().envVar["api_key-openweather"].Value;
      const weatherForcast = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=${openWeatherApiKey}&units=metric`
      );
      setWeatherForecastList(weatherForcast?.data?.list);
      setLocation(
        `${weatherForcast?.data?.city?.country} : ${weatherForcast?.data?.city?.name}` ||
          ""
      );
    } catch (err) {
      let message = "Error obtaining weather data";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      // setIsLoading(false);
      showMessage && showMessage(message, false, true);
    }
  };

  const updateLocation = () => {
    if (!mapboxController) return;
    const { lng, lat } = mapboxController.map.getCenter();
    if (!lng || !lat) return;
    getWeatherForecast(lng, lat);
  };
  useEffect(() => {
    if (!mapboxController) return;
    updateLocation();

    // Set interval to run every 10 minutes
    const intervalId = setInterval(() => {
      updateLocation();
    }, 600000);

    return () => clearInterval(intervalId);
  }, [mapboxController]);

  return (
    <div className="flex-col w-[350px]">
      <div
        className="w-full rounded-container p-2 flex items-center content-center select-none cursor-pointer"
        onClick={handleToggleOperationPanel}
      >
        <div className="rounded-lg bg-blue-200 h-14 w-14 content-center items-center">
          <FaCloudRain size={28} className="m-auto fill-blue-500" />
        </div>
        <div className="flex flex-col px-4 grow">
          <h5 className="font-medium text-lg">Weather Panel</h5>
          <p>Weather Forecast</p>
        </div>
        {operationPanelExpanded ? (
          <IoIosArrowUp size={20} color="gray" className="mr-2" />
        ) : (
          <IoIosArrowDown size={20} color="gray" className="mr-2" />
        )}
      </div>
      <div
        className={`bg-white dark:bg-darkSecondary flex-col border-gray2 dark:border-darkTertiary border p-2 transition-all duration-500 ease-in-out ${opPanelSize}`}
      >
        {operationPanelExpanded && (
          <div className="flex-col overflow-auto h-[95%] pb-6">
            <div className="mb-3 flex items-center justify-between p-2 dark:text-white">
              <span className="flex items-center">
                <IoMdPin size={18} color="gray" className="mr-2" />
                <b>{location}</b>
              </span>
              <button>
                <MdMyLocation
                  size={18}
                  className="text-blue-500 mr-1"
                  onClick={updateLocation}
                />
              </button>
            </div>
            {weatherForecastList?.map((weatherForecast) => (
              <WeatherPanelItem
                key={weatherForecast.dt}
                weatherForecast={weatherForecast}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

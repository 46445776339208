import UserAccess from "@/model/UserAccess";

const TrackerManagement = {
  iconDefault: "/sidebar-menu/menu_tracker_light_default.png",
  iconActive: "/sidebar-menu/menu_tracker_light_active.png",
  title: "Tracker Management",
  isActive: false,
};

const FlightLogs = {
  iconDefault: "/sidebar-menu/menu_flight_log_light.png",
  iconActive: "/sidebar-menu/menu_flight_log_active.png",
  title: "Flight Logs",
  isActive: false,
};

const FlightAuthorization = {
  iconDefault: "/sidebar-menu/menu_waypoint_light_default.png",
  iconActive: "/sidebar-menu/menu_waypoint_light_active.png",
  title: "Flight Authorization",
  isActive: false,
};

const IncidentReports = {
  iconDefault: "/sidebar-menu/menu_reports_light_default.png",
  iconActive: "/sidebar-menu/menu_reports_light_active.png",
  title: "Incident Reports",
  isActive: false,
};

const ImportOperation = {
  iconDefault: "/sidebar-menu/menu_import_light_default.png",
  iconActive: "/sidebar-menu/menu_import_light_active.png",
  title: "DJI Integration",
  isActive: false,
};

const AssetsManagement = {
  iconDefault: "/sidebar-menu/menu_drone_light_default.png",
  iconActive: "/sidebar-menu/menu_drone_light_active.png",
  title: "Assets Management",
  isActive: false,
};

const ConstraintsManagement = {
  iconDefault: "/sidebar-menu/menu_constraint_light_default.png",
  iconActive: "/sidebar-menu/menu_constraint_light_active.png",
  title: "Constraints Management",
  isActive: false,
};

const OperatorManagement = {
  iconDefault: "/sidebar-menu/menu_organisation_light_default.png",
  iconActive: "/sidebar-menu/menu_organisation_light_active.png",
  title: "Organisation Management",
  isActive: false,
};

const ScheduleManagement = {
  iconDefault: "/sidebar-menu/menu_schedule_light_default.png",
  iconActive: "/sidebar-menu/menu_schedule_light_active.png",
  title: "Schedule Management",
  isActive: false,
};

const FlightReplay = {
  iconDefault: "/sidebar-menu/menu_replay_light_default.png",
  iconActive: "/sidebar-menu/menu_replay_light_active.png",
  title: "Flight Replay",
  isActive: false,
};

const UserManagement = {
  iconDefault: "/sidebar-menu/menu_group_light_default.png",
  iconActive: "/sidebar-menu/menu_group_light_active.png",
  title: "User Management",
  isActive: false,
};

const WeatherAnalysis = {
  iconDefault: "/sidebar-menu/menu_weather_light_default.png",
  iconActive: "/sidebar-menu/menu_weather_light_active.png",
  title: "Weather Analysis",
  isActive: false,
};
export default function sideBarAccessCheck(userAccess: UserAccess) {
  const sideBarAccess = [];

  const { role } = userAccess;
  if (role === "administrator") {
    if (userAccess.privileges.includes("general.organisation.read")) {
      sideBarAccess.push(UserManagement);
    }

    if (
      [
        "assets.pilot.read",
        "assets.platform.read",
        "assets.platformtype.read",
        "assets.tracker.read",
      ].some((privilege) => userAccess.privileges.includes(privilege))
    ) {
      sideBarAccess.push(AssetsManagement);
    }
  }

  if (role === "airspace_manager") {
    if (userAccess.privileges.includes("constraints.read")) {
      sideBarAccess.push(ConstraintsManagement);
    }

    if (userAccess.privileges.includes("airspace.organisations.read")) {
      sideBarAccess.push(OperatorManagement);
    }

    if (userAccess.privileges.includes("airspace.operations.read")) {
      sideBarAccess.push(FlightAuthorization);
    }

    if (
      [
        "assets.pilot.read",
        "assets.platform.read",
        "assets.platformtype.read",
        "assets.tracker.read",
      ].some((privilege) => userAccess.privileges.includes(privilege))
    ) {
      sideBarAccess.push(AssetsManagement);
    }

    if (userAccess.privileges.includes("operations.read")) {
      sideBarAccess.push(FlightLogs);
    }

    if (userAccess.privileges.includes("operations.incident.read")) {
      sideBarAccess.push(IncidentReports);
    }
  }
  if (role === "flight_manager") {
    if (userAccess.privileges.includes("operations.read")) {
      sideBarAccess.push(FlightAuthorization);
    }

    if (userAccess.privileges.includes("schedules.read")) {
      sideBarAccess.push(ScheduleManagement);
    }

    if (
      (userAccess.privileges.includes("schedules.read") ||
        userAccess.privileges.includes("operations.read")) &&
      userAccess.privileges.includes("sdsp.read")
    ) {
      sideBarAccess.push(WeatherAnalysis);
    }

    if (
      [
        "assets.pilot.read",
        "assets.platform.read",
        "assets.platformtype.read",
        "assets.tracker.read",
      ].some((privilege) => userAccess.privileges.includes(privilege))
    ) {
      sideBarAccess.push(AssetsManagement);
    }

    if (userAccess.privileges.includes("operations.read")) {
      sideBarAccess.push(FlightLogs);
    }

    if (userAccess.privileges.includes("operations.incident.read")) {
      sideBarAccess.push(IncidentReports);
    }

    if (userAccess.privileges.includes("operations.integrations.read")) {
      sideBarAccess.push(ImportOperation);
    }
  }
  if (role === "pilot") {
    if (userAccess.privileges.includes("operations.read")) {
      sideBarAccess.push(FlightAuthorization);
    }

    if (userAccess.privileges.includes("schedules.read")) {
      sideBarAccess.push(ScheduleManagement);
    }

    if (
      (userAccess.privileges.includes("schedules.read") ||
        userAccess.privileges.includes("operations.read")) &&
      userAccess.privileges.includes("sdsp.read")
    ) {
      sideBarAccess.push(WeatherAnalysis);
    }

    if (userAccess.privileges.includes("operations.rid.read")) {
      sideBarAccess.push(TrackerManagement);
    }
    if (
      [
        "assets.pilot.read",
        "assets.platform.read",
        "assets.platformtype.read",
        "assets.tracker.read",
      ].some((privilege) => userAccess.privileges.includes(privilege))
    ) {
      sideBarAccess.push(AssetsManagement);
    }

    if (userAccess.privileges.includes("operations.read")) {
      sideBarAccess.push(FlightLogs);
    }

    if (userAccess.privileges.includes("operations.incident.read")) {
      sideBarAccess.push(IncidentReports);
    }

    if (userAccess.privileges.includes("operations.integrations.read")) {
      sideBarAccess.push(ImportOperation);
    }
  }
  return sideBarAccess;
}

import React from "react";
import MaintenanceRecord from "@/model/api/Maintenance";
import moment from "moment";

interface MaintenanceItemProps {
  data: MaintenanceRecord;
  onClick?: (maintenanceRecord: MaintenanceRecord) => void;
  onSelect?: (id: string, isSelected: boolean) => void;
}

export default function MaintenanceItem({
  data,
  onClick,
  onSelect,
}: MaintenanceItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(data.record_uuid, e.target.checked);
  };

  return (
    <div className="relative p-2 rounded-container mb-2 cursor-pointer">
      <div
        key={data.record_uuid}
        onClick={() => onClick && onClick(data)}
        className="flex-col"
      >
        <h5 className="font-medium">{data.purpose}</h5>
        <p>Defect : {data.defect}</p>
        <p className="mb-2">Conducted By : {data.conducted_by}</p>
        <div className="flex flex-wrap">
          {/* <span className="new-badge new-badge-success mb-2">
            {data.maintenance_date
              ? formatToDisplayDate(data.maintenance_date)
              : ""}
          </span> */}
          <span className="new-badge mb-2 mr-2">
            {moment(data.maintenance_date).format("DD/MM/YYYY hh:mm a")}
          </span>
        </div>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}

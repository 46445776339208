import { OperationJson } from "@/model/api/Operation";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoMdPerson } from "react-icons/io";
import { MdOutlineSettingsRemote, MdKeyboardArrowRight } from "react-icons/md";
import { PiDrone } from "react-icons/pi";

interface OperationPanelItemProps {
  flightData: OperationJson;
  platformName?: string;
  trackerName?: string;
  pilotName?: string;
  onClick?: () => void;
  isRemoteId?: boolean;
}

export default function OperationPanelItem({
  flightData,
  platformName,
  trackerName,
  pilotName,
  onClick,
  isRemoteId,
}: OperationPanelItemProps) {
  const [operationStateColor, setOperationStateColor] = useState("");
  useEffect(() => {
    const { state } = flightData.details;

    switch (state.toLowerCase()) {
      case "activated":
        setOperationStateColor("new-badge-success");
        break;
      case "nonconforming":
        setOperationStateColor("new-badge-warning");
        break;
      case "contingent":
        setOperationStateColor("new-badge-danger");
        break;
      default:
        setOperationStateColor(""); // default or unknown state color
        break;
    }
  }, [flightData]);
  return (
    <div className="flex-col pb-4">
      <div
        className="flex p-1 rounded-container items-center cursor-pointer"
        onClick={onClick}
      >
        <div className="flex-col">
          <div className="w-14 h-14 bg-gray-100 rounded-md content-center items-center mb-2">
            <img
              src="https://www1.djicdn.com/cms/uploads/de09b4d004c7d7638f4697e286defe8c.png"
              alt="ship"
              className="w-14 h-14"
            />
          </div>
          <span className="new-badge font-medium">
            {flightData.request.operation_type.toUpperCase()}
          </span>
        </div>
        <div className="flex-col grow">
          <h5 className="font-medium mb-1">{flightData.reference.intent}</h5>
          {!isRemoteId && (
            <div className="flex mb-1 items-center">
              <PiDrone size={14} />
              <p className="ml-2">{platformName}</p>
            </div>
          )}
          <div className="flex mb-1 items-center">
            <MdOutlineSettingsRemote size={14} />
            <p className="ml-2">{trackerName}</p>
          </div>
        </div>
        <div className="flex-col content-end items-end justify-end">
          <div className="flex items-center content-center">
            <span className="text-sm">
              {moment(flightData.reference.time_start.value).format("HH:mm A")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
          <div className="flex items-center content-center mt-5">
            <span className="text-sm">
              {moment(flightData.reference.time_end.value).format("HH:mm A")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
        </div>
      </div>
      <div
        className="rounded-b-lg flex p-2 border border-gray-300 -mt-1 bg-gray-100 items-center content-center"
        // onClick={() => setViewOperationDetail(true)}
      >
        <IoMdPerson />
        <span className="ml-2">{pilotName}</span>
        <span className="grow" />
        {flightData.request.operation_type && (
          <span className={`new-badge ${operationStateColor} text-sm`}>
            {flightData.details.state.toUpperCase()}
          </span>
        )}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { WeatherForecastData } from "@/model/api/WeatherForecast";
import moment from "moment";
import { GiRaining } from "react-icons/gi";
import { MdOutlineWindPower, MdVisibility } from "react-icons/md";

interface WeathernPanelItemProps {
  weatherForecast: WeatherForecastData;
  onClick?: () => void;
}

function getCardinalDirection(angle: number) {
  const directions = [
    "↑ S",
    "↗ SW",
    "→ W",
    "↘ NW",
    "↓ N",
    "↙ NE",
    "← E",
    "↖ SE",
  ];
  return directions[Math.round((angle + 180) / 45) % 8];
}

function toProperCase(str: string) {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function WeatherPanelItem({
  weatherForecast,
  onClick,
}: WeathernPanelItemProps) {
  return (
    <div className="flex-col pb-4">
      <div
        className="flex p-1 rounded-container items-center"
        onClick={onClick}
      >
        <div className="flex-col">
          <div className="w-12 h-12 bg-blue-100 rounded-md content-center items-center mb-2 mr-2">
            <img
              src={`https://openweathermap.org/img/wn/${weatherForecast.weather[0].icon}@2x.png`}
              alt="ship"
              className="w-12 h-12"
            />
          </div>
        </div>
        <div className="flex-col grow">
          <h5 className="font-medium mb-1">
            {toProperCase(weatherForecast.weather[0].description)}
          </h5>
          <div className="flex mb-1 items-center">
            <MdVisibility size={14} className="mr-2" />
            <p>{(weatherForecast.visibility / 1000).toFixed(2)} km</p>
          </div>
          <div className="flex mb-1 items-center">
            <GiRaining size={14} className="mr-2" />
            <p>
              {weatherForecast?.rain?.["3h"]
                ? weatherForecast?.rain?.["3h"]
                : 0}{" "}
              mm/h
            </p>
          </div>
        </div>
        <div className="flex flex-col items-end justify-end">
          <div className="flex items-center justify-end">
            <span className="text-sm text-right">
              {moment(weatherForecast.dt * 1000).format("DD/MM/YYYY")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
          <div className="flex items-center justify-end mt-5">
            <span className="text-sm text-right">
              {moment(weatherForecast.dt * 1000).format("h:mm a")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
        </div>
      </div>
      <div
        className="rounded-b-lg flex p-2 border border-gray-300 -mt-1 bg-gray-100 items-center content-center"
        // onClick={() => setViewOperationDetail(true)}
      >
        <MdOutlineWindPower size={14} />
        <span className="ml-2">{weatherForecast.wind.speed} m/s</span>
        <span className="grow" />
        <span className="new-badge font-medium">
          {getCardinalDirection(weatherForecast.wind.deg)}
        </span>
      </div>
    </div>
  );
}

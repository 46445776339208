const takeoffPoint = [
  {
    id: "MB",
    name: "Marina Barrage",
    coordinates: [
      {
        lat: 1.280176,
        lng: 103.871798,
      },
    ],
  },
  {
    id: "MDE",
    name: "Maritime Drone Estate",
    coordinates: [
      {
        lat: 1.272049,
        lng: 103.864779,
      },
      {
        lat: 1.2716718545273977,
        lng: 103.86504164148766,
      },
      {
        lat: 1.271550591125731,
        lng: 103.86778538103817,
      },
      {
        lat: 1.2703523702183048,
        lng: 103.86864164316808,
      },
    ],
  },
  {
    id: "ES",
    name: "Sentosa East (Tanjong)",
    coordinates: [
      {
        lat: 1.241697,
        lng: 103.82938,
      },
    ],
  },
  {
    id: "WS",
    name: "Sentosa West (Palawan)",
    coordinates: [
      {
        lat: 1.248282,
        lng: 103.822531,
      },
    ],
  },
  {
    id: "SCP",
    name: "Siglap Canal Pier",
    coordinates: [
      {
        lat: 1.301931,
        lng: 103.919169,
      },
    ],
  },
];

const anchorageDetails = [
  {
    id: "way/182075709",
    name: "Selat Pauh (ASPLU)",
    coordinates: [
      {
        lat: 1.221172,
        lng: 103.734066,
      },
    ],
  },
  {
    id: "way/182075788",
    name: "Eastern (AEW)",
    coordinates: [
      {
        lat: 1.265241,
        lng: 103.877238,
      },
    ],
  },
  {
    id: "way/182075839",
    name: "Changi Barge Temporary Holding (ACBTH)",
    coordinates: [
      {
        lat: 1.339865,
        lng: 104.052383,
      },
    ],
  },
  {
    id: "way/182075846",
    name: "Sudong Holding (ASH)",
    coordinates: [
      {
        lat: 1.201104,
        lng: 103.669732,
      },
    ],
  },
  {
    id: "way/182075862",
    name: "Changi General Purposes (ACGP) No. 4",
    coordinates: [
      {
        lat: 1.317099,
        lng: 104.071707,
      },
    ],
  },
  {
    id: "way/182075873",
    name: "Eastern Bunkering A (AEBA) Sector E",
    coordinates: [
      {
        lat: 1.293725,
        lng: 104.051495,
      },
    ],
  },
  {
    id: "way/182075880",
    name: "Eastern Bunkering A (AEBA) Sector G",
    coordinates: [
      {
        lat: 1.297212,
        lng: 104.076162,
      },
    ],
  },
  {
    id: "way/182075884",
    name: "Eastern Holding A (AEHA)",
    coordinates: [
      {
        lat: 1.248865,
        lng: 103.923895,
      },
    ],
  },
  {
    id: "way/182075887",
    name: "Eastern Holding B (AEHB)",
    coordinates: [
      {
        lat: 1.24264,
        lng: 103.888788,
      },
    ],
  },
  {
    id: "way/182075899",
    name: "Changi General Purposes (ACGP) No. 3",
    coordinates: [
      {
        lat: 1.322212,
        lng: 104.066809,
      },
    ],
  },
  {
    id: "way/182075914",
    name: "Eastern Bunkering A (AEBA) Sector D",
    coordinates: [
      {
        lat: 1.301883,
        lng: 104.051491,
      },
    ],
  },
  {
    id: "way/182075927",
    name: "Eastern Bunkering B (AEBB) Sector K",
    coordinates: [
      {
        lat: 1.289854,
        lng: 104.001918,
      },
    ],
  },
  {
    id: "way/182075942",
    name: "Sudong Bunkering B (ASUBB)",
    coordinates: [
      {
        lat: 1.171248,
        lng: 103.713694,
      },
    ],
  },
  {
    id: "way/182075955",
    name: "Sudong Special Purpose (ASSPU)",
    coordinates: [
      {
        lat: 1.189282,
        lng: 103.69326,
      },
    ],
  },
  {
    id: "way/182075957",
    name: "Eastern Bunkering B (AEBB) Sector N",
    coordinates: [
      {
        lat: 1.2838,
        lng: 104.001916,
      },
    ],
  },
  {
    id: "way/182075999",
    name: "Eastern Bunkering C (AEBC) Sector S",
    coordinates: [
      {
        lat: 1.272807,
        lng: 103.942932,
      },
    ],
  },
  {
    id: "way/182076025",
    name: "Eastern Explosives Lighters (AEEL)",
    coordinates: [
      {
        lat: 1.284424,
        lng: 103.895466,
      },
    ],
  },
  {
    id: "way/182076032",
    name: "Eastern Bunkering C (AEBC) Sector Q",
    coordinates: [
      {
        lat: 1.272813,
        lng: 103.96518,
      },
    ],
  },
  {
    id: "way/182076046",
    name: "Eastern Bunkering A (AEBA) Sector F",
    coordinates: [
      {
        lat: 1.295213,
        lng: 104.06214,
      },
    ],
  },
  {
    id: "way/182076067",
    name: "Eastern Bunkering A (AEBA) Sector A",
    coordinates: [
      {
        lat: 1.304019,
        lng: 104.08419,
      },
    ],
  },
  {
    id: "way/182076070",
    name: "Eastern Bunkering A (AEBA) Sector H",
    coordinates: [
      {
        lat: 1.299026,
        lng: 104.08895,
      },
    ],
  },
  {
    id: "way/182076090",
    name: "Sudong Explosive (ASUEX)",
    coordinates: [
      {
        lat: 1.180981,
        lng: 103.703941,
      },
    ],
  },
  {
    id: "way/182076103",
    name: "Eastern Bunkering A (AEBA) Sector B",
    coordinates: [
      {
        lat: 1.303329,
        lng: 104.073732,
      },
    ],
  },
  {
    id: "way/182076112",
    name: "Eastern Holding C (AEHC)",
    coordinates: [
      {
        lat: 1.249994,
        lng: 103.868283,
      },
    ],
  },
  {
    id: "way/182076119",
    name: "Eastern Bunkering B (AEBB) Sector L",
    coordinates: [
      {
        lat: 1.288422,
        lng: 103.989222,
      },
    ],
  },
  {
    id: "way/182076122",
    name: "Eastern Bunkering C (AEBC) Sector R",
    coordinates: [
      {
        lat: 1.272819,
        lng: 103.954607,
      },
    ],
  },
  {
    id: "way/182076129",
    name: "Changi General Purposes (ACGP) No. 2",
    coordinates: [
      {
        lat: 1.327065,
        lng: 104.062169,
      },
    ],
  },
  {
    id: "way/182076160",
    name: "Small Craft (ASC)",
    coordinates: [
      {
        lat: 1.28288,
        lng: 103.920447,
      },
    ],
  },
  {
    id: "way/182076172",
    name: "Eastern Petroleum A (AEPA)",
    coordinates: [
      {
        lat: 1.25641,
        lng: 103.91257,
      },
    ],
  },
  {
    id: "way/182076176",
    name: "Western Petroleum A (AWPA)",
    coordinates: [
      {
        lat: 1.240569,
        lng: 103.796922,
      },
    ],
  },
  {
    id: "way/182076197",
    name: "Changi General Purposes (ACGP) No. 1",
    coordinates: [
      {
        lat: 1.331302,
        lng: 104.058151,
      },
    ],
  },
  {
    id: "way/182076205",
    name: "Laid-Up Vessels (ALUV)",
    coordinates: [
      {
        lat: 1.287011,
        lng: 103.929915,
      },
    ],
  },
  {
    id: "way/182076207",
    name: "Eastern Special Purposes B (AESPB)",
    coordinates: [
      {
        lat: 1.300465,
        lng: 103.962949,
      },
    ],
  },
  {
    id: "way/182076222",
    name: "Eastern Bunkering B (AEBB) Sector J",
    coordinates: [
      {
        lat: 1.291304,
        lng: 104.014477,
      },
    ],
  },
  {
    id: "way/182076224",
    name: "Western Holding (AWH)",
    coordinates: [
      {
        lat: 1.235596,
        lng: 103.789733,
      },
    ],
  },
  {
    id: "way/182076226",
    name: "Eastern Bunkering A (AEBA) Sector C",
    coordinates: [
      {
        lat: 1.303275,
        lng: 104.061571,
      },
    ],
  },
  {
    id: "way/182076230",
    name: "Raffles Reserved (ARAFR)",
    coordinates: [
      {
        lat: 1.190757,
        lng: 103.747874,
      },
    ],
  },
  {
    id: "way/182076233",
    name: "Eastern Bunkering B (AEBB) Sector M",
    coordinates: [
      {
        lat: 1.281551,
        lng: 103.989206,
      },
    ],
  },
  {
    id: "way/182076235",
    name: "Sudong Bunkering A (ASUBA)",
    coordinates: [
      {
        lat: 1.196079,
        lng: 103.68004,
      },
    ],
  },
  {
    id: "way/182076239",
    name: "Eastern Bunkering B (AEBB) Sector P",
    coordinates: [
      {
        lat: 1.286447,
        lng: 104.016842,
      },
    ],
  },
  {
    id: "way/182076263",
    name: "Eastern Bunkering C (AEBC) Sector T",
    coordinates: [
      {
        lat: 1.273867,
        lng: 103.931704,
      },
    ],
  },
  {
    id: "way/182076269",
    name: "Western Quarantine and Immigration (AWQI)",
    coordinates: [
      {
        lat: 1.218353,
        lng: 103.822399,
      },
    ],
  },
  {
    id: "way/182076274",
    name: "Western Petroleum B (AWPB)",
    coordinates: [
      {
        lat: 1.226208,
        lng: 103.804007,
      },
    ],
  },
  {
    id: "way/182076280",
    name: "Man-of-War (AMOW)",
    coordinates: [
      {
        lat: 1.309672,
        lng: 104.066608,
      },
    ],
  },
  {
    id: "way/182076286",
    name: "Eastern Petroleum B (AEPB)",
    coordinates: [
      {
        lat: 1.295324,
        lng: 103.954367,
      },
    ],
  },
  {
    id: "way/182076291",
    name: "Eastern Special Purposes A (AESPA)",
    coordinates: [
      {
        lat: 1.281305,
        lng: 103.952893,
      },
    ],
  },
  {
    id: "way/182076300",
    name: "Eastern Special Purposes D (AESPD)",
    coordinates: [
      {
        lat: 1.296933,
        lng: 103.997598,
      },
    ],
  },
  {
    id: "way/182076302",
    name: "Western (AWW)",
    coordinates: [
      {
        lat: 1.239147,
        lng: 103.811329,
      },
    ],
  },
];

const data = {
  takeoffPoint,
  anchorageDetails,
};

export default data;

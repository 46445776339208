import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl-style-switcher/styles.css";
import "../custom-mapbox.css";
import "../windmarker/windmarker.css";
import "../windmarker/fonts/fonts.css";

import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useApi } from "../../../api/useApi";
import { store } from "../../../store";

// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { addConstraintSource, getConstraintsFeatures } from "../layers";

/**
 * Component encapsulating MapBox map
 * @param {*} props Props to pass in for the MapB component
 * @returns Component that displays MapBox map
 */
export default function EmbedMapB(props) {
  const {
    mapHeight = document.documentElement.clientHeight - 300, // Reduce map height to accomodate header
    compareData,
    compareCurrentData,
    isWeatherAnalysis = false,
  } = props;
  // console.log("compare data", compareData);
  // console.log("compare current data tesing", compareCurrentData);
  const api = useApi();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const [lng, setLng] = useState(103.8167107035549);
  const [lat, setLat] = useState(1.366701902383488);
  const [zoom, setZoom] = useState(13);
  const [modelMap, setModelMap] = useState(new Map());
  const [style, setStyle] = useState(null);
  const [focussedOperation, setFocussedOperation] = useState(null);

  /**
   * Initialise map with Dark styling with default zoom level 11 and centered at Singapore
   */
  useEffect(() => {
    mapboxgl.accessToken = store.getState().envVar["api_key-mapbox"].Value;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [lng, lat],
      zoom,
    });

    /**
     * Add controls onto the current map on loading of site
     */
    map.current.on("load", () => {
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        }),
        "top-right"
      );
      let constraints = [];
      const styles = [
        {
          title: "Street",
          uri: "mapbox://styles/mapbox/streets-v9",
        },
        {
          title: "Outdoors",
          uri: "mapbox://styles/mapbox/outdoors-v11",
        },
        {
          title: "Light",
          uri: "mapbox://styles/mapbox/light-v10",
        },
        {
          title: "Dark",
          uri: "mapbox://styles/mapbox/dark-v10",
        },
      ];

      map.current.on("styledataloading", () => {
        map.current.once("styledata", (e) => {
          setStyle(e.style);
        });
      });

      map.current.on("click", "outline", (e) => {
        if (!isWeatherAnalysis) return;
        let weatherAnalysis;
        try {
          weatherAnalysis = JSON.parse(e.features[0].properties.weather);
        } catch {
          weatherAnalysis = [];
        }
        let popupHTML = `<table>
        <style>
          tr.const:nth-of-type(even) {
            background-color: #ffcccb;
          }
        </style>
        <tr><td><b>Flight Time : ${e.features[0].properties.time}</b></td></tr>
        <tr class="const"><td><strong>Status:</strong> ${e.features[0].properties.status}</td></tr>
        <tr class="const"><td><strong>EAS :</strong> ${e.features[0].properties.speed_eas}</td></tr>
        <tr class="const"><td><strong>GS :</strong> ${e.features[0].properties.speed_gs}</td></tr>`;

        weatherAnalysis.map((singleWeatherData) => {
          if (!singleWeatherData) return;
          popupHTML += `
            <tr class="const"><td><strong>${singleWeatherData?.name} status :</strong> ${singleWeatherData.status}</td></tr>
            <tr class="const"><td><strong>${singleWeatherData?.name} :</strong> ${singleWeatherData?.value} ${singleWeatherData.unit}</td></tr>
          `;
        });
        popupHTML += "</table>";

        new mapboxgl.Popup()
          .setLngLat(e.lngLat) // Use the location of the click
          .setHTML(popupHTML)
          .addTo(map.current);
      });

      // Change cursor to pointer when hovering over the line
      map.current.on("mouseenter", "outline", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Reset cursor to default when no longer hovering
      map.current.on("mouseleave", "outline", () => {
        map.current.getCanvas().style.cursor = "";
      });

      const addCurrentCompareLayer = async () => {
        if (!map.current || !compareCurrentData) return;
        if (isWeatherAnalysis) {
          let previousSegment;
          const getSegmentColor = (status) => {
            if (status === "severe") {
              return "#FF0000";
            }
            if (status === "moderate") {
              return "#FFFF00";
            }
            return "#00FF00";
          };
          const geoJsonData = {
            type: "FeatureCollection",
            features: compareCurrentData.flatMap((segment) => {
              const coordinates = previousSegment
                ? [
                    previousSegment,
                    [segment.longitude, segment.latitude, segment.altitude],
                  ]
                : [[segment.longitude, segment.latitude, segment.altitude]];

              const lineFeature = {
                type: "Feature",
                properties: {
                  status: segment.status,
                  time: moment(segment.timeframe).format("hh:mm a"),
                  speed_gs: `${segment.speed_gs.value} ${segment.speed_gs.unit}`,
                  speed_eas: `${segment.speed_eas.value} ${segment.speed_eas.unit}`,
                  weather: segment.weather_parameter,
                  color: getSegmentColor(segment.status),
                },
                geometry: {
                  type: "LineString",
                  coordinates,
                },
              };

              const pointFeature = {
                type: "Feature",
                properties: {
                  description: "Point",
                  color: "#000000",
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    segment.longitude,
                    segment.latitude,
                    segment.altitude,
                  ],
                },
              };

              previousSegment = [
                segment.longitude,
                segment.latitude,
                segment.altitude,
              ];

              return [lineFeature, pointFeature];
            }),
          };
          map.current.addSource("comparecurrent", {
            type: "geojson",
            data: geoJsonData,
          });

          map.current.addLayer({
            id: "outline",
            type: "line",
            source: "comparecurrent",
            layout: { "line-join": "round", "line-cap": "round" },
            paint: {
              "line-color": ["get", "color"],
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                5,
                5,
                10,
                10,
                15,
                10,
              ],
            },
          });
          map.current.addLayer({
            id: "point-layer",
            type: "circle",
            source: "comparecurrent",
            paint: {
              "circle-color": "#000000",
              "circle-radius": 3,
            },
          });
        } else {
          const dataCoordinate = compareCurrentData.area_coordinates
            ? compareCurrentData.area_coordinates
            : compareCurrentData.waypoints;
          map.current.addSource("comparecurrent", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: dataCoordinate,
              },
            },
          });

          map.current.addLayer({
            id: "outline",
            type: "line",
            source: "comparecurrent",
            layout: { "line-join": "round", "line-cap": "round" },
            paint: {
              "line-color": "#0080ff",
              "line-width": 3,
            },
          });
        }
      };
      const addCompareLayer = async () => {
        if (!map.current || !compareData) return;
        const operationS = compareData.details.operation_volumes.map(function (
          d
        ) {
          return {
            type: "Feature",
            properties: {
              height: d.volume.altitude_upper.value,
              base: d.volume.altitude_lower.value,
            },
            geometry: d.volume.outline_polygon,
          };
        });
        map.current.addSource("comparecurrent-polygon", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: operationS,
          },
        });

        // Add a new layer to visualize the polygon.
        map.current.addLayer({
          id: "comparePolygon",
          type: "fill-extrusion",
          source: "comparecurrent-polygon", // reference the data source
          paint: {
            "fill-extrusion-color": "grey",
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": ["get", "base"],
            "fill-extrusion-opacity": 0.5,
          },
        });

        map.current.flyTo({
          center:
            compareData.details.operation_volumes[0].volume.outline_polygon
              .coordinates[0][0],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      };
      const updateConstraintsLayer = async () => {
        try {
          const res = await api.getConstraints();
          constraints = res.data;
        } catch (err) {
          console.log(`Error in getting constraints.${err}`);
          if (
            err?.response?.data?.message ===
            "No active constraints can be retrieved."
          )
            return;
        }
        map.current.addSource("constraints-coords", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: constraints,
          },
        });
        const constraintSource = map.current.getSource("constraints-coords");
        if (!constraintSource) return;
        constraintSource.setData({
          type: "FeatureCollection",
          features: getConstraintsFeatures(constraints, focussedOperation),
        });
        map.current.addLayer({
          id: "constraints",
          type: "fill-extrusion",
          source: "constraints-coords", // reference the data source
          paint: {
            "fill-extrusion-color": "red",
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": ["get", "base"],
            "fill-extrusion-opacity": 0.2,
          },
        });
      };
      updateConstraintsLayer();
      addCurrentCompareLayer();
      addCompareLayer();

      if (isWeatherAnalysis) {
        map.current.flyTo({
          center: [
            compareCurrentData[0].longitude,
            compareCurrentData[0].latitude,
          ],
          zoom: 15,
          essential: true,
        });
      }
    });
  }, [compareCurrentData, compareData, focussedOperation]);

  return (
    <LoadingOverlay>
      <div ref={mapContainer} style={{ height: mapHeight, width: "100%" }} />
    </LoadingOverlay>
  );
}

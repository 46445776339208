/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useState } from "react";
import Button from "@/components/v1/Common/Button";
import PlatformType from "@/model/api/PlatformType";

interface PlatformTypeFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  platformType?: PlatformType | null;
  showMessage?: (message: string, isError?: boolean) => void;
  onSaveSucess: () => void;
  hasWritePrivilege: boolean;
}

export default function PlatformTypeForm({
  api,
  platformType,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
}: PlatformTypeFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    platform_type_uuid: platformType?.platform_type_uuid,
    model: platformType?.model,
    airframe: platformType?.airframe || "",
    weight: platformType?.weight,
    endurance: platformType?.endurance,
    unfolded_length: platformType?.unfolded_length,
    unfolded_width: platformType?.unfolded_width,
    unfolded_height: platformType?.unfolded_height,
    max_speed_asc: platformType?.max_speed_asc,
    max_speed_cruise: platformType?.max_speed_cruise,
    max_speed_desc: platformType?.max_speed_desc,
    max_pitch_rate: platformType?.max_pitch_rate,
    max_roll_rate: platformType?.max_roll_rate,
    max_yaw_rate: platformType?.max_yaw_rate,
    max_pitch_angle: platformType?.max_pitch_angle,
    service_ceiling: platformType?.service_ceiling,
    radio_range: platformType?.radio_range,
    max_wind_tolerance: platformType?.max_wind_tolerance,
    ingress_protection_solid: platformType?.ingress_protection_solid,
    ingress_protection_liquid: platformType?.ingress_protection_liquid,
    max_operating_temperature: platformType?.max_operating_temperature,
    min_operating_temperature: platformType?.min_operating_temperature,
    tse_mean: platformType?.tse_mean,
    tse_variance: platformType?.tse_variance,
    hover_accuracy_v_visual: platformType?.hover_accuracy_v_visual,
    hover_accuracy_v_gps: platformType?.hover_accuracy_v_gps,
    hover_accuracy_v_rtk: platformType?.hover_accuracy_v_rtk,
    hover_accuracy_h_visual: platformType?.hover_accuracy_h_visual,
    hover_accuracy_h_gps: platformType?.hover_accuracy_h_gps,
    hover_accuracy_h_rtk: platformType?.hover_accuracy_h_rtk,
    positioning_accuracy_v_rtk: platformType?.positioning_accuracy_v_rtk,
    positioning_accuracy_h_rtk: platformType?.positioning_accuracy_h_rtk,
  });

  const [formError, setFormError] = useState({
    model: "",
    airframe: "",
    weight: "",
    max_speed_cruise: "",
    endurance: "",
    radio_range: "",
    ingress_protection_solid: "",
    ingress_protection_liquid: "",
  });

  const [showAdvancedFeatures, setShowAdvancedFeatures] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      const floatValue = parseFloat(e.target.value);
      if (!isNaN(floatValue)) {
        const updatedForm = { ...form, [e.target.id]: floatValue };
        setForms(updatedForm);
      }
    } else {
      setForms({
        ...form,
        [e.target.id]: e.target.value,
      });
    }
  };

  const onAirframeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForms({
      ...form,
      airframe: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      model: "",
      airframe: "",
      weight: "",
      max_speed_cruise: "",
      endurance: "",
      radio_range: "",
      ingress_protection_solid: "",
      ingress_protection_liquid: "",
    };

    if (!form.model) {
      error.model = "Model is required";
      isValid = false;
    }

    if (!form.airframe) {
      error.airframe = "Airframe is required";
      isValid = false;
    }

    if (!form.endurance) {
      error.endurance = "Endurance is required";
      isValid = false;
    }

    if (!form.radio_range) {
      error.radio_range = "Radio range is required";
      isValid = false;
    }

    if (!form.max_speed_cruise) {
      error.max_speed_cruise = "Max Cruise Speed is required";
      isValid = false;
    }

    if (!form.weight) {
      error.weight = "Weight is required";
      isValid = false;
    }

    if (form.ingress_protection_solid && form.ingress_protection_solid > 6) {
      error.ingress_protection_solid =
        "Ingress protection solid should not exceed 6";
      isValid = false;
    }

    if (form.ingress_protection_liquid && form.ingress_protection_liquid > 9) {
      error.ingress_protection_liquid =
        "Ingress protection liquid should not exceed 9";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async () => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Saving platform details", false);
    setIsLoading(true);

    try {
      let response: any;
      if (platformType) {
        response = await api.updatePlatformTypeInfo(
          platformType.platform_type_uuid,
          { ...form }
        );
      } else {
        response = await api.submitNewPlatformType(form);
      }

      if (response.data) {
        setIsLoading(false);
        onSaveSucess();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (showMessage) {
        showMessage((err as any).message, true);
      }
    }
  };

  const airframeType = [
    "Fixed Wing",
    "Multi Rotor",
    "Gyroplane",
    "Hybrid Lift",
    "Ornithopter",
    "Glider",
    "Kite",
    "Free Balloon",
    "Captive Balloon",
    "Airship",
    "Parachute",
    "Rocket",
    "Tethered Powered Aircraft",
    "Ground Obstacle",
    "Other",
  ];

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="name" className="label">
          UAV Model*
        </label>
        <input
          type="text"
          id="model"
          className="input-text"
          defaultValue={form.model}
          onChange={onChange}
        />
        {formError.model && (
          <p className="text-red-500 text-sm mt-1">{formError.model}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          UAV Airframe Type*
        </label>
        <select
          id="airframe"
          className="input-select"
          defaultValue={form.airframe}
          onChange={onAirframeTypeChange}
        >
          <option value="">Select Airframe Type</option>
          {airframeType?.map((airframe, index) => (
            <option key={airframe.replace(" ", "_")} value={airframe}>
              {airframe}
            </option>
          ))}
        </select>
        {formError.airframe && (
          <p className="text-red-500 text-sm mt-1">{formError.airframe}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            RF Range (m)*
          </label>
          <input
            type="number"
            id="radio_range"
            className="input-text"
            defaultValue={form.radio_range}
            onChange={onChange}
          />
          {formError.radio_range && (
            <p className="text-red-500 text-sm mt-1">{formError.radio_range}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            Weight (kg)*
          </label>
          <input
            type="number"
            id="weight"
            className="input-text"
            defaultValue={form.weight}
            onChange={onChange}
          />
          {formError.weight && (
            <p className="text-red-500 text-sm mt-1">{formError.weight}</p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Cruising Speed (m/s)*
          </label>
          <input
            type="number"
            id="max_speed_cruise"
            className="input-text"
            defaultValue={form.max_speed_cruise}
            onChange={onChange}
          />
          {formError.max_speed_cruise && (
            <p className="text-red-500 text-sm mt-1">
              {formError.max_speed_cruise}
            </p>
          )}
        </div>
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Ascend Speed (m/s)
          </label>
          <input
            type="number"
            id="max_speed_asc"
            className="input-text"
            defaultValue={form.max_speed_asc}
            onChange={onChange}
          />
        </div>
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Descend Speed (m/s)
          </label>
          <input
            type="number"
            id="max_speed_desc"
            className="input-text"
            defaultValue={form.max_speed_desc}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Endurance (s)*
          </label>
          <input
            type="number"
            id="endurance"
            className="input-text"
            defaultValue={form.endurance}
            onChange={onChange}
          />
          {formError.endurance && (
            <p className="text-red-500 text-sm mt-1">{formError.endurance}</p>
          )}
        </div>
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Wind Tolerance (m/s)
          </label>
          <input
            type="number"
            id="max_wind_tolerance"
            className="input-text"
            defaultValue={form.max_wind_tolerance}
            onChange={onChange}
          />
        </div>
      </div>
      {showAdvancedFeatures && (
        <div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Max Pitch Rate (degrees/s)
              </label>
              <input
                type="number"
                id="max_pitch_rate"
                className="input-text"
                defaultValue={form.max_pitch_rate}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Max Roll Rate (degrees/s)
              </label>
              <input
                type="number"
                id="max_roll_rate"
                className="input-text"
                defaultValue={form.max_roll_rate}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Max Yaw Rate (degrees/s)
              </label>
              <input
                type="number"
                id="max_yaw_rate"
                className="input-text"
                defaultValue={form.max_yaw_rate}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Max Pitch Angle <br />
                (degrees)
              </label>
              <input
                type="number"
                id="max_pitch_angle"
                className="input-text"
                defaultValue={form.max_pitch_angle}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Service Ceiling (meters) (AMSL)
              </label>
              <input
                type="number"
                id="service_ceiling"
                className="input-text"
                defaultValue={form.service_ceiling}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Unfolded Length (mm)
              </label>
              <input
                type="number"
                id="unfolded_length"
                className="input-text"
                defaultValue={form.unfolded_length}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Unfolded Width (mm)
              </label>
              <input
                type="number"
                id="unfolded_width"
                className="input-text"
                defaultValue={form.unfolded_width}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Unfolded Height (mm)
              </label>
              <input
                type="number"
                id="unfolded_height"
                className="input-text"
                defaultValue={form.unfolded_height}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Ingress Protection Solid
              </label>
              <input
                type="number"
                id="ingress_protection_solid"
                className="input-text"
                defaultValue={form.ingress_protection_solid}
                onChange={onChange}
              />
              {formError.ingress_protection_solid && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.ingress_protection_solid}
                </p>
              )}
            </div>
            <div className="w-full">
              <label htmlFor="username" className="label">
                Ingress Protection Liquid
              </label>
              <input
                type="number"
                id="ingress_protection_liquid"
                className="input-text"
                defaultValue={form.ingress_protection_liquid}
                onChange={onChange}
              />
              {formError.ingress_protection_liquid && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.ingress_protection_liquid}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Max Operating Temp (°C)
              </label>
              <input
                type="number"
                id="max_operating_temperature"
                className="input-text"
                defaultValue={form.max_operating_temperature}
                onChange={onChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="username" className="label">
                Min Operating Temp (°C)
              </label>
              <input
                type="number"
                id="min_operating_temperature"
                className="input-text"
                defaultValue={form.min_operating_temperature}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                TSE (Variance)
              </label>
              <input
                type="number"
                id="tse_variance"
                className="input-text"
                defaultValue={form.tse_variance}
                onChange={onChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="username" className="label">
                TSE (Mean)
              </label>
              <input
                type="number"
                id="tse_mean"
                className="input-text"
                defaultValue={form.tse_mean}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-6">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc Visual Vertical (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_v_visual"
                className="input-text"
                defaultValue={form.hover_accuracy_v_visual}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc GPS Vertical (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_v_gps"
                className="input-text"
                defaultValue={form.hover_accuracy_v_gps}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc RTK Vertical (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_v_rtk"
                className="input-text"
                defaultValue={form.hover_accuracy_v_rtk}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-6">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc Visual H (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_h_visual"
                className="input-text"
                defaultValue={form.hover_accuracy_h_visual}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc GPS Horizontal (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_h_gps"
                className="input-text"
                defaultValue={form.hover_accuracy_h_gps}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Hover Acc RTK Horizontal (meters)
              </label>
              <input
                type="number"
                id="hover_accuracy_h_rtk"
                className="input-text"
                defaultValue={form.hover_accuracy_h_rtk}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex mb-6">
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Positioning Acc Vertical (meters)
              </label>
              <input
                type="number"
                id="positioning_accuracy_v_rtk"
                className="input-text"
                defaultValue={form.positioning_accuracy_v_rtk}
                onChange={onChange}
              />
            </div>
            <div className="w-full mr-2">
              <label htmlFor="username" className="label">
                Positioning Acc Horizontal (meters)
              </label>
              <input
                type="number"
                id="positioning_accuracy_h_rtk"
                className="input-text"
                defaultValue={form.positioning_accuracy_h_rtk}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="flex items-center px-2 cursor-pointer"
        onClick={() => setShowAdvancedFeatures((s) => !s)}
      >
        <p className="mb-5 text-blue-500 text-sm font-medium">
          {showAdvancedFeatures
            ? "Hide additional details"
            : "Show additional details"}
        </p>
      </div>
      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}

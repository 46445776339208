import JSZip from "jszip";
import FileSaver from "file-saver";
import axios from "axios";
import { setOperationFormRequest } from "../store/actions/index";

/**
 * Converts a JSON object into a string and writes it to a single JSON file, then downloads it
 * @param {Object} jsonObject JSON object to be downloaded
 * @param {String} fileName Name of JSON file
 */
export function downloadSingleJSON(jsonObject, fileName) {
  const blob = new Blob([JSON.stringify(jsonObject)], {
    type: "application/json",
  });
  FileSaver.saveAs(blob, `${fileName}.json`);
}
/**
 * Converts multiple JSON objects into a string and writes them to a multiple JSON files, then downloads
 * them in a single zip file.
 * @param {Array} arr Array of JSON objects to be downloaded
 * @param {String} fileName Name of JSON file
 */
export async function downloadMultipleJSON(arr, fileName) {
  const zip = new JSZip();
  arr.forEach((o) => {
    zip.file(`${o.name}.json`, JSON.stringify(o.data));
  });
  const blob = await zip.generateAsync({ type: "blob" });
  FileSaver.saveAs(blob, `${fileName}.zip`);
}

/**
 * Converts a JSON object into a string and writes it to a single JSON file, then downloads it
 * @param {Object} jsonObject JSON object to be downloaded
 * @param {String} fileName Name of JSON file
 */
export async function uploadJSONtoCloud(
  jsonObject,
  fileName,
  username,
  api,
  callback
) {
  try {
    const blob = new Blob([JSON.stringify(jsonObject)], {
      type: "application/json",
    });

    if (typeof callback === "function") {
      callback(blob);
    }

    const formData = new FormData();
    formData.append("file", blob, `${fileName}.json`);
    const getURL = async () => {
      const response = await api.getWaylineUploadURL({
        loginUser: `${username}/Wayline`, // Add pilot
        fileName: `${fileName}.JSON`,
      });
      const URLResponse = response.data;

      const putResponse = await axios.put(URLResponse.uploadURL, blob, {
        headers: { "Content-Type": "application/json" }, // Adjust the content type as needed
      });

      if (putResponse.statusText === "OK") {
        window.alert("File Uploaded");
      }
    };

    getURL();
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

export function downloadCSV(operationsObj, fileName) {
  function getCSV(operations) {
    const rows = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        rows.push(getCSV(operations[singleOperation]));
      } else {
        rows.push(operations[singleOperation]);
      }
    }
    return rows;
  }
  function getCSVHeader(operations, prefix = "") {
    const headers = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        headers.push(
          getCSVHeader(
            operations[singleOperation],
            `${prefix}${singleOperation}.`
          )
        );
      } else {
        headers.push(prefix + singleOperation);
      }
    }
    return headers;
  }
  const headers = getCSVHeader(operationsObj[0]).join(",");
  let formatCSV = [];
  operationsObj.forEach((operation) => {
    const CSV = [];
    CSV.push(`${getCSV(operation)}\n`);
    formatCSV = `${formatCSV}${CSV}`;
  });
  const blob = new Blob([`${headers}\n${formatCSV}`], {
    type: "text/csv",
  });
  FileSaver.saveAs(blob, `${fileName}.csv`);
}

export function downloadTelemetryCSV(operationsObj, fileName) {
  function getCSV(operations) {
    const rows = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        rows.push(getCSV(operations[singleOperation]));
      } else {
        rows.push(operations[singleOperation]);
      }
    }
    return rows;
  }
  function getCSVHeader(operations, prefix = "") {
    const headers = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        headers.push(
          getCSVHeader(
            operations[singleOperation],
            `${prefix}${singleOperation}.`
          )
        );
      } else {
        headers.push(prefix + singleOperation);
      }
    }
    return headers;
  }
  const headers = getCSVHeader(operationsObj[0].puckResponse[0]).join(",");
  let formatCSV = [];
  operationsObj[0].puckResponse.forEach((operation) => {
    const CSV = [];
    CSV.push(`${getCSV(operation)}\n`);
    formatCSV = `${formatCSV}${CSV}`;
  });
  const blob = new Blob([`${headers}\n${formatCSV}`], {
    type: "text/csv",
  });
  FileSaver.saveAs(blob, `${fileName}.csv`);
}

export async function downloadMultipleIncidentReport(operationsObj) {
  const zip = new JSZip();
  const fileName = "";
  function getCSV(operations) {
    const rows = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        rows.push(getCSV(operations[singleOperation]));
      } else {
        rows.push(operations[singleOperation]);
      }
    }
    return rows;
  }
  function getCSVHeader(operations, prefix = "") {
    const headers = [];
    for (const singleOperation in operations) {
      if (typeof operations[singleOperation] === "object") {
        headers.push(
          getCSVHeader(
            operations[singleOperation],
            `${prefix}${singleOperation}.`
          )
        );
      } else {
        headers.push(prefix + singleOperation);
      }
    }
    return headers;
  }
  const operationsObjTelemetry = operationsObj.map((operation) => {
    return operation.puckTelemetryData;
  });
  operationsObj.forEach((operation) => {
    const headers = [];
    let formatCSV = [];
    const operationEdited = (({
      incidentReport,
      details,
      interuss,
      reference,
      request,
    }) => ({ incidentReport, details, interuss, reference, request }))(
      operation
    );
    headers.push(getCSVHeader(operationEdited).join(","));
    const CSV = [];
    CSV.push(`${getCSV(operationEdited)}\n`);
    formatCSV = `${formatCSV}${CSV}`;
    zip.file(
      `${operationEdited.incidentReport.reportId}.csv`,
      `${headers}\n${formatCSV}`
    );

    const headersTelemetry = getCSVHeader(
      operationsObjTelemetry[0].puckResponse[0]
    ).join(",");
    let formatCSVTelemetry = [];
    operationsObjTelemetry[0].puckResponse.forEach((operationTelemetry) => {
      const CSVTelemetry = [];
      CSVTelemetry.push(`${getCSV(operationTelemetry)}\n`);
      formatCSVTelemetry = `${formatCSVTelemetry}${CSVTelemetry}`;
    });
    zip.file(
      `${operationEdited.incidentReport.reportId}-telemetry.csv`,
      `${headersTelemetry}\n${formatCSVTelemetry}`
    );
  });

  const blobFinal = await zip.generateAsync({ type: "blob" });
  FileSaver.saveAs(blobFinal, "incidentReports.zip");
}

export function downloadKML(
  operationsObj,
  fileName,
  operationName = "Waypoint"
) {
  const coordinates = [];
  operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
    coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
  });
  const coordinatesAdjusted = coordinates.join(" ");
  const text = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    <name>${operationName}</name>
    <gx:CascadingStyle kml:id="__managed_style_2CEBD1387C24E127829D">
      <Style>
        <IconStyle>
          <scale>1.2</scale>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>4</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <gx:CascadingStyle kml:id="__managed_style_1DA77AAB0424E127829D">
      <Style>
        <IconStyle>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>2.66667</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <StyleMap id="__managed_style_05373A232324E127829D">
      <Pair>
        <key>normal</key>
        <styleUrl>#__managed_style_1DA77AAB0424E127829D</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#__managed_style_2CEBD1387C24E127829D</styleUrl>
      </Pair>
    </StyleMap>
    <Placemark id="0428F6752324E127829B">
      <name>${operationName}</name>
      <LookAt>
        <longitude>103.858506</longitude>
        <latitude>1.2643484</latitude>
        <altitude>60</altitude>
        <heading>0</heading>
        <tilt>0</tilt>
        <gx:fovy>35</gx:fovy>
        <range>3032.032173961925</range>
        <altitudeMode>absolute</altitudeMode>
      </LookAt>
      <styleUrl>#__managed_style_05373A232324E127829D</styleUrl>
      <LineString>
        <coordinates>
          ${coordinatesAdjusted}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
  </kml>`;

  const blob = new Blob([text], {
    type: "text/kml",
  });
  FileSaver.saveAs(blob, `${fileName}.kml`);
}

// export function downloadKMLDJI(operationsObj, fileName) {
//   const coordinates = [];
//   operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
//     coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
//   });
//   const coordinatesAdjusted = coordinates.join(" ");
//   const pilotUuid = operationsObj.operation_json.request.pilot_uuid;
//   const trackerUuid = operationsObj.operation_json.request.tracker_uuid;
//   const platformUuid = operationsObj.operation_json.request.platform_uuid;
//   const created_time =
//     operationsObj.operation_json.reference.time_created.value;
//   const formattedCreatedTime = new Date(created_time).getTime();

//   const text = `<?xml version="1.0" encoding="UTF-8"?>
//   <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.2">
//   <Document>

//     <!-- Step 1: Implement File Creation Information -->
//     <wpml:author>${pilotUuid}</wpml:author>
//     <wpml:createTime>${formattedCreatedTime}</wpml:createTime>
//     <wpml:updateTime>${formattedCreatedTime}</wpml:updateTime>
//     <wpml:tracker>${trackerUuid}</wpml:tracker>
//     <wpml:platform>${platformUuid}</wpml:platform>

//     <!-- Step 2: Setup Mission Configuration -->
//     <wpml:missionConfig>
//       <wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>
//       <wpml:finishAction>goHome</wpml:finishAction>
//       <wpml:exitOnRCLost>goContinue</wpml:exitOnRCLost>
//       <wpml:executeRCLostAction>hover</wpml:executeRCLostAction>
//       <wpml:takeOffSecurityHeight>20</wpml:takeOffSecurityHeight>
//       <wpml:takeOffRefPoint></wpml:takeOffRefPoint>
//       <wpml:takeOffRefPointAGLHeight></wpml:takeOffRefPointAGLHeight>
//       <wpml:globalTransitionalSpeed>5</wpml:globalTransitionalSpeed>
//       <wpml:droneInfo>
//         <!-- Declare drone model with M30 -->
//         <wpml:droneEnumValue>60</wpml:droneEnumValue>
//         <wpml:droneSubEnumValue>0</wpml:droneSubEnumValue>
//       </wpml:droneInfo>
//       <wpml:payloadInfo>
//         <!-- Declare payload model with M30 -->
//         <wpml:payloadEnumValue>66</wpml:payloadEnumValue>
//         <wpml:payloadSubEnumValue>0</wpml:payloadSubEnumValue>
//         <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
//       </wpml:payloadInfo>
//     </wpml:missionConfig>

//     <!-- Step 3: Setup A Folder for Waypoint Template -->
//     <Folder>
//       <wpml:templateType>waypoint</wpml:templateType>
//       <wpml:useGlobalTransitionalSpeed>0</wpml:useGlobalTransitionalSpeed>
//       <wpml:templateId>0</wpml:templateId>
//       <wpml:waylineCoordinateSysParam>
//         <wpml:coordinateMode>WGS84</wpml:coordinateMode>
//         <wpml:heightMode>EGM96</wpml:heightMode>
//         <wpml:globalShootHeight>50</wpml:globalShootHeight>
//         <wpml:positioningType>GPS</wpml:positioningType>
//         <wpml:surfaceFollowModeEnable>1</wpml:surfaceFollowModeEnable>
//         <wpml:surfaceRelativeHeight>100</wpml:surfaceRelativeHeight>
//       </wpml:waylineCoordinateSysParam>
//       <wpml:autoFlightSpeed>5</wpml:autoFlightSpeed>
//       <wpml:transitionalSpeed>5</wpml:transitionalSpeed>
//       <wpml:gimbalPitchMode>usePointSetting</wpml:gimbalPitchMode>
//       <wpml:globalWaypointHeadingParam>
//         <wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>
//         <wpml:waypointHeadingAngle>45</wpml:waypointHeadingAngle>
//         <wpml:waypointPoiPoint>24.323345,116.324532,31.000000</wpml:waypointPoiPoint>
//         <wpml:waypointHeadingPathMode>clockwise</wpml:waypointHeadingPathMode>
//       </wpml:globalWaypointHeadingParam>
//       <wpml:globalWaypointTurnMode>coordinateTurn</wpml:globalWaypointTurnMode>
//       <wpml:globalUseStraightLine>0</wpml:globalUseStraightLine>
//       <Placemark>
//         <Point>
//           <coordinates>
//           ${coordinatesAdjusted}
//           </coordinates>
//         </Point>
//         <wpml:index>0</wpml:index>
//         <wpml:ellipsoidHeight>90.2</wpml:ellipsoidHeight>
//         <wpml:height>100</wpml:height>
//         <wpml:useGlobalHeight>1</wpml:useGlobalHeight>
//         <wpml:useGlobalSpeed>1</wpml:useGlobalSpeed>
//         <wpml:useGlobalHeadingParam>1</wpml:useGlobalHeadingParam>
//         <wpml:useGlobalTurnParam>1</wpml:useGlobalTurnParam>
//         <wpml:gimbalPitchAngle>0</wpml:gimbalPitchAngle>
//       </Placemark>
//       <Placemark>
//         <Point>
//           <coordinates>
//           ${coordinatesAdjusted}
//           </coordinates>
//         </Point>
//         <wpml:index>1</wpml:index>
//         <wpml:ellipsoidHeight>90.2</wpml:ellipsoidHeight>
//         <wpml:height>100</wpml:height>
//         <wpml:useGlobalHeight>1</wpml:useGlobalHeight>
//         <wpml:useGlobalSpeed>1</wpml:useGlobalSpeed>
//         <wpml:useGlobalHeadingParam>1</wpml:useGlobalHeadingParam>
//         <wpml:useGlobalTurnParam>1</wpml:useGlobalTurnParam>
//         <wpml:gimbalPitchAngle>0</wpml:gimbalPitchAngle>
//         <!-- Declare action group for waypoint 1# -->
//         <wpml:actionGroup>
//           <wpml:actionGroupId>0</wpml:actionGroupId>
//           <wpml:actionGroupStartIndex>1</wpml:actionGroupStartIndex>
//           <wpml:actionGroupEndIndex>1</wpml:actionGroupEndIndex>
//           <wpml:actionGroupMode>sequence</wpml:actionGroupMode>
//           <wpml:actionTrigger>
//             <wpml:actionTriggerType>reachPoint</wpml:actionTriggerType>
//           </wpml:actionTrigger>
//           <!-- Declare the 1st action: rotate gimbal -->
//           <wpml:action>
//             <wpml:actionId>0</wpml:actionId>
//             <wpml:actionActuatorFunc>gimbalRotate</wpml:actionActuatorFunc>
//             <wpml:actionActuatorFuncParam>
//               <wpml:gimbalRotateMode>absoluteAngle</wpml:gimbalRotateMode>
//               <wpml:gimbalPitchRotateEnable>0</wpml:gimbalPitchRotateEnable>
//               <wpml:gimbalPitchRotateAngle>0</wpml:gimbalPitchRotateAngle>
//               <wpml:gimbalRollRotateEnable>0</wpml:gimbalRollRotateEnable>
//               <wpml:gimbalRollRotateAngle>0</wpml:gimbalRollRotateAngle>
//               <wpml:gimbalYawRotateEnable>1</wpml:gimbalYawRotateEnable>
//               <wpml:gimbalYawRotateAngle>30</wpml:gimbalYawRotateAngle>
//               <wpml:gimbalRotateTimeEnable>0</wpml:gimbalRotateTimeEnable>
//               <wpml:gimbalRotateTime>0</wpml:gimbalRotateTime>
//               <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
//             </wpml:actionActuatorFuncParam>
//           </wpml:action>
//           <!-- Declare the 2nd action: take photo -->
//           <wpml:action>
//             <wpml:actionId>1</wpml:actionId>
//             <wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>
//             <wpml:actionActuatorFuncParam>
//               <wpml:fileSuffix>point1</wpml:fileSuffix>
//               <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
//             </wpml:actionActuatorFuncParam>
//           </wpml:action>
//         </wpml:actionGroup>
//       </Placemark>
//     </Folder>
//   </Document>
//   </kml>`;

//   const blob = new Blob([text], {
//     type: "text/csv",
//   });
//   FileSaver.saveAs(blob, `${fileName}.kml`);
// }

export function uploadKMLtoCloud(operationsObj, fileName, username, api) {
  const coordinates = [];
  operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
    coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
  });
  const coordinatesAdjusted = coordinates.join(" ");
  const kmlText = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    <name>Test</name>
    <gx:CascadingStyle kml:id="__managed_style_2CEBD1387C24E127829D">
      <Style>
        <IconStyle>
          <scale>1.2</scale>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>4</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <gx:CascadingStyle kml:id="__managed_style_1DA77AAB0424E127829D">
      <Style>
        <IconStyle>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>2.66667</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <StyleMap id="__managed_style_05373A232324E127829D">
      <Pair>
        <key>normal</key>
        <styleUrl>#__managed_style_1DA77AAB0424E127829D</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#__managed_style_2CEBD1387C24E127829D</styleUrl>
      </Pair>
    </StyleMap>
    <Placemark id="0428F6752324E127829B">
      <name>Test</name>
      <LookAt>
        <longitude>103.858506</longitude>
        <latitude>1.2643484</latitude>
        <altitude>60</altitude>
        <heading>0</heading>
        <tilt>0</tilt>
        <gx:fovy>35</gx:fovy>
        <range>3032.032173961925</range>
        <altitudeMode>absolute</altitudeMode>
      </LookAt>
      <styleUrl>#__managed_style_05373A232324E127829D</styleUrl>
      <LineString>
        <coordinates>
          ${coordinatesAdjusted}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
  </kml>`;

  const blob = new Blob([kmlText], {
    type: "text/kml",
  });

  const formData = new FormData();
  formData.append(
    "file",
    new Blob([kmlText], { type: "text/xml" }),
    `${fileName}.kml`
  );

  const getURL = async () => {
    const response = await api.getWaylineUploadURL({
      loginUser: `${username}/Wayline`, // Add pilot
      fileName: `${fileName}.kml`,
    });
    const URLResponse = response.data;

    const putResponse = await axios.put(URLResponse.uploadURL, blob, {
      headers: { "Content-Type": "application/kml" }, // Adjust the content type as needed
    });

    if (putResponse.statusText === "OK") {
      window.alert("File Uploaded");
    }
  };

  getURL();
}

// For DJI to download operation straight form FOH using Controller
export function downloadKMLURL(operationsObj, fileName) {
  const coordinates = [];
  operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
    coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
  });
  const coordinatesAdjusted = coordinates.join(" ");
  const text = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    <name>Test</name>
    <gx:CascadingStyle kml:id="__managed_style_2CEBD1387C24E127829D">
      <Style>
        <IconStyle>
          <scale>1.2</scale>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>4</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <gx:CascadingStyle kml:id="__managed_style_1DA77AAB0424E127829D">
      <Style>
        <IconStyle>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>2.66667</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <StyleMap id="__managed_style_05373A232324E127829D">
      <Pair>
        <key>normal</key>
        <styleUrl>#__managed_style_1DA77AAB0424E127829D</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#__managed_style_2CEBD1387C24E127829D</styleUrl>
      </Pair>
    </StyleMap>
    <Placemark id="0428F6752324E127829B">
      <name>Test</name>
      <LookAt>
        <longitude>103.858506</longitude>
        <latitude>1.2643484</latitude>
        <altitude>60</altitude>
        <heading>0</heading>
        <tilt>0</tilt>
        <gx:fovy>35</gx:fovy>
        <range>3032.032173961925</range>
        <altitudeMode>absolute</altitudeMode>
      </LookAt>
      <styleUrl>#__managed_style_05373A232324E127829D</styleUrl>
      <LineString>
        <coordinates>
          ${coordinatesAdjusted}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
  </kml>`;

  const blob = new Blob([text], {
    type: "text/kml",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.kml`;
  link.click();
  return url;
}

export function downloadKMLURLfromCloud(operationsObj, fileName) {
  const blob = new Blob([operationsObj], {
    type: "text/kml",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.kml`;
  link.click();
  return url;
}

/**
 * Converts a JSON object into a string and writes it to a single JSON file, then downloads it
 * @param {Object} jsonObject JSON object to be downloaded
 * @param {String} fileName Name of JSON file
 */
export function downloadJSONURL(jsonObject, fileName) {
  const blob = new Blob([JSON.stringify(jsonObject)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.JSON`;
  link.click();
  return url;
}

export function downloadJSONURLfromCloud(jsonObject, fileName) {
  const blob = new Blob([jsonObject], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  return url;
}

function convertKMLtoJSON(kmlData) {
  const parser = new DOMParser();
  const kmlDocument = parser.parseFromString(kmlData, "application/xml");

  const features = [];
  const placemarks = kmlDocument.querySelectorAll("Placemark");
  const allPointCoordinates = [];
  let alt = 0;

  if (placemarks.length === 1) {
    const lineCoordinates = Array.from(
      placemarks[0].querySelectorAll("LineString coordinates")
    );
    lineCoordinates.forEach((coordinatesElement) => {
      const coordinatesText = coordinatesElement.textContent.trim();
      const coordinatesArray = coordinatesText
        .split(/\s+/)
        .map((coordString) => {
          const [longitude, latitude, altitude] = coordString
            .split(",")
            .map((coord) => parseFloat(coord.trim()));
          alt = altitude;
          return [longitude, latitude];
        });

      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinatesArray,
          alt,
        },
      });
    });
  } else {
    let lineCoordinates;
    for (const placemark of placemarks) {
      const getCoordinatesFromPlacemark = placemark.querySelectorAll(
        "LineString coordinates"
      );
      if (getCoordinatesFromPlacemark.length) {
        lineCoordinates = Array.from(getCoordinatesFromPlacemark);
      }
      if (lineCoordinates) {
        break; // Exit the loop after the first valid lineCoordinates
      }
    }

    lineCoordinates.forEach((coordinatesElement) => {
      const coordinatesText = coordinatesElement.textContent.trim();
      const coordinatesArray = coordinatesText
        .split(/\s+/)
        .map((coordString) => {
          const [longitude, latitude, altitude] = coordString
            .split(",")
            .map((coord) => parseFloat(coord.trim()));
          alt = altitude;
          return [longitude, latitude];
        });

      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinatesArray,
        },
      });
    });
  }
  return { features };
}

export function handleKmlImport(
  kmlData,
  dispatch,
  isShipToShoreOperation = false
) {
  const jsonData = convertKMLtoJSON(kmlData);
  dispatch(
    setOperationFormRequest({
      id: "",
      type: "duplicate",
      isShipToShoreOperation,
      request: {
        pilot_uuid: [],
        platform_uuid: [],
        tracker_uuid: [],
        waypoints: jsonData.features[0].geometry.coordinates,
        time_buffer: 30,
        elevation: 0,
        max_segment_length: 500,
        airspace_optimised: false,
        two_way: false,
        altitude: jsonData.features[0].geometry.alt,
        altitude_reference: "W84",
        time_start: "",
        time_end: "",
        ground_speed: 5,
        operation_type: "bvlos",
        description: "",
        contingency_plans: {
          landing_point: [jsonData.features[0].geometry.coordinates[0]],
          safe_altitude: 25,
        },
      },
    })
  );
}

export function handleJsonImport(data, dispatch) {
  dispatch(
    setOperationFormRequest({
      id: data.operation_json.reference.id,
      type: "duplicate",
      request: data.request_json,
    })
  );
}

export function uploadKMLtoCloudDeconflict(
  operationsObj,
  fileName,
  username,
  api
) {
  const coordinates = [];
  operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
    coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
  });
  const coordinatesAdjusted = coordinates.join(" ");
  const kmlText = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    <name>Test</name>
    <gx:CascadingStyle kml:id="__managed_style_2CEBD1387C24E127829D">
      <Style>
        <IconStyle>
          <scale>1.2</scale>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>4</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <gx:CascadingStyle kml:id="__managed_style_1DA77AAB0424E127829D">
      <Style>
        <IconStyle>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>2.66667</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <StyleMap id="__managed_style_05373A232324E127829D">
      <Pair>
        <key>normal</key>
        <styleUrl>#__managed_style_1DA77AAB0424E127829D</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#__managed_style_2CEBD1387C24E127829D</styleUrl>
      </Pair>
    </StyleMap>
    <Placemark id="0428F6752324E127829B">
      <name>Test</name>
      <LookAt>
        <longitude>103.858506</longitude>
        <latitude>1.2643484</latitude>
        <altitude>60</altitude>
        <heading>0</heading>
        <tilt>0</tilt>
        <gx:fovy>35</gx:fovy>
        <range>3032.032173961925</range>
        <altitudeMode>absolute</altitudeMode>
      </LookAt>
      <styleUrl>#__managed_style_05373A232324E127829D</styleUrl>
      <LineString>
        <coordinates>
          ${coordinatesAdjusted}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
  </kml>`;

  const blob = new Blob([kmlText], {
    type: "text/csv",
  });

  const formData = new FormData();
  formData.append(
    "file",
    new Blob([kmlText], { type: "text/xml" }),
    `${fileName}(Deconflict).kml`
  );

  const getURL = async () => {
    const response = await api.getWaylineUploadURLDeconflict({
      loginUser: `${username}/Deconflict`, // Add pilot
      fileName: `${fileName}.kml`,
    });
    const URLResponse = response.data;

    const putResponse = await axios.put(URLResponse.uploadURL, blob, {
      headers: { "Content-Type": "application/kml" }, // Adjust the content type as needed
    });

    if (putResponse.statusText === "OK") {
      window.alert("File Uploaded");
    }
  };

  getURL();
}

export function uploadKMLtoDJICloudDeconflict(
  operationsObj,
  fileName,
  username,
  api
) {
  const coordinates = [];
  operationsObj.operation_json.details.waypoints.forEach((waypoint) => {
    coordinates.push(`${waypoint.lon},${waypoint.lat},${waypoint.alt}`);
  });
  const coordinatesAdjusted = coordinates.join(" ");
  const kmlText = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    <name>Test</name>
    <gx:CascadingStyle kml:id="__managed_style_2CEBD1387C24E127829D">
      <Style>
        <IconStyle>
          <scale>1.2</scale>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>4</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <gx:CascadingStyle kml:id="__managed_style_1DA77AAB0424E127829D">
      <Style>
        <IconStyle>
          <Icon>
            <href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>
          </Icon>
          <hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>
        </IconStyle>
        <LabelStyle>
        </LabelStyle>
        <LineStyle>
          <color>ff2dc0fb</color>
          <width>2.66667</width>
        </LineStyle>
        <PolyStyle>
          <color>40ffffff</color>
        </PolyStyle>
        <BalloonStyle>
          <displayMode>hide</displayMode>
        </BalloonStyle>
      </Style>
    </gx:CascadingStyle>
    <StyleMap id="__managed_style_05373A232324E127829D">
      <Pair>
        <key>normal</key>
        <styleUrl>#__managed_style_1DA77AAB0424E127829D</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#__managed_style_2CEBD1387C24E127829D</styleUrl>
      </Pair>
    </StyleMap>
    <Placemark id="0428F6752324E127829B">
      <name>Test</name>
      <LookAt>
        <longitude>103.858506</longitude>
        <latitude>1.2643484</latitude>
        <altitude>60</altitude>
        <heading>0</heading>
        <tilt>0</tilt>
        <gx:fovy>35</gx:fovy>
        <range>3032.032173961925</range>
        <altitudeMode>absolute</altitudeMode>
      </LookAt>
      <styleUrl>#__managed_style_05373A232324E127829D</styleUrl>
      <LineString>
        <coordinates>
          ${coordinatesAdjusted}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
  </kml>`;

  const blob = new Blob([kmlText], {
    type: "text/csv",
  });

  const kmlFileName = `${fileName}.kml`;
  const folderName = `${fileName}_folder`;

  const zip = new JSZip();
  const folder = zip.folder(folderName); // Create a folder inside the zip

  // Add the KML file to the folder
  folder.file(kmlFileName, kmlText);

  // Generate the zip content
  zip.generateAsync({ type: "blob" }).then((content) => {
    const formData = new FormData();
    formData.append("file", content, `${folderName}(Deconflict).zip`);

    const getURL = async () => {
      const response = await api.getWaylineUploadURLDeconflict({
        loginUser: `${username}/Deconflict`,
        fileName: `${folderName}.zip`,
      });
      const URLResponse = response.data;

      const putResponse = await axios.put(URLResponse.uploadURL, content, {
        headers: { "Content-Type": "application/zip" },
      });

      if (putResponse.statusText === "OK") {
        window.alert("File Uploaded");
      }
    };

    getURL();
  });
}

/**
 * Converts a JSON object into a string and writes it to a single JSON file, then downloads it
 * @param {Object} jsonObject JSON object to be downloaded
 * @param {String} fileName Name of JSON file
 */
export async function uploadJSONtoCloudDeconflict(
  jsonObject,
  fileName,
  username,
  api,
  callback
) {
  try {
    const blob = new Blob([JSON.stringify(jsonObject)], {
      type: "application/json",
    });

    if (typeof callback === "function") {
      callback(blob);
    }

    const formData = new FormData();
    formData.append("file", blob, `${fileName}(Deconflict).json`);
    const getURL = async () => {
      const response = await api.getWaylineUploadURLDeconflict({
        loginUser: `${username}/Deconflict`,
        fileName: `${fileName}.JSON`,
      });
      const URLResponse = response.data;

      const putResponse = await axios.put(URLResponse.uploadURL, blob, {
        headers: { "Content-Type": "application/json" },
      });

      if (putResponse.statusText === "OK") {
        window.alert("File Uploaded");
      }
    };

    getURL();
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

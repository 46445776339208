import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import UserAccess from "@/model/UserAccess";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import SideBarMenu from "../SideBarMenu";
import data from "./data";
import sideBarAccessCheck from "./sideBarAccessCheck";

interface DashboardSideBarProps {
  isSidebarHidden?: boolean;
  sidebarWidth?: string;
  children?: React.ReactNode;
  currentTheme?: string;
  onMenuSelected(menu: string, isOpening: boolean): void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function DashboardSideBar({
  isSidebarHidden,
  sidebarWidth,
  children,
  currentTheme,
  onMenuSelected,
}: DashboardSideBarProps) {
  const childRef = useRef<HTMLDivElement>(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [childWidth, setChildWidth] = useState(0);
  const [marginLeft, setMarginLeft] = useState(80);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  /* Manage Sidebar Menu */
  const [sidebarMenu, setSidebarMenu] = useState(data.sideBarOperator);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const toggleSideBar = () => {
    if (isSidebarExpanded) {
      hideSideBar();
    } else {
      openSideBar();
    }
  };

  const openSideBar = () => {
    setIsSidebarExpanded(true);
    setMarginLeft(80);
  };

  const hideSideBar = () => {
    setIsSidebarExpanded(false);
    setMarginLeft(-childWidth);
  };

  const handleSelectMenu = (index: number) => {
    if (index === selectedMenu && isSidebarExpanded) {
      hideSideBar();
    } else {
      openSideBar();
    }

    setFirstRender(false);
    setSelectedMenu(index);
    onMenuSelected(sidebarMenu[index].title, !isSidebarExpanded);
  };

  // useEffect(() => {
  //   if (childRef.current) {
  //     const { width } = childRef.current.getBoundingClientRect();
  //     const afterWidth = width - 80;
  //     setChildWidth(afterWidth);
  //     setMarginLeft(-afterWidth);
  //   }
  // }, [childRef]);

  useEffect(() => {
    if (sidebarWidth) {
      const strWidth = sidebarWidth
        ?.replace("w-[", "")
        .replace("]", "")
        .replace("px", "");
      const width = parseInt(strWidth, 10);
      const afterWidth = width - 80;
      setChildWidth(afterWidth);
      // Handle scenerio where click create operation causes tab to close
      // if (afterWidth !== 480) {
      // console.log("After Width : ", afterWidth);
      // console.log("selectedMenu : ", selectedMenu);
      // console.log("sidebarMenu : ", sidebarMenu);
      //   setMarginLeft(-afterWidth);
      //   hideSideBar();
      // }
    }
  }, [firstRender, sidebarWidth]);

  useEffect(() => {
    const sideBarMenuAccess = sideBarAccessCheck(userAccess);
    setSidebarMenu(sideBarMenuAccess);

    // Prevent wrong sidebar open when switching role
    hideSideBar();
    onMenuSelected("", false);
  }, [userAccess]);

  useEffect(() => {
    if (isSidebarHidden) {
      hideSideBar();
    } else {
      openSideBar();
    }
  }, [isSidebarHidden]);

  return (
    <div className="relative h-full">
      <div className="w-20 bg-white h-full" />
      <div className="sidebar-container">
        <ul>
          {sidebarMenu.map((item, index) => (
            <SideBarMenu
              key={item.title}
              currentTheme={currentTheme}
              iconDefault={item.iconDefault}
              iconActive={item.iconActive}
              title={item.title}
              isActive={selectedMenu === index}
              onSelected={() => handleSelectMenu(index)}
            />
          ))}
        </ul>
      </div>
      {/* <button className="absolute bottom-26 left-0 z-[52] w-20">
        <LuLogOut className="m-auto" color="white" size={24} />
      </button> */}
      <div
        className="absolute top-0 bottom-0 z-[50] duration-300 ease-in-out"
        style={{ marginLeft }}
      >
        <div className="flex h-full">
          <div ref={childRef}>{children}</div>
          <button
            onClick={toggleSideBar}
            className="sidebar-toggle-button ml-20"
          >
            {isSidebarExpanded ? (
              <MdArrowLeft className="icon-btn" size={24} />
            ) : (
              <MdArrowRight className="icon-btn" size={24} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import EmbedMapB from "@/components/MapB/embedMapB";
import {
  WeatherSimulation,
  Route,
  Segment,
} from "@/model/api/WeatherSimulation";
import moment from "moment";

interface MissionSimulationProps {
  routes?: Route[];
  simulationData: WeatherSimulation;
}

interface FlightSimulationProps {
  segment?: Segment[];
  simulationData: WeatherSimulation;
}

interface SimulationFormProps {
  simulationData: WeatherSimulation;
  handleClose: () => void;
  isFlightSimulation: boolean;
}

function MissionSimulation(props: MissionSimulationProps) {
  const [selectedRoute, setSelectedRoute] = useState<Route>();
  const { routes, simulationData } = props;

  useEffect(() => {
    setSelectedRoute(routes?.[0]);
  }, []);

  const getEvaluationSeverity = (
    evaluation: string,
    isSelected: boolean,
    isFirst: boolean,
    isLast: boolean
  ) => {
    if (evaluation === "nominal") {
      return (
        <div
          className={`${isFirst && "rounded-t mt-1"} ${
            isLast && "rounded-b mb-1"
          } w-14 h-4 flex justify-center items-center cursor-pointer ${
            isSelected ? "border-4 border-white bg-green-300" : "bg-green-500"
          }`}
        />
      );
    }
    if (evaluation === "moderate") {
      return (
        <div
          className={`${isFirst && "rounded-t"} ${
            isLast && "rounded-b"
          } w-14 h-4 flex justify-center items-center cursor-pointer" ${
            isSelected ? "border-4 border-white bg-yellow-300" : "bg-yellow-500"
          }`}
        />
      );
    }
    if (evaluation === "severe") {
      return (
        <div
          className={`w-14 h-4 ${isFirst && "rounded-t"} ${
            isLast && "rounded-b"
          } flex justify-center items-center cursor-pointer" ${
            isSelected ? "border-4 border-white bg-red-300" : "bg-red-500"
          }`}
        />
      );
    }
    return (
      <div
        className={`bg-grey-500 w-16 h-5 ${isFirst && "rounded-t"} ${
          isLast && "rounded-b"
        } flex justify-center items-center cursor-pointer" ${
          isSelected ? "border-4 border-white bg-grey-300" : "bg-grey-500"
        }`}
      />
    );
  };

  return (
    <div className="flex overflow-hidden mt-10">
      <div
        className="flex justify-between w-full overflow-y-auto"
        style={{
          scrollbarWidth: "none", // For Firefox
          msOverflowStyle: "none", // For Internet Explorer and Edge
        }}
      >
        <div className="ml-5 w-[60%] h-[100%]">
          <div className="absolute rounded-container-np w-[55%] h-[93%] overflow-y-auto">
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">Time :</span>
              {selectedRoute?.take_off_time &&
                moment(selectedRoute?.take_off_time).format("hh:mm a")}
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Distance :
              </span>
              {simulationData.mission_simulation_data?.route_distance}
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Flight Status :
              </span>{" "}
              {selectedRoute?.flight_status?.evaluation}
              <div className="w-full px-1">
                {selectedRoute?.flight_status?.reasons_severe &&
                  selectedRoute?.flight_status?.reasons_severe.join(", ")}
              </div>
              <div className="w-full px-1">
                {selectedRoute?.flight_status?.reasons_moderate &&
                  selectedRoute?.flight_status?.reasons_moderate.join(", ")}
              </div>
            </div>

            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Weather Parameters
              </span>
              {selectedRoute?.min_max_weather_parameters.map(
                (singleWeatherParameter) => (
                  <div
                    className="w-full px-1 mb-2"
                    key={singleWeatherParameter.parameter}
                  >
                    {singleWeatherParameter.parameter}
                    <div className="w-full px-1">
                      max : {singleWeatherParameter.max}
                    </div>
                    <div className="w-full px-1">
                      min : {singleWeatherParameter.min}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Flight Time :
              </span>{" "}
              {selectedRoute?.flight_time}
              <div className="w-full px-1">
                {selectedRoute?.flight_status?.reasons_severe &&
                  selectedRoute?.flight_status?.reasons_severe.join(", ")}
              </div>
              <div className="w-full px-1">
                {selectedRoute?.flight_status?.reasons_moderate &&
                  selectedRoute?.flight_status?.reasons_moderate.join(", ")}
              </div>
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Zero Wind Flight Time :
              </span>
              {simulationData.mission_simulation_data?.zero_wind_flight_time}
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Elevation
              </span>
              <div className="w-full px-1">
                At Takeoff :{" "}
                {simulationData.mission_simulation_data?.elevation_at_take_off}
              </div>
              <div className="w-full px-1">
                At Landing :{" "}
                {simulationData.mission_simulation_data?.elevation_at_landing}
              </div>
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">
                Endurance
              </span>

              <div className="w-full px-1">
                At Takeoff : {selectedRoute?.endurance_at_take_off}
              </div>
              <div className="w-full px-1">
                At Landing : {selectedRoute?.endurance_at_landing}
              </div>
            </div>
            <div className="w-full px-1 m-2">
              <span className="text-blue-500 text-sm font-medium">SOC</span>
              <div className="w-full px-1">
                At Takeoff : {selectedRoute?.soc_at_take_off_percent}%
              </div>
              <div className="w-full px-1">
                At Landing : {selectedRoute?.soc_at_landing_percent}%
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%] h-[90%] ">
          {Array.from({ length: 7 }, (_, index) => (
            <div key={index} className="mb-1 flex items-center">
              <div className="px-1">
                {routes &&
                  routes
                    .slice(index * 24, index * 24 + 24)
                    .map((route, index) => {
                      return (
                        <div
                          key={`${route.take_off_time}`}
                          className="w-[100%]"
                          onClick={() => setSelectedRoute(route)}
                        >
                          {getEvaluationSeverity(
                            route.flight_status.evaluation,
                            selectedRoute?.take_off_time ===
                              route.take_off_time,
                            index === 0,
                            index === 23
                          )}
                        </div>
                      );
                    })}
              </div>
              {/* Text to the right of each container */}
              <div className="ml-5 text-center">
                <span className="text-blue-500 text-sm font-medium">
                  {moment(routes?.[index * 24].take_off_time).format("ddd")}
                </span>
                <p>
                  {moment(routes?.[index * 24].take_off_time).format("DD/MMM")}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function FlightSimulation(props: FlightSimulationProps) {
  const { segment, simulationData } = props;
  const { flight_simulation_data } = simulationData;
  const currentData = segment?.map((singleSegment) => [
    singleSegment.longitude,
    singleSegment.latitude,
  ]);
  return (
    <div className="flex overflow-auto">
      <div className="w-[100%] mr-2">
        <div className="rounded-container-np h-[100%] overflow-y-auto">
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Distance :
            </span>
            {flight_simulation_data?.route_distance}
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Flight Status :
            </span>{" "}
            {flight_simulation_data?.flight_status.evaluation}
            <div className="w-full px-1">
              {flight_simulation_data?.flight_status?.reasons_severe &&
                flight_simulation_data?.flight_status?.reasons_severe.join(
                  ", "
                )}
            </div>
            <div className="w-full px-1">
              {flight_simulation_data?.flight_status?.reasons_moderate &&
                flight_simulation_data?.flight_status?.reasons_moderate.join(
                  ", "
                )}
            </div>
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Weather Parameters
            </span>
            {flight_simulation_data?.min_max_weather_parameters.map(
              (singleWeatherParameter) => (
                <div
                  className="w-full px-1 mb-2"
                  key={singleWeatherParameter.parameter}
                >
                  {singleWeatherParameter.parameter}
                  <div className="w-full px-1">
                    max : {singleWeatherParameter.max}
                  </div>
                  <div className="w-full px-1">
                    min : {singleWeatherParameter.min}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Flight Time :
            </span>
            {flight_simulation_data?.flight_time}
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Zero Wind Flight Time :
            </span>
            {flight_simulation_data?.zero_wind_flight_time}
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Landing Time :
            </span>
            {moment(flight_simulation_data?.landing_time).format("hh:mm a")}
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Elevation :
            </span>
            <div className="w-full px-1">
              At Takeoff : {flight_simulation_data?.elevation_at_take_off}
            </div>
            <div className="w-full px-1">
              At Landing : {flight_simulation_data?.elevation_at_landing}
            </div>
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">
              Endurance :
            </span>
            <div className="w-full px-1">
              At Takeoff : {flight_simulation_data?.endurance_at_take_off}
            </div>
            <div className="w-full px-1">
              At Landing : {flight_simulation_data?.endurance_at_landing}
            </div>
          </div>
          <div className="w-full px-1 m-2">
            <span className="text-blue-500 text-sm font-medium">SOC :</span>
            <div className="w-full px-1">
              At Takeoff : {flight_simulation_data?.soc_at_take_off_percent}%
            </div>
            <div className="w-full px-1">
              At Landing : {flight_simulation_data?.soc_at_landing_percent}%
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-[100%]">
        <EmbedMapB compareCurrentData={segment} isWeatherAnalysis={true} />
      </div>
    </div>
  );
}

export default function SimulationForm({
  simulationData,
  handleClose,
  isFlightSimulation,
}: SimulationFormProps) {
  return (
    <div
      className="h-[80vh] w-[60vw] flex flex-col p-4 overflow-auto"
      onClick={(e) => e.stopPropagation()}
    >
      {isFlightSimulation && (
        <div className="absolute h-[70vh] w-[85%] flex flex-col overflow-auto mt-10">
          <FlightSimulation
            segment={simulationData.flight_simulation_data?.segments}
            simulationData={simulationData}
          />
        </div>
      )}
      {!isFlightSimulation && (
        <div className="absolute h-[69vh] w-[85%] flex flex-col overflow-auto">
          <MissionSimulation
            routes={simulationData.mission_simulation_data?.routes}
            simulationData={simulationData}
          />
        </div>
      )}
    </div>
  );
}

import ToolbarDropdownItem from "@/model/ToolbarDropdownItem";

const overlayManagerDropdownItems: ToolbarDropdownItem[] = [
  {
    title: "AIS",
    isToggle: true,
  },
  {
    title: "ADS-B",
    isToggle: true,
  },
  {
    title: "WX-RADAR",
    isToggle: true,
  },
  {
    title: "STATIC CONSTRAINTS",
    isToggle: true,
    isActive: true,
  },
  {
    title: "DYNAMIC CONSTRAINTS",
    isToggle: true,
    isActive: true,
  },
  {
    title: "BUILDING",
    isToggle: true,
  },
  {
    title: "OPS VOLUME",
    isToggle: true,
    isActive: true,
  },
  {
    title: "DRONE ICON",
    isToggle: true,
    isActive: true,
  },
  {
    title: "ANCHORAGE",
    isToggle: true,
    isActive: true,
  },
  {
    title: "DRONE PORT",
    isToggle: true,
  },
  {
    title: "SG BOUNDARY",
    isToggle: true,
  },
  {
    title: "SG POPULATION DENSITY",
    isToggle: true,
  },
  {
    title: "MAP ELEVATION",
    isToggle: true,
  },
  {
    title: "COLLISION",
    isToggle: true,
    isActive: true,
  },
];

const viewDropdownItems: ToolbarDropdownItem[] = [
  {
    title: "Operation Panel",
    isToggle: true,
    isActive: true,
  },
  {
    title: "Weather Panel",
    isToggle: true,
    isActive: true,
  },
  {
    title: "Map Tool",
    isToggle: true,
    isActive: false,
  },
];

export { overlayManagerDropdownItems, viewDropdownItems };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useApi } from "@/api/useApi";
import toast from "react-hot-toast";
import { Auth } from "aws-amplify";
import Button from "@/components/v1/Common/Button";
import { User } from "@/model/api/User";
import { IoMdCopy } from "react-icons/io";
import Loader from "../../Common/Loader";

interface UserFormProps {
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
}

export default function OwnOrganisationManagement({
  showMessage,
}: UserFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    organisation_uuid: "",
    organisation_name: "",
    subscription_plan_name: "",
    subscription_plan_expiry: "",
    date_created: "",
  });

  const [formError, setFormError] = useState({
    organisation_name: "",
  });

  const api = useApi();

  const handleCopytoClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    showMessage && showMessage("Copied to clipboard");
  };
  const getOrganisationDetails = async () => {
    try {
      const response = await api.getOwnOrganisation();
      setForms(response.data);
    } catch {
      showMessage && showMessage("No Organisation Data", false, true);

      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getOrganisationDetails();
  }, []);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      organisation_name: "",
    };

    if (form.organisation_name === "") {
      error.organisation_name = "Organisation name is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async (isNewOrganisation: boolean) => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Updating Organisation details");
    setIsLoading(true);

    // Update Organisation Name
    if (!isNewOrganisation) {
      try {
        await api.updateOwnOrganisation({
          organisation_name: form.organisation_name,
        });
        showMessage && showMessage("Organisation Updated", true);
      } catch (err) {
        showMessage && showMessage((err as any).message, false, true);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="flex-col" onClick={(e) => e.stopPropagation()}>
      {/* No Organisation Available */}
      {!isLoading && form.organisation_uuid === "" && (
        <p className="p-8 text-center">No Organisation Data</p>
      )}

      {!isLoading && form.organisation_uuid !== "" && (
        <div>
          <div className="flex-col rounded-container">
            <div className="mb-2">
              <p className="mb-2 font-bold">Organisation UUID : </p>
              <p className="mb-2 flex">
                {form.organisation_uuid}
                <IoMdCopy
                  onClick={() => handleCopytoClipboard(form.organisation_uuid)}
                  size={14}
                />
              </p>
            </div>
            <div className="mb-2">
              <p className="mb-2 mt-1 font-bold">Subsciption Type : </p>
              <p className="mb-2">{form.subscription_plan_name}</p>
            </div>
            <div className="mb-2">
              <p className="mb-2 mt-1 font-bold">Subsciption Expiry : </p>
              <p className="mb-2">
                {form.subscription_plan_expiry
                  ? form.subscription_plan_expiry
                  : "na"}
              </p>
            </div>
            <div className="mb-2">
              <p className="mb-2 mt-1 font-bold">Date Created : </p>
              <p className="mb-2">
                {form.date_created ? form.date_created.split("T")[0] : "na"}
              </p>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <label htmlFor="organisation_name" className="label">
              Organisation Name*
            </label>
            <input
              type="text"
              id="organisation_name"
              className="input-text"
              defaultValue={form.organisation_name}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
          </div>
          {formError.organisation_name && (
            <p className="text-red-500 text-sm mb-2">
              {formError.organisation_name}
            </p>
          )}

          <Button
            type="primaryDark"
            isLoading={isLoading}
            text="Update Organisation"
            onClick={(e: any) => {
              e.stopPropagation();
              handleSave(false);
            }}
          />
        </div>
      )}

      <Loader isLoading={isLoading} isError={false} />
    </div>
  );
}

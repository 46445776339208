import React, { useEffect, useState, useRef } from "react";
import Button from "@/components/v1/Common/Button";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import InputWaypoint from "@/components/v1/InputWaypoint";
import LatLng from "@/model/LatLng";
import Waypoint from "@/model/Waypoint";

import { FaDrawPolygon, FaLocationArrow, FaMapPin } from "react-icons/fa";
import { MdEmergencyShare, MdTimeline } from "react-icons/md";
import { CiImport } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { setOperationFormRequest } from "@/store/actions";
import moment from "moment";
import { ScheduleForm } from "@/model/api/Schedule";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import { convertZuluToLocalTime } from "@/api/timeConvert";
import axios from "axios";
import { handleKmlImport, handleJsonImport } from "@/services/json";
import MapState from "@/model/MapState";
import { CreateOperationType } from "@/enum/CreateOperationType";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import { Operation, OperationJson } from "@/model/api/Operation";
import { OperationFormRequest } from "@/model/api/OperationFormRequest";
import FlightPlan from "@/model/FlightPlan";
import { getFutureDate } from "@/utils/date_utils";
import { computePolygonCenter } from "@/api/polygonCenter";

import useWebsocket from "@/hooks/useWebsocket";
import { channels } from "@/config/channels";
import { messageTypes } from "@/config/messageTypes";
import BellNotificationType from "@/model/api/BellNotification";

import data from "./data";
import AssetSelection from "../AssetSelection";

interface OperationForm {
  intent?: string;
  pilot_uuid: string[];
  platform_uuid: string[];
  tracker_uuid: string[];
  waypoints?: number[][];
  time_buffer?: number;
  vertical_buffer?: number;
  lateral_buffer?: number;
  rth_altitude?: number;
  elevation: number;
  max_segment_length?: number;
  airspace_optimised?: boolean;
  two_way?: boolean;
  transit_duration?: number;
  altitude: number;
  altitude_reference: string;
  time_start: string;
  time_end: string;
  transit_duration_hour?: number;
  transit_duration_minute?: number;
  ground_speed?: number;
  operation_type: string;
  description: string;
  contingency_plans: {
    landing_point: number[][];
    safe_altitude: number;
  };
  area_coordinates?: number[][];
  properties?: {
    isCircle: boolean;
    radiusInKm?: number;
    center?: number[];
  };
  tag: string;
}

interface AdditionalAssetForm {
  id: number;
  pilot_uuid: string;
  platform_uuid: string;
  tracker_uuid: string;
}

interface CreateFlightPlanFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  selectedWaypoint: number[][];
  selectedAreaWaypoints: number[][];
  selectedCircleWaypoint: number[][];
  selectedCircleProperties: SelectedCircleProperties;
  className?: string;
  pilots: Pilot[];
  platforms: Platform[];
  trackers: Tracker[];
  operationType: CreateOperationType;
  revisionAction: {
    isDiscard: boolean;
    isEdit: boolean;
    isResubmit: boolean;
    isAccepted: boolean;
  };
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  onOperationSubmitted: () => void;
  onOperationRevision: (
    isRerouted: boolean,
    isScheduled: boolean,
    operationData: OperationJson,
    flightPlan: FlightPlan
  ) => void;
  onCloseSidebar: () => void;
  handleSetEmergencyLanding: (waypoints: [number, number]) => void;
  emergencyLanding: number[];
  operationFormRequest: OperationFormRequest;
  handleOperationFormWaypointChange: (
    formData: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => void;
  isEditOperation?: boolean;
  setIsEditOperation: (state: boolean) => void;
  isAuthority?: boolean;
  hasOperationWriteRight: boolean;
  isScheduleManagement?: boolean;
  scheduleForm?: ScheduleForm;
  resetScheduleForm: () => void;
  setScheduleFormError: (error: any) => void;
  isShoreToShipOperation: boolean;
}

export default function CreateFlightPlanForm({
  api,
  selectedWaypoint,
  selectedAreaWaypoints,
  selectedCircleWaypoint,
  selectedCircleProperties,
  className = "",
  pilots,
  platforms,
  trackers,
  operationType = CreateOperationType.ROUTE,
  revisionAction,
  onConfirmation,
  showMessage,
  goToLocation,
  onOperationSubmitted,
  onOperationRevision,
  onCloseSidebar,
  handleSetEmergencyLanding,
  emergencyLanding,
  operationFormRequest,
  handleOperationFormWaypointChange,
  isEditOperation,
  setIsEditOperation,
  isAuthority,
  hasOperationWriteRight,
  isScheduleManagement,
  scheduleForm,
  resetScheduleForm,
  setScheduleFormError,
  isShoreToShipOperation,
}: CreateFlightPlanFormProps) {
  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );
  const { channel } = useWebsocket({
    channel: channels.NOTIFICATION_CHANNEL,
  });
  const dispatch = useDispatch();

  /* Input Waypoint Configuration */
  const [curentOperationType, setCurrentOperationType] =
    useState(operationType);
  const [inputWaypointConfiguration, setInputWaypointConfiguration] = useState({
    keyLabel: "WP",
    drawIcon: <MdTimeline className="fill-blue-500" />,
    drawLabel: "Draw Waypoint",
    showRadius: false,
    allowMultiple: true,
    useTakeOffLandingPoint: true,
    minWaypointCount: 1,
    latLabel: "Latitude",
    lngLabel: "Longitude",
  });

  /* Waypoints */
  const [areaWaypoints, setAreaWaypoints] = useState<number[][]>([]);
  const [operationWaypoints, setOperationWaypoints] = useState<Waypoint[]>(
    data.initialRouteWaypoint
  );
  const [emergencyLandingWaypoints, setEmergencyLandingWaypoints] = useState<
    Waypoint[]
  >(data.initialEmergencyLandingPoint);

  /* Form State */
  // Boolean to allow mapbox to update waypoints after import
  const [operationDataImported, setOperationDataImported] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [operationClarification, setOperationClarification] =
    useState<string>("");
  const [submittedOperation, setSubmittedOperation] =
    useState<OperationJson | null>(null);
  const [form, setForm] = useState<OperationForm>({
    intent: "",
    pilot_uuid: [""],
    platform_uuid: [""],
    tracker_uuid: [""],
    waypoints: [
      [0, 0],
      [0, 0],
    ],
    time_buffer: 30,
    vertical_buffer: 0,
    lateral_buffer: 0,
    rth_altitude: 60,
    elevation: 0,
    max_segment_length: 500,
    airspace_optimised: false,
    two_way: false,
    transit_duration: 0,
    altitude: 60,
    altitude_reference: "W84",
    time_start: moment(getFutureDate(3)).toISOString(),
    time_end: moment(getFutureDate(30)).toISOString(),
    transit_duration_hour: 0,
    transit_duration_minute: 0,
    ground_speed: 5,
    operation_type: "bvlos",
    description: "",
    contingency_plans: {
      landing_point: [[0, 0]],
      safe_altitude: 60,
    },
    area_coordinates: [],
    properties: {
      isCircle: false,
      radiusInKm: 0,
      center: [0, 0],
    },
    tag: "operation",
  });

  const [formError, setFormError] = useState({
    emergencyPoint: "",
    intent: "",
    pilot_uuid: "",
    platform_uuid: "",
    tracker_uuid: "",
    waypoints: "",
    time_buffer: "",
    vertical_buffer: "",
    lateral_buffer: "",
    rth_altitude: "",
    elevation: "",
    max_segment_length: "",
    airspace_optimised: "",
    two_way: "",
    transit_duration: "",
    altitude: "",
    altitude_reference: "",
    time_start: "",
    time_end: "",
    transit_duration_hour: "",
    transit_duration_minute: "",
    ground_speed: "",
    operation_type: "",
    description: "",
  });

  /* Operation Date */
  const [departureDate, setDepartureDate] = useState<Date>(getFutureDate(3));
  const [arrivalDate, setArrivalDate] = useState<Date>(getFutureDate(30));

  /* Optional Configuration */
  const [customBuffer, setCustomBuffer] = useState(false);
  const [twoWay, setTwoWay] = useState(false);

  /* Asset Configuration */
  const [allowMultipleAsset, setAllowMultipleAsset] = useState(false);
  const [additionalAsset, setAdditionalAsset] = useState<AdditionalAssetForm[]>(
    []
  );

  const inputButtonRef = useRef<HTMLInputElement>(null);

  const submittedOperationData = useRef({});

  const handleWaypointChange = (waypoints: Waypoint[]) => {
    handleOperationFormWaypointChange(waypoints, operationType);
    setOperationWaypoints(waypoints);
  };

  const handleOnDraw = () => {
    if (mapboxController) {
      mapboxController.trash();
      let message = "";
      if (operationType === CreateOperationType.CIRCLE) {
        message = "Pick a center point on the map";
        mapboxController.changeDrawMode("draw_circle");
      } else if (operationType === CreateOperationType.ROUTE) {
        message = "Draw waypoints on the map";
        mapboxController.changeDrawMode("draw_line_string");
      } else if (operationType === CreateOperationType.POLYGON) {
        message = "Draw polygon on the map";
        mapboxController.changeDrawMode("draw_polygon");
      }
      if (message) {
        showMessage(message);
        onCloseSidebar();
      }
    }
  };

  /* Import KML */
  const handleImportButtonClick = () => {
    if (inputButtonRef.current) {
      inputButtonRef.current.click();
    }
  };

  const handleImportOperation = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<string | undefined> => {
    try {
      if (!e.target.files) return "";
      const [file] = e.target.files;
      e.target.files = null; // Clear the input field
      const importFile = (reader: FileReader) => {
        return new Promise((resolve) => {
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
          if (file) {
            reader.readAsText(file);
          }
        });
      };
      if (file.type === "application/json") {
        const reader = new FileReader();
        const rawData = await importFile(reader);
        const data = typeof rawData === "string" ? JSON.parse(rawData) : "";
        handleJsonImport(data, dispatch);
      } else if (file.type === "") {
        const reader = new FileReader();
        const kmlData = await importFile(reader);
        handleKmlImport(kmlData, dispatch, true);
      } else {
        showMessage &&
          showMessage("Import must be a JSON or KML file.", false, true);
      }
    } catch {
      showMessage("Invalid file", false, true);
    }
    e.target.value = "";
  };

  useEffect(() => {
    if (!operationFormRequest?.request) return;
    try {
      const { request } = operationFormRequest;
      // // operationType handled in parent
      // setOperationWaypoints(operationWaypointsEdited);

      if (isEditOperation) {
        setDepartureDate(new Date(request.time_start));
        setArrivalDate(new Date(request.time_end));
      }
      // handle additional assets in import
      const additionalAssetArrayOperationRequestForm = [];
      if (request.pilot_uuid.length > 1) {
        for (let i = 1; i < request.pilot_uuid.length; i += 1) {
          if (
            !request.pilot_uuid[i] ||
            !request.platform_uuid[i] ||
            !request.tracker_uuid[i]
          )
            return;
          const pilot_uuid = request.pilot_uuid[i];
          const platform_uuid = request.platform_uuid[i];
          const tracker_uuid = request.tracker_uuid[i];

          additionalAssetArrayOperationRequestForm.push({
            id: Math.floor(Math.random() * 1000),
            pilot_uuid,
            platform_uuid,
            tracker_uuid,
          });
        }
      }
      setAdditionalAsset(additionalAssetArrayOperationRequestForm);
      setCustomBuffer(!!(request.vertical_buffer || request.lateral_buffer));
      setTwoWay(request.two_way || false);
      const formDetails = {
        intent: request.description || "",
        pilot_uuid: [request.pilot_uuid[0]],
        platform_uuid: [request.platform_uuid[0]],
        tracker_uuid: [request.tracker_uuid[0]],
        waypoints: [
          [0, 0],
          [0, 0],
        ],
        time_buffer: request.time_buffer || 30,
        vertical_buffer: request.vertical_buffer || 0,
        lateral_buffer: request.lateral_buffer || 0,
        rth_altitude: request.contingency_plans.safe_altitude || 60,
        elevation: request.elevation || 0,
        max_segment_length: request.max_segment_length || 500,
        airspace_optimised: request.airspace_optimised || false,
        transit_duration: request.transit_duration || 0,
        altitude: request.altitude || 60,
        altitude_reference: request.altitude_reference || "W84",
        time_start: isEditOperation
          ? request.time_start
          : moment(getFutureDate(3)).toISOString(),
        time_end: isEditOperation
          ? request.time_end
          : moment(getFutureDate(30)).toISOString(),
        transit_duration_hour: request.transit_duration
          ? Math.round(request.transit_duration / 3600)
          : 0,
        transit_duration_minute: request.transit_duration
          ? Math.round((request.transit_duration % 3600) / 60)
          : 0,
        ground_speed: request.ground_speed || 5,
        operation_type: request.operation_type || "bvlos",
        description: request.description || "",
        contingency_plans: {
          landing_point: request.contingency_plans.landing_point || [[0, 0]],
          safe_altitude: request.contingency_plans.safe_altitude || 60,
        },
        area_coordinates: request.area_coordinates || [],
        properties: request.properties || {
          isCircle: false,
          radiusInKm: 0,
          center: [0, 0],
        },
        tag: "operation",
      };
      setForm(formDetails);
    } catch (err: any) {
      showMessage(`Error reading data`, false, true);
    }
  }, [operationFormRequest]);

  const handleOnDrawEmergencyPoint = (value: any) => {
    if (mapboxController) {
      const message = "Pick a point on the map";
      mapboxController.changeDrawMode("draw_point");
      mapboxController.removeDrawFeatures("Point");

      showMessage(message);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "transit_duration_minute") {
      const newValue = Math.min(Number(e.target.value), 59);
      setForm({
        ...form,
        [e.target.name]: newValue,
      });
      return;
    }
    if (e.target.name === "intent") {
      if (form.description === form.intent) {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
          description: e.target.value,
        });
      } else {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setForm({
        ...form,
        [e.target.name]:
          e.target.type === "number"
            ? parseFloat(e.target.value)
            : e.target.value,
      });
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: string
  ) => {
    if (
      type === "pilot_uuid" ||
      type === "platform_uuid" ||
      type === "tracker_uuid"
    ) {
      let { value } = e.target;
      if (e.target.value === "Select") {
        value = "";
      }
      setForm((prevForm) => ({
        ...prevForm,
        [type]: [value],
      }));
      return;
    }

    setForm({
      ...form,
      [type]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    if (name === "inbound_operation") {
      const firstWaypoint = operationWaypoints[0];
      const lastWaypoint = operationWaypoints[operationWaypoints.length - 1];

      // reverse last and first waypoint position
      const updatedWaypoints = operationWaypoints.map((waypoint, index) => {
        if (index === 0) {
          const newWaypoint = {
            ...lastWaypoint,
            isFirst: false,
            isLast: true,
          };
          return newWaypoint;
        }
        if (index === operationWaypoints.length - 1) {
          const newWaypoint = {
            ...firstWaypoint,
            isFirst: true,
            isLast: false,
          };
          return newWaypoint;
        }
        return waypoint;
      });
      setOperationWaypoints(updatedWaypoints);

      // set emergency landing point using first updatedWaypoints
      const updatedEmergencyLandingWaypoints = emergencyLandingWaypoints.map(
        (waypoint) =>
          waypoint.isFirst
            ? {
                ...waypoint,
                latitude: updatedWaypoints[0].latitude,
                longitude: updatedWaypoints[0].longitude,
              }
            : waypoint
      );
      setEmergencyLandingWaypoints(updatedEmergencyLandingWaypoints);
      return;
    }

    if (name === "custom_buffer") {
      setCustomBuffer(e.target.checked);
    }

    if (name === "two_way") {
      setTwoWay(e.target.checked);
    }
    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleDateChange = (date: Date, type: string) => {
    let formField = "";
    if (type === "departure") {
      setDepartureDate(date);
      formField = "time_start";
    }
    if (type === "arrival") {
      setArrivalDate(date);
      formField = "time_end";
    }

    if (date) {
      const formattedDate = moment(date).toISOString();
      setForm({
        ...form,
        [formField]: formattedDate,
      });
    }
  };

  const handleAddAdditionalAsset = () => {
    if (!additionalAsset) {
      setAdditionalAsset([
        {
          id: Math.floor(Math.random() * 1000),
          pilot_uuid: pilots[0].pilot_uuid,
          platform_uuid: platforms[0].platform_uuid,
          tracker_uuid: trackers[0].tracker_uuid,
        },
      ]);
    } else {
      const newAsset = {
        id: Math.floor(Math.random() * 1000),
        pilot_uuid: pilots[0].pilot_uuid,
        platform_uuid: platforms[0].platform_uuid,
        tracker_uuid: trackers[0].tracker_uuid,
      };
      setAdditionalAsset([...additionalAsset, newAsset]);
    }
  };

  const handleAssetSelect = (id: number, type: string, value: string) => {
    if (additionalAsset) {
      const newAsset = additionalAsset.map((asset) => {
        if (asset.id === id) {
          return {
            ...asset,
            [type]: value,
          };
        }
        return asset;
      });
      setAdditionalAsset(newAsset);
    }
  };

  const handleRemoveAsset = (id: number) => {
    if (additionalAsset) {
      const newAsset = additionalAsset.filter((asset) => asset.id !== id);
      setAdditionalAsset(newAsset);
    }
  };

  const handleResetForm = () => {
    onConfirmation(
      "Reset Confirmation",
      "Are you sure you to reset the form ?",
      (result) => {
        if (result) {
          resetForm();
        }
      }
    );
  };
  // handle network error when creating operation

  const handleWSLogMessage = (
    singleBellNotificationWsMessage: BellNotificationType
  ) => {
    if (!singleBellNotificationWsMessage) return;
    if (
      singleBellNotificationWsMessage.data?.rerouted ||
      singleBellNotificationWsMessage.data?.rescheduled
    ) {
      const operationUuid = singleBellNotificationWsMessage.data?.operation_id;
      checkForRerouteError(
        submittedOperationData.current as FlightPlan,
        operationUuid
      );
    }
  };

  const checkForRerouteError = async (
    operationData: FlightPlan,
    operationUuid?: string
  ) => {
    try {
      let res;
      if (operationUuid) {
        res = await api.getOperationData({
          operationID: operationUuid,
        });
        const { rerouted, rescheduled } = res.data.details;
        // compare start and end waypoint to ensure is same operation
        if (
          res.data.request.waypoints[0][0] === operationData.waypoints[0][0] &&
          res.data.request.waypoints[0][1] === operationData.waypoints[0][1] &&
          res.data.request.waypoints[
            res.data.request.waypoints.length - 1
          ][0] ===
            operationData.waypoints[operationData.waypoints.length - 1][0] &&
          res.data.request.waypoints[
            res.data.request.waypoints.length - 1
          ][1] ===
            operationData.waypoints[operationData.waypoints.length - 1][1]
        ) {
          setSubmittedOperation(res.data);
          onOperationRevision(
            rerouted,
            rescheduled,
            res.data,
            operationData as FlightPlan
          );
        }
      } else {
        // handle no operation id available
        res = await api.getOperations({
          isOperations: true,
          operationStates: "accepted",
        });
        const rereoutedRescheduledOperations = res.data.filter(
          (singleOperation: Operation) => {
            // compare start and end waypoint to ensure is same operation
            if (
              singleOperation.operation_json.request.waypoints[0][0] ===
                operationData.waypoints[0][0] &&
              singleOperation.operation_json.request.waypoints[0][1] ===
                operationData.waypoints[0][1] &&
              singleOperation.operation_json.request.waypoints[
                singleOperation.operation_json.request.waypoints.length - 1
              ][0] ===
                operationData.waypoints[
                  operationData.waypoints.length - 1
                ][0] &&
              singleOperation.operation_json.request.waypoints[
                singleOperation.operation_json.request.waypoints.length - 1
              ][1] ===
                operationData.waypoints[operationData.waypoints.length - 1][1]
            ) {
              return (
                singleOperation.operation_json.details.rerouted ||
                singleOperation.operation_json.details.rescheduled
              );
            }
          }
        );
        if (rereoutedRescheduledOperations.length === 1) {
          const { rerouted, rescheduled } =
            rereoutedRescheduledOperations[0].operation_json.details;
          if (rerouted || rescheduled) {
            setSubmittedOperation(
              rereoutedRescheduledOperations[0].operation_json
            );
            onOperationRevision(
              rerouted,
              rescheduled,
              rereoutedRescheduledOperations[0].operation_json,
              operationData as FlightPlan
            );
          }
        }
      }
    } catch {
      showMessage("Unable to verify operation creation status", false, true);
    }
  };

  // End of handle network error when creating operation

  const handleSubmitOperation = async () => {
    if (!validateForm()) {
      showMessage("Please fill all required fields");
      return;
    }

    // prevent asset from duplicating when user add and then remove asset
    const pilots = [form.pilot_uuid[0]];
    const platforms = [form.platform_uuid[0]];
    const trackers = [form.tracker_uuid[0]];

    if (additionalAsset.length > 0) {
      additionalAsset.forEach((asset) => {
        pilots.push(asset.pilot_uuid);
        platforms.push(asset.platform_uuid);
        trackers.push(asset.tracker_uuid);
      });
    }
    form.pilot_uuid = pilots;
    form.platform_uuid = platforms;
    form.tracker_uuid = trackers;

    const operationData = {
      ...form,
      contingency_plans: {
        landing_point: emergencyLandingWaypoints.map((waypoint) => [
          waypoint.longitude,
          waypoint.latitude,
        ]),
        safe_altitude: form.rth_altitude,
      },
      tag: "operation",
    };

    submittedOperationData.current = operationData;

    if (twoWay) {
      let transitDuration = 0;
      if (form.transit_duration_hour && form.transit_duration_hour !== 0) {
        transitDuration += form.transit_duration_hour * 3600;
      }

      if (form.transit_duration_minute && form.transit_duration_minute !== 0) {
        transitDuration += form.transit_duration_minute * 60;
      }
      operationData.transit_duration = transitDuration;
    }

    if (operationType === CreateOperationType.CIRCLE) {
      operationData.area_coordinates = areaWaypoints;
      operationData.properties = {
        isCircle: true,
        radiusInKm: selectedCircleProperties.radiusInKm,
        center: [
          operationWaypoints[0].longitude,
          operationWaypoints[0].latitude,
        ],
      };
    }

    if (operationType === CreateOperationType.POLYGON) {
      delete operationData.transit_duration;
      delete operationData.time_buffer;
      operationData.area_coordinates = areaWaypoints;
      operationData.properties = {
        isCircle: false,
      };
    }

    if (isScheduleManagement) {
      showMessage("Submitting Schedule, Please Wait");
    } else {
      showMessage("Submitting Operation, Please Wait");
    }
    setIsLoading(true);

    try {
      // delete operationData.intent;
      delete operationData.transit_duration_hour;
      delete operationData.transit_duration_minute;
      delete operationData.rth_altitude;

      if (!customBuffer) {
        delete operationData.vertical_buffer;
        delete operationData.lateral_buffer;
      }

      if (operationType !== CreateOperationType.ROUTE) {
        delete operationData.max_segment_length;
        delete operationData.ground_speed;
        delete operationData.airspace_optimised;
        delete operationData.two_way;
        delete operationData.waypoints;
      }

      if (operationType === CreateOperationType.ROUTE) {
        delete operationData.area_coordinates;
        delete operationData.properties;

        operationData.waypoints = operationWaypoints.map((waypoint) => [
          waypoint.longitude,
          waypoint.latitude,
        ]);
      }

      let response;
      // authority only accesses this page for propose changes
      if (isAuthority) {
        const proposedChangesData = {
          ...operationData,
          clarification: operationClarification,
          original_request: operationFormRequest.request,
        };
        try {
          response = await api.aas.setFlightRejected(
            operationFormRequest.id,
            proposedChangesData
          );
          if (response.status === 200 || response.status === 204) {
            showMessage("Operation Changes Submitted", true);
            onOperationSubmitted();
          } else {
            showMessage("Unable to modify operations", false, true);
            onOperationSubmitted();
          }
          return;
        } catch (err: any) {
          let { message } = err.response.data;
          if (message && message.startsWith("Airspace unavailable")) {
            message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
              message.split(" ").at(-2)
            )}`;
          }
          showMessage(`Error : ${message}`, false, true);
          onOperationSubmitted();
          return;
        }
      } else if (isEditOperation) {
        if (isScheduleManagement) {
          const scheduleData = {
            ...operationData,
            ...scheduleForm,
          };
          response = await api.editSchedule(
            operationFormRequest.id,
            scheduleData
          );
        } else if (isShoreToShipOperation) {
          const shoreToShipData = {
            ...operationData,
            ...scheduleForm,
          };
          response = await api.editOperation(
            operationFormRequest.id,
            shoreToShipData
          );
        } else {
          response = await api.editOperation(
            operationFormRequest.id,
            operationData
          );
        }
      } else if (isScheduleManagement) {
        const scheduleData = {
          ...operationData,
          ...scheduleForm,
        };
        response = await api.submitSchedule(scheduleData);
      } else if (isShoreToShipOperation) {
        const shoreToShipData = {
          ...operationData,
          ...scheduleForm,
        };
        response = await api.submitOperation(shoreToShipData);
      } else if (operationType === CreateOperationType.ROUTE) {
        response = await api.submitOperation(operationData);
      } else {
        response = await api.submitAreaOperation(operationData);
      }

      if (isScheduleManagement && response.data) {
        mapboxController.removeDrawFeaturesAll();
        resetForm();
        setIsLoading(false);
        showMessage("Schedule Submitted", true);
        onOperationSubmitted();
        return;
      }

      if (response.data) {
        mapboxController.removeDrawFeaturesAll();
        resetForm();
        showMessage("Operation Submitted", true);

        const responseOperation = response.data.operations as OperationJson[];
        if (responseOperation.length > 0) {
          const { rerouted } = responseOperation[0].details;
          const { rescheduled } = responseOperation[0].details;
          if (rerouted || rescheduled) {
            setSubmittedOperation(responseOperation[0]);
            onOperationRevision(
              rerouted,
              rescheduled,
              responseOperation[0],
              operationData as FlightPlan
            );
          } else {
            onOperationSubmitted();
          }
        }
      }
    } catch (err: any) {
      let message = "Error getting response, please try again";
      // Remove after test
      if (!isAuthority && !isEditOperation && !isScheduleManagement) {
        // if error occur during creation, trigger ws listener
        const removeLogMessageListener = channel?.addMessageListener(
          messageTypes.LOG_MESSAGE,
          handleWSLogMessage
        );
        // Schedule removal after 60 seconds
        setTimeout(() => {
          removeLogMessageListener?.();
        }, 60000);
      }

      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message || err.message;
        if (errorMessage) {
          message = errorMessage;
        }
        if (message && message.startsWith("Airspace already reserved")) {
          const time = message.split(" ").at(-2);
          if (time) {
            message = `Airspace already reserved. Try again after: ${convertZuluToLocalTime(
              time
            )}`;
          }
        }
        if (err.code === "ECONNABORTED" || err?.response?.status === 504) {
          message = "Network Error";
        }
        if (err?.response?.status === 400) {
          message = err?.response?.data?.message
            ? err?.response?.data?.message
            : message;
        }
      }
      showMessage(message, false, true);
    }

    setIsLoading(false);
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      two_way: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      time_end: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    };

    if (isScheduleManagement || isShoreToShipOperation) {
      if (
        scheduleForm?.anchorage_details.anchorage_id === "" ||
        scheduleForm?.vessel_details.vessel_imo === ""
      ) {
        setScheduleFormError({
          anchorage_id:
            scheduleForm?.anchorage_details.anchorage_id === "" &&
            "No Anchorage selected",
          vessel_imo:
            scheduleForm?.vessel_details.vessel_imo === "" &&
            "Vessel IMO required",
        });
        isValid = false;
      }
    }

    if (form.intent === "") {
      error.intent = "Intent is required";
      isValid = false;
    }

    if (form.pilot_uuid[0] === "") {
      error.pilot_uuid = "Pilot is required";
      isValid = false;
    }

    if (form.platform_uuid[0] === "") {
      error.platform_uuid = "Platform is required";
      isValid = false;
    }

    if (form.tracker_uuid[0] === "") {
      error.tracker_uuid = "Tracker is required";
      isValid = false;
    }

    const isValidWaypoints = operationWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidWaypoints) {
      error.waypoints = "Waypoints is required";
      isValid = false;
    }

    const isValidEmergencyPoint = emergencyLandingWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidEmergencyPoint) {
      error.emergencyPoint = "Emergency point is required";
      isValid = false;
    }

    if (form.time_buffer === 0 || Number.isNaN(form.time_buffer)) {
      error.time_buffer = "Time buffer is required";
      isValid = false;
    }

    if (
      (customBuffer && form.vertical_buffer === 0) ||
      Number.isNaN(form.vertical_buffer)
    ) {
      error.vertical_buffer = "Vertical buffer is required";
      isValid = false;
    }

    if (
      (customBuffer && form.lateral_buffer === 0) ||
      Number.isNaN(form.lateral_buffer)
    ) {
      error.lateral_buffer = "Lateral buffer is required";
      isValid = false;
    }

    if (form.rth_altitude === 0 || Number.isNaN(form.rth_altitude)) {
      error.rth_altitude = "RTH altitude is required";
      isValid = false;
    }

    if (Number.isNaN(form.elevation)) {
      error.elevation = "Elevation is required";
      isValid = false;
    }

    if (
      form.max_segment_length === 0 ||
      (Number.isNaN(form.max_segment_length) &&
        operationType === CreateOperationType.ROUTE)
    ) {
      error.max_segment_length = "Max segment length is required";
      isValid = false;
    }

    if (form.altitude === 0 || Number.isNaN(form.altitude)) {
      error.altitude = "Altitude is required";
      isValid = false;
    }

    if (form.altitude_reference === "") {
      error.altitude_reference = "Altitude reference is required";
      isValid = false;
    }

    if (form.time_start === "") {
      error.time_start = "Time start is required";
      isValid = false;
    }

    if (form.time_end === "") {
      error.time_end = "Time end is required";
      isValid = false;
    }

    if (
      twoWay &&
      (form.transit_duration_hour === 0 ||
        Number.isNaN(form.transit_duration_hour)) &&
      (form.transit_duration_minute === 0 ||
        Number.isNaN(form.transit_duration_minute))
    ) {
      error.transit_duration_hour = "Transit duration is required";

      error.transit_duration_minute = "Transit duration is required";
      isValid = false;
    }

    if (form.ground_speed === 0 || Number.isNaN(form.ground_speed)) {
      error.ground_speed = "Ground speed is required";
      isValid = false;
    }

    if (form.operation_type === "") {
      error.operation_type = "Operation type is required";
      isValid = false;
    }

    if (form.description === "") {
      error.description = "Description is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const resetForm = () => {
    mapboxController.removeDrawFeaturesAll();
    setIsEditOperation(false);
    handleOperationFormWaypointChange([], "ResetForm");
    if (operationType === CreateOperationType.CIRCLE) {
      setOperationWaypoints(data.initialCircleWaypoint);
    } else if (operationType === CreateOperationType.ROUTE) {
      setOperationWaypoints(data.initialRouteWaypoint);
    } else if (operationType === CreateOperationType.POLYGON) {
      setOperationWaypoints(data.initialPolygonWaypoint);
    }
    dispatch(setOperationFormRequest(null));
    setOperationDataImported(false);
    setAdditionalAsset([]);
    setEmergencyLandingWaypoints(data.initialEmergencyLandingPoint);
    setCustomBuffer(false);
    setTwoWay(false);
    setDepartureDate(getFutureDate(3));
    setArrivalDate(getFutureDate(30));
    setForm({
      intent: "",
      pilot_uuid: [""],
      platform_uuid: [""],
      tracker_uuid: [""],
      waypoints: [
        [0, 0],
        [0, 0],
      ],
      time_buffer: 30,
      vertical_buffer: 0,
      lateral_buffer: 0,
      rth_altitude: 60,
      elevation: 0,
      max_segment_length: 500,
      airspace_optimised: false,
      two_way: false,
      transit_duration: 0,
      altitude: 60,
      altitude_reference: "W84",
      time_start: moment(departureDate).toISOString(),
      time_end: moment(arrivalDate).toISOString(),
      transit_duration_hour: 0,
      transit_duration_minute: 0,
      ground_speed: 5,
      operation_type: "bvlos",
      description: "",
      contingency_plans: {
        landing_point: [[0, 0]],
        safe_altitude: 60,
      },
      tag: "operation",
    });
    setFormError({
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      two_way: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      time_end: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    });
    resetScheduleForm();
  };

  const deleteOperation = async () => {
    // TODO: Handle delete two way operation

    setIsLoading(true);
    showMessage("Discarding Operation, Please Wait");

    try {
      const response = await api.deleteOperation(
        submittedOperation?.reference.id
      );
      setIsLoading(false);
      if (response.status === 200) {
        showMessage("Operation Deleted", true);
      } else {
        showMessage(
          "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
          false,
          true
        );
      }
    } catch {
      setIsLoading(false);
      showMessage(
        "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
        false,
        true
      );
    }

    onOperationSubmitted();
  };

  const resubmitOperation = async () => {
    showMessage("Resubmitting Operation, Please Wait");
    setIsLoading(true);
    const response = await api.editOperation(
      submittedOperation?.reference.id,
      submittedOperation?.request,
      "true"
    );
    if (response.status === 200) {
      showMessage(
        "Original plan resubmitted successfully for manual approval",
        true
      );
    } else {
      showMessage("Unable to resubmit original plan", false, true);
    }

    onOperationSubmitted();
  };

  useEffect(() => {
    if (emergencyLandingWaypoints[0]?.latitude) {
      handleSetEmergencyLanding([
        emergencyLandingWaypoints[0].longitude,
        emergencyLandingWaypoints[0].latitude,
      ]);
    }
  }, [emergencyLandingWaypoints]);
  // handle the result of route map drawing
  useEffect(() => {
    if (operationType !== CreateOperationType.ROUTE) {
      return;
    }
    try {
      const newWaypoints: Waypoint[] = [];
      if (operationFormRequest && !operationDataImported) {
        const { request } = operationFormRequest;
        request.waypoints.forEach((waypoint, index) => {
          let nP = index;
          if (index === 0) {
            nP = 1;
          } else if (index === request.waypoints.length - 1) {
            nP = 2;
          } else {
            nP = Math.floor(Math.random() * 1000);
          }
          newWaypoints.push({
            position: nP,
            latitude: waypoint[1],
            longitude: waypoint[0],
            isFirst: index === 0,
            isLast: index === request.waypoints.length - 1,
          });
        });
        setOperationWaypoints(newWaypoints);

        const newEmergencyLandingWaypoints: Waypoint[] = [];
        newEmergencyLandingWaypoints.push({
          position: 1,
          latitude: request.contingency_plans.landing_point[0][1],
          longitude: request.contingency_plans.landing_point[0][0],
          isFirst: true,
          isLast: true,
        });

        setEmergencyLandingWaypoints(newEmergencyLandingWaypoints);
        setOperationDataImported(true);
      } else {
        selectedWaypoint.forEach((waypoint, index) => {
          let nP = index;
          if (index === 0) {
            nP = 1;
          } else if (index === selectedWaypoint.length - 1) {
            nP = 2;
          } else {
            nP = Math.floor(Math.random() * 1000);
          }
          newWaypoints.push({
            position: nP,
            latitude: waypoint[1],
            longitude: waypoint[0],
            isFirst: index === 0,
            isLast: index === selectedWaypoint.length - 1,
          });
        });
        setOperationWaypoints(newWaypoints);

        const newEmergencyLandingWaypoints: Waypoint[] = [];
        let latitude = 0;
        let longitude = 0;
        try {
          [[, latitude]] = selectedWaypoint;
          [[longitude]] = selectedWaypoint;
        } catch {
          console.log("No lat long value detected");
        }
        newEmergencyLandingWaypoints.push({
          position: 1,
          latitude,
          longitude,
          isFirst: true,
          isLast: true,
        });

        setEmergencyLandingWaypoints(newEmergencyLandingWaypoints);
      }
    } catch (err: any) {
      showMessage(`Error reading data`, false, true);
    }
  }, [selectedWaypoint, operationFormRequest]);

  // handle the result of polygon drawing
  useEffect(() => {
    if (operationType !== CreateOperationType.POLYGON) {
      return;
    }
    let areaOperations: number[][] = [];

    if (operationFormRequest && !operationDataImported) {
      const { request } = operationFormRequest;
      setAreaWaypoints(request.area_coordinates);

      const newWaypoints: Waypoint[] = [];
      request.area_coordinates.forEach((waypoint, index) => {
        newWaypoints.push({
          position: index + 1,
          latitude: waypoint[1],
          longitude: waypoint[0],
          isFirst: index === 0,
          isLast: index === request.area_coordinates.length - 1,
        });
      });
      setOperationWaypoints(newWaypoints);

      const newEmergencyLandingWaypoints: Waypoint[] = [];
      newEmergencyLandingWaypoints.push({
        position: 1,
        latitude: request.contingency_plans.landing_point[0][1],
        longitude: request.contingency_plans.landing_point[0][0],
        isFirst: true,
        isLast: true,
      });
      setEmergencyLandingWaypoints(newEmergencyLandingWaypoints);
      setOperationDataImported(true);
    } else {
      if (selectedAreaWaypoints.length > 0) {
        areaOperations = selectedAreaWaypoints;
      }
      setAreaWaypoints(areaOperations);

      const newWaypoints: Waypoint[] = [];
      selectedAreaWaypoints.forEach((waypoint, index) => {
        newWaypoints.push({
          position: index + 1,
          latitude: waypoint[1],
          longitude: waypoint[0],
          isFirst: index === 0,
          isLast: index === selectedAreaWaypoints.length - 1,
        });
      });
      setOperationWaypoints(newWaypoints);

      const newEmergencyLandingWaypoints: Waypoint[] = [];
      // Area operation to calculate center point to use as emergency point
      const centerLatLng = computePolygonCenter(selectedAreaWaypoints) || [
        0, 0,
      ];
      newEmergencyLandingWaypoints.push({
        position: 1,
        latitude: centerLatLng[1],
        longitude: centerLatLng[0],
        isFirst: true,
        isLast: true,
      });
      setEmergencyLandingWaypoints(newEmergencyLandingWaypoints);
    }
  }, [operationType, selectedAreaWaypoints]);

  // handle the result of circle drawing
  useEffect(() => {
    if (operationType !== CreateOperationType.CIRCLE) {
      return;
    }
    if (operationFormRequest && !operationDataImported) {
      const { request } = operationFormRequest;
      const areaCoordinates = request.area_coordinates;
      setAreaWaypoints(areaCoordinates);

      const operationWaypointsEdited = [
        {
          position: 1,
          latitude: request.properties.center[1],
          longitude: request.properties.center[0],
          radius: request.properties.radiusInKm,
          isFirst: true,
          isLast: true,
        },
      ];
      const emergencyOperationWaypointsEdited = [
        {
          position: 1,
          latitude: request.contingency_plans.landing_point[0][1],
          longitude: request.contingency_plans.landing_point[0][0],
          radius: 0,
          isFirst: true,
          isLast: true,
        },
      ];
      setOperationWaypoints(operationWaypointsEdited);
      setEmergencyLandingWaypoints(emergencyOperationWaypointsEdited); // FIX
      setOperationDataImported(true);
    } else {
      let areaOperations: number[][] = [];
      if (selectedCircleWaypoint.length > 0) {
        areaOperations = selectedCircleWaypoint;
      }
      setAreaWaypoints(areaOperations);
    }
  }, [operationType, selectedCircleWaypoint]);

  // handle the result of cirlce properties
  useEffect(() => {
    if (operationType !== CreateOperationType.CIRCLE) {
      return;
    }
    if (
      selectedCircleProperties &&
      (!operationFormRequest || operationDataImported)
    ) {
      const newWaypoints: Waypoint[] = [];
      newWaypoints.push({
        position: 1,
        latitude: selectedCircleProperties.center[1],
        longitude: selectedCircleProperties.center[0],
        radius: selectedCircleProperties.radiusInKm,
        isFirst: true,
        isLast: true,
      });
      setOperationWaypoints(newWaypoints);
      setEmergencyLandingWaypoints(newWaypoints);
    }
  }, [operationType, selectedCircleProperties]);

  useEffect(() => {
    if (
      // removed this check to handle scenerio where after submit
      // circle operation is select but no radius option
      // curentOperationType !== CreateOperationType.CIRCLE &&
      operationType === CreateOperationType.CIRCLE
    ) {
      if (
        !operationFormRequest ||
        !operationFormRequest?.request?.properties?.isCircle
      )
        setOperationWaypoints(data.initialCircleWaypoint);
      setCurrentOperationType(operationType);
      setInputWaypointConfiguration({
        keyLabel: "",
        drawIcon: <FaMapPin className="fill-blue-500" />,
        drawLabel: "Pick Center Point",
        showRadius: true,
        allowMultiple: false,
        useTakeOffLandingPoint: false,
        minWaypointCount: 1,
        latLabel: "LAT (Center)",
        lngLabel: "LONG (Center)",
      });
    } else if (
      curentOperationType !== CreateOperationType.ROUTE &&
      operationType === CreateOperationType.ROUTE
    ) {
      if (!operationFormRequest || !operationFormRequest?.request?.properties)
        setOperationWaypoints(data.initialRouteWaypoint);
      setCurrentOperationType(operationType);
      setInputWaypointConfiguration({
        keyLabel: "WP",
        drawIcon: <MdTimeline className="fill-blue-500" />,
        drawLabel: "Draw Waypoint",
        showRadius: false,
        allowMultiple: true,
        useTakeOffLandingPoint: true,
        minWaypointCount: 2,
        latLabel: "Latitude",
        lngLabel: "Longitude",
      });
    } else if (
      curentOperationType !== CreateOperationType.POLYGON &&
      operationType === CreateOperationType.POLYGON
    ) {
      if (
        !operationFormRequest ||
        operationFormRequest?.request?.properties?.isCircle
      )
        setOperationWaypoints(data.initialPolygonWaypoint);
      setCurrentOperationType(operationType);
      setInputWaypointConfiguration({
        keyLabel: "WP",
        drawIcon: <FaDrawPolygon className="fill-blue-500" />,
        drawLabel: "Draw Polygon",
        showRadius: false,
        allowMultiple: true,
        useTakeOffLandingPoint: false,
        minWaypointCount: 3,
        latLabel: "Latitude",
        lngLabel: "Longitude",
      });
    }
    setAllowMultipleAsset(operationType !== CreateOperationType.ROUTE); // disable multiple assets for route operation
    if (!operationFormRequest) {
      setAdditionalAsset([]);
      setEmergencyLandingWaypoints([]);
    }
    mapboxController.trash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentOperationType, operationType]);

  useEffect(() => {
    if (revisionAction.isAccepted) {
      onOperationSubmitted();
    } else if (revisionAction.isDiscard) {
      deleteOperation();
    } else if (revisionAction.isResubmit) {
      resubmitOperation();
    }
    // else if (revisionAction.isEdit) {
    //   console.log("Edit Operation Detected");
    // }
  }, [revisionAction]);

  return (
    <div className={`h-full flex-col px-4 ${className}`}>
      <h5 className="font-medium mb-2">Operation Details</h5>
      {/* Waypoints */}
      <div className="flex mb-2 items-center">
        <FaLocationArrow size={14} color="gray" />
        <h5 className="ms-2 font-medium grow">Waypoints</h5>
        {(isScheduleManagement || isShoreToShipOperation) && (
          <div
            className="flex items-center px-2 cursor-pointer"
            onClick={handleImportButtonClick}
          >
            <CiImport className="fill-blue-500" />
            <p className="ml-1 text-blue-500 text-sm font-medium">Import KML</p>
            <input
              type="file"
              onChange={handleImportOperation}
              hidden
              ref={inputButtonRef}
            />
          </div>
        )}
        <div
          className="flex items-center px-2 cursor-pointer"
          onClick={handleOnDraw}
        >
          {inputWaypointConfiguration.drawIcon}
          <p className="ml-1 text-blue-500 text-sm font-medium">
            {inputWaypointConfiguration.drawLabel}
          </p>
        </div>
      </div>
      <div className="rounded-container p-2 mb-4">
        <InputWaypoint
          keyLabel={inputWaypointConfiguration.keyLabel}
          allowMultiple={inputWaypointConfiguration.allowMultiple}
          showRadius={inputWaypointConfiguration.showRadius}
          minWaypointCount={inputWaypointConfiguration.minWaypointCount}
          latLabel={inputWaypointConfiguration.latLabel}
          lngLabel={inputWaypointConfiguration.lngLabel}
          useTakeOffLandingPoint={
            inputWaypointConfiguration.useTakeOffLandingPoint
          }
          waypointsData={operationWaypoints}
          onWaypointChanged={handleWaypointChange}
          goToLocation={goToLocation}
        />
      </div>
      {formError.waypoints && (
        <p className="text-red-500 text-sm -mt-2 mb-4">{formError.waypoints}</p>
      )}

      {/* Emergency Point */}
      <div className="flex mb-2 items-center">
        <MdEmergencyShare size={18} color="gray" />
        <h5 className="ms-2 font-medium">Emergency Point</h5>
      </div>
      <div className="rounded-container p-2 mb-4">
        <InputWaypoint
          keyLabel="EP"
          allowMultiple={false}
          pickFromMapButton={true}
          handleOnDrawEmergencyPoint={handleOnDrawEmergencyPoint}
          waypointsData={emergencyLandingWaypoints}
          onWaypointChanged={(waypoints) =>
            setEmergencyLandingWaypoints(waypoints)
          }
          goToLocation={goToLocation}
          emergencyLanding={emergencyLanding}
        />
      </div>
      {formError.emergencyPoint && (
        <p className="text-red-500 text-sm -mt-2 mb-4">
          {formError.emergencyPoint}
        </p>
      )}

      {/* Operation Configuration */}
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="elevation" className="label">
            Elevation (m)
          </label>
          <input
            type="number"
            className="input-text"
            name="elevation"
            value={form.elevation}
            onChange={handleTextChange}
          />
          {formError.elevation && (
            <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="altitude" className="label">
            Max Altitude (m)
          </label>
          <input
            type="number"
            name="altitude"
            className="input-text"
            value={form.altitude}
            onChange={handleTextChange}
          />
          {formError.altitude && (
            <p className="text-red-500 text-sm mt-1">{formError.altitude}</p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="rth_altitude" className="label">
            RTH Altitude (m)
          </label>
          <input
            type="number"
            name="rth_altitude"
            className="input-text"
            defaultValue={form.contingency_plans.safe_altitude}
            onChange={handleTextChange}
          />
          {formError.rth_altitude && (
            <p className="text-red-500 text-sm mt-1">
              {formError.rth_altitude}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Altitude Reference</label>
          <select
            name="altitude_reference"
            className="input-select"
            onChange={(e) => handleSelectChange(e, "altitude_reference")}
          >
            <option value="W84">W84</option>
            <option value="AGL">AGL</option>
          </select>
          {formError.altitude_reference && (
            <p className="text-red-500 text-sm mt-1">
              {formError.altitude_reference}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="vertical_buffer" className="label">
            Vertical Buffer (m)
          </label>
          <input
            type="number"
            name="vertical_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            value={form.vertical_buffer}
            onChange={handleTextChange}
          />
          {formError.vertical_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.vertical_buffer}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Lateral Buffer (m)</label>
          <input
            type="number"
            name="lateral_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            value={form.lateral_buffer}
            onChange={handleTextChange}
          />
          {formError.lateral_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.lateral_buffer}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="custom_buffer"
          checked={customBuffer}
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Custom Buffer</label>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">Time Buffer (s)</label>
          <input
            type="number"
            name="time_buffer"
            className="input-text"
            value={form.time_buffer}
            onChange={handleTextChange}
          />
          {formError.time_buffer && (
            <p className="text-red-500 text-sm mt-1">{formError.time_buffer}</p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Speed (m/s)</label>
          <input
            type="number"
            name="ground_speed"
            className="input-text"
            value={form.ground_speed}
            onChange={handleTextChange}
          />
          {formError.ground_speed && (
            <p className="text-red-500 text-sm mt-1">
              {formError.ground_speed}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="Departure Time (Local Time)"
            value={departureDate}
            className="mr-2"
            onChange={(date) => date && handleDateChange(date, "departure")}
          />
          {formError.time_start && (
            <p className="text-red-500 text-sm mt-1">{formError.time_start}</p>
          )}
        </div>
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="End Time (Local Time)"
            value={arrivalDate}
            onChange={(date) => date && handleDateChange(date, "arrival")}
          />
          {formError.time_start && (
            <p className="text-red-500 text-sm mt-1">{formError.time_start}</p>
          )}
        </div>
      </div>
      {operationType === CreateOperationType.ROUTE && (
        <div className="mb-2">
          <label htmlFor="max_segment_length" className="label">
            Max Segment Length (m)
          </label>
          <input
            type="number"
            name="max_segment_length"
            className="input-text"
            value={form.max_segment_length}
            onChange={handleTextChange}
          />
          {formError.max_segment_length && (
            <p className="text-red-500 text-sm mt-1">
              {formError.max_segment_length}
            </p>
          )}
        </div>
      )}
      <div className="mb-2">
        <label htmlFor="pilot" className="label">
          Pilot Name
        </label>
        <select
          name="pilot_uuid"
          onChange={(e) => handleSelectChange(e, "pilot_uuid")}
          className="input-select"
          value={form.pilot_uuid[0]}
        >
          <option>Select</option>
          {pilots.map((pilot) => (
            <option key={pilot.pilot_uuid} value={pilot.pilot_uuid}>
              {pilot.pilot_username}
            </option>
          ))}
        </select>
        {formError.pilot_uuid && (
          <p className="text-red-500 text-sm mt-1">{formError.pilot_uuid}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="platform" className="label">
            Platform Callsign
          </label>
          <select
            name="platform_uuid"
            onChange={(e) => handleSelectChange(e, "platform_uuid")}
            className="input-select"
            value={form.platform_uuid[0]}
          >
            <option>Select</option>
            {platforms.map((platform) => (
              <option
                key={platform.platform_uuid}
                value={platform.platform_uuid}
              >
                {platform.platform_callsign}
              </option>
            ))}
          </select>
          {formError.platform_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.platform_uuid}
            </p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="tracker" className="label">
            Tracker Callsign
          </label>
          <select
            name="tracker_uuid"
            onChange={(e) => handleSelectChange(e, "tracker_uuid")}
            className="input-select"
            value={form.tracker_uuid[0]}
          >
            <option>Select</option>
            {trackers.map((tracker) => (
              <option key={tracker.tracker_uuid} value={tracker.tracker_uuid}>
                {tracker.tracker_name}
              </option>
            ))}
          </select>
          {formError.tracker_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.tracker_uuid}
            </p>
          )}
        </div>
      </div>
      {allowMultipleAsset && (
        <div className="flex-col">
          {additionalAsset &&
            additionalAsset.map((asset, index) => (
              <AssetSelection
                assetIndex={index}
                key={asset.id}
                selectedAsset={asset}
                pilots={pilots}
                platforms={platforms}
                trackers={trackers}
                onPilotSelect={(pilot_uuid) =>
                  handleAssetSelect(asset.id, "pilot_uuid", pilot_uuid)
                }
                onPlatformSelect={(platform_uuid) =>
                  handleAssetSelect(asset.id, "platform_uuid", platform_uuid)
                }
                onTrackerSelect={(tracker_uuid) =>
                  handleAssetSelect(asset.id, "tracker_uuid", tracker_uuid)
                }
                onRemove={() => handleRemoveAsset(asset.id)}
              />
            ))}

          <button
            className="my-4 w-full text-center"
            onClick={() => handleAddAdditionalAsset()}
          >
            <span className="text-blue-500 font-medium">
              Add Additional Assets
            </span>
          </button>
        </div>
      )}
      {operationType === CreateOperationType.ROUTE && (
        <div className="flex-col">
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="input-checkbox"
              name="airspace_optimised"
              checked={form.airspace_optimised}
              onChange={handleCheckboxChange}
            />
            <label className="ml-2 text-sm">Optimized</label>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className={`input-checkbox ${
                isEditOperation ? "opacity-50" : ""
              }`}
              name="two_way"
              checked={twoWay}
              onChange={handleCheckboxChange}
              disabled={isEditOperation}
            />
            <label
              className={`ml-2 text-sm  ${isEditOperation ? "opacity-50" : ""}`}
            >
              Two Way
            </label>
          </div>
        </div>
      )}
      {/* Transit Duration */}
      {twoWay && (
        <div className="mb-4">
          <h4>Transit Duration</h4>
          <div className="flex">
            <div className="w-full mr-2">
              <label htmlFor="transit_duration" className="label">
                Transit Duration (h)
              </label>
              <input
                type="number"
                name="transit_duration_hour"
                className={`input-text ${
                  isEditOperation && !isScheduleManagement ? "opacity-50" : ""
                }`}
                disabled={isEditOperation && !isScheduleManagement}
                value={form.transit_duration_hour}
                onChange={handleTextChange}
              />
              {formError.transit_duration_hour && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.transit_duration_hour}
                </p>
              )}
            </div>
            <div className="w-full">
              <label htmlFor="transit_duration" className="label">
                Transit Duration (m)
              </label>
              <input
                type="number"
                name="transit_duration_minute"
                className={`input-text ${
                  isEditOperation && !isScheduleManagement ? "opacity-50" : ""
                }`}
                disabled={isEditOperation && !isScheduleManagement}
                value={form.transit_duration_minute}
                onChange={handleTextChange}
                max={59}
              />
              {formError.transit_duration_minute && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.transit_duration_minute}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="intent" className="label">
            Operation Intent
          </label>
          <input
            type="text"
            name="intent"
            className="input-text"
            value={form.intent}
            onChange={handleTextChange}
          />
          {formError.intent && (
            <p className="text-red-500 text-sm mt-1">{formError.intent}</p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="operation_type" className="label">
            Type
          </label>
          <select
            className="input-select"
            name="operation_type"
            onChange={(e) => handleSelectChange(e, "operation_type")}
          >
            <option value="bvlos">BVLOS</option>
            <option value="vlos">VLOS</option>
          </select>
          {formError.operation_type && (
            <p className="text-red-500 text-sm mt-1">
              {formError.operation_type}
            </p>
          )}
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="description" className="label">
          Description
        </label>
        <textarea
          className="input-text"
          name="description"
          value={form.description}
          onChange={handleTextAreaChange}
        />
        {formError.description && (
          <p className="text-red-500 text-sm mt-1">{formError.description}</p>
        )}
      </div>
      {isAuthority && (
        <div className="mb-6">
          <label htmlFor="description" className="label">
            Clarification
          </label>
          <textarea
            className="input-text"
            name="clarification"
            onChange={(e: any) => setOperationClarification(e.target.value)}
          />
        </div>
      )}

      <div className="flex mt-4 mb-8 pb-8">
        {!isAuthority && (
          <Button
            type="light"
            size="medium"
            text="Reset"
            className="mr-2"
            disabled={isLoading}
            onClick={handleResetForm}
          />
        )}

        {hasOperationWriteRight && (
          <Button
            type="primaryDark"
            size="medium"
            text="Submit"
            isLoading={isLoading}
            onClick={handleSubmitOperation}
          />
        )}
      </div>
    </div>
  );
}

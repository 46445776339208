import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { MdMyLocation } from "react-icons/md";
import {
  FaMapMarkerAlt,
  FaPlaneArrival,
  FaPlaneDeparture,
} from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Waypoint from "@/model/Waypoint";
import LatLng from "@/model/LatLng";
import Button from "../Common/Button";
import "react-tooltip/dist/react-tooltip.css";

interface InputWaypointProps {
  allowMultiple?: boolean;
  keyLabel?: string;
  addButtonText?: string;
  useTakeOffLandingPoint?: boolean;
  pickFromMapButton?: boolean;
  waypointsData?: Waypoint[];
  latLabel?: string;
  lngLabel?: string;
  showRadius?: boolean;
  minWaypointCount?: number;
  handleOnDrawEmergencyPoint?: (value?: any) => void;
  onWaypointChanged: (waypoints: Waypoint[]) => void;
  onRadiusChanged?: (radius: number) => void;
  goToLocation?: (point: LatLng) => void;
  emergencyLanding?: number[];
}

export default function InputWaypoint({
  allowMultiple = true,
  keyLabel = "WP",
  addButtonText = "Add Waypoint",
  useTakeOffLandingPoint,
  pickFromMapButton = false,
  waypointsData,
  latLabel = "Latitude",
  lngLabel = "Longitude",
  showRadius = false,
  minWaypointCount,
  onWaypointChanged,
  onRadiusChanged,
  goToLocation,
  handleOnDrawEmergencyPoint,
  emergencyLanding,
}: InputWaypointProps) {
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

  const handleAddWaypointInput = () => {
    const middleLat =
      waypoints &&
      (waypoints[0].latitude + waypoints[waypoints.length - 1].latitude) / 2;
    const middleLng =
      waypoints &&
      (waypoints[0].longitude + waypoints[waypoints.length - 1].longitude) / 2;
    const newWaypoint = {
      position: Math.floor(Math.random() * 1000),
      latitude: middleLat || 0,
      longitude: middleLng || 0,
      isFirst: false,
      isLast: true,
    };
    // if useTakeOffLandingPoint is true, then add the new waypoint before the last current waypoints
    if (useTakeOffLandingPoint) {
      newWaypoint.isLast = false;

      const index = waypoints.length - 1;
      const newWaypoints = [
        ...waypoints.slice(0, index),
        newWaypoint,
        ...waypoints.slice(index),
      ];

      setWaypoints(newWaypoints);
      onWaypointChanged(newWaypoints);
      return;
    }

    setWaypoints([...waypoints, newWaypoint]);
    onWaypointChanged([...waypoints, newWaypoint]);
  };

  const handleRemoveWaypointInput = (position: number) => {
    if (useTakeOffLandingPoint && (position === 1 || position === 2)) return;
    if (useTakeOffLandingPoint && waypoints.length === 2) return;
    if (waypoints.length === 1) return;

    const newWaypoints = waypoints.filter(
      (waypoint) => waypoint.position !== position
    );

    setWaypoints(newWaypoints);
    onWaypointChanged(newWaypoints);
  };

  const handleGoToLocation = (position: number) => {
    const waypoint = waypoints.find((wp) => wp.position === position);
    if (waypoint?.latitude && waypoint?.longitude) {
      goToLocation?.({
        lat: waypoint.latitude,
        lng: waypoint.longitude,
      });
    }
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    position: number,
    type: string
  ) => {
    if (type === "radius" && onRadiusChanged) {
      const radiusValue = parseFloat(e.target.value);
      onRadiusChanged(radiusValue);
      return;
    }

    const newWaypoints = waypoints.map((waypoint) => {
      if (waypoint.position === position) {
        return {
          ...waypoint,
          [type]: parseFloat(e.target.value),
        };
      }

      return waypoint;
    });

    setWaypoints(newWaypoints);
    onWaypointChanged(newWaypoints);
  };

  const checkRemoveButtonDisabled = (waypoint: Waypoint) => {
    if (useTakeOffLandingPoint && (waypoint.isFirst || waypoint.isLast)) {
      return true;
    }

    if (minWaypointCount && waypoints.length <= minWaypointCount) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const initialWaypoints: Waypoint[] = [];
    if (waypointsData) {
      waypointsData?.forEach((waypoint) => {
        initialWaypoints.push({
          position: waypoint.position,
          latitude: waypoint.latitude,
          longitude: waypoint.longitude,
          radius: waypoint.radius,
          isFirst: waypoint.isFirst,
          isLast: waypoint.isLast,
        });
      });
    } else {
      initialWaypoints.push({
        position: 1,
        latitude: 0,
        longitude: 0,
        radius: 0,
        isFirst: true,
        isLast: true,
      });
    }

    setWaypoints(initialWaypoints);
  }, [waypointsData]);

  // update emergency landing point when click on map
  useEffect(() => {
    setWaypoints([
      {
        position: 1,
        latitude: emergencyLanding?.[1] ?? 0,
        longitude: emergencyLanding?.[0] ?? 0,
        radius: 0,
        isFirst: true,
        isLast: true,
      },
    ]);
  }, [emergencyLanding]);

  return (
    <div className="flex-col">
      <table className="table-auto w-full">
        <thead className="mb-2">
          <tr>
            {keyLabel !== "" && <th className="th">{keyLabel}</th>}
            <th className="th pl-2 pr-1">{latLabel}</th>
            <th className="th pl-2">{lngLabel}</th>
            {showRadius && <th className="th pl-4">Radius (KM)</th>}
            <th className="th">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {waypoints.map((waypoint, index) => (
            <tr key={waypoint.position}>
              {keyLabel !== "" && (
                <td>
                  {/* Use Takeoff Icon */}
                  {useTakeOffLandingPoint && waypoint.isFirst && (
                    <FaPlaneDeparture size={16} color="gray" />
                  )}
                  {/* Use Landing Icon */}
                  {useTakeOffLandingPoint && waypoint.isLast && (
                    <FaPlaneArrival size={16} color="gray" />
                  )}
                  {/* Use Number */}
                  {useTakeOffLandingPoint &&
                    !waypoint.isFirst &&
                    !waypoint.isLast && <span>{index}.</span>}
                  {/* Use Number */}
                  {!useTakeOffLandingPoint && <span>{index + 1}.</span>}
                </td>
              )}
              <td className="pr-1">
                <input
                  type="number"
                  className="input-text mr-2"
                  placeholder="Enter Latitude"
                  onChange={(e) =>
                    onInputChange(e, waypoint.position, "latitude")
                  }
                  value={waypoint.latitude}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="input-text"
                  placeholder="Enter Longitude"
                  onChange={(e) =>
                    onInputChange(e, waypoint.position, "longitude")
                  }
                  value={waypoint.longitude}
                />
              </td>
              {showRadius && (
                <td className="pl-2 pr-1">
                  <input
                    type="number"
                    className="input-text"
                    placeholder="Radius"
                    onChange={(e) =>
                      onInputChange(e, waypoint.position, "radius")
                    }
                    value={waypoint.radius}
                  />
                </td>
              )}
              <td className="flex items-center h-10">
                <button
                  id="goToLocationButton"
                  onClick={() => handleGoToLocation(waypoint.position)}
                  className="mx-1"
                  data-tooltip-id="tooltipGoToLocation"
                  data-tooltip-content="Open Location on Map"
                  data-tooltip-place="bottom"
                >
                  <MdMyLocation size={20} className="fill-blue-500" />
                </button>

                {pickFromMapButton && (
                  <button
                    id="pickFromMapButton"
                    onClick={(value) => handleOnDrawEmergencyPoint?.(value)}
                    className="mx-1"
                    data-tooltip-id="tooltipPickFromMap"
                    data-tooltip-content="Pick From The Map"
                    data-tooltip-place="bottom"
                  >
                    <FaMapMarkerAlt size={20} color="gray" />
                  </button>
                )}
                {allowMultiple && (
                  <button
                    id="removeWaypointButton"
                    onClick={() => handleRemoveWaypointInput(waypoint.position)}
                    className="mx-1"
                    disabled={checkRemoveButtonDisabled(waypoint)}
                  >
                    <IoMdCloseCircle
                      size={20}
                      className={
                        checkRemoveButtonDisabled(waypoint)
                          ? "fill-gray-200"
                          : "fill-gray-400"
                      }
                    />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {allowMultiple && (
        <Button
          text={addButtonText}
          type="light"
          size="small"
          onClick={handleAddWaypointInput}
          className="mt-4"
        />
      )}
      <Tooltip id="tooltipPickFromMap" />
      <Tooltip id="tooltipGoToLocation" />
    </div>
  );
}

import React, { useEffect } from "react";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";

interface SimulationListType {
  simulation_uuid: string;
  flight_simulation_uuid?: string;
  flight_simulation_eval?: string;
  mission_simulation_uuid?: string;
  date_created: string;
}

interface ItemCMFProps {
  simulationItem: SimulationListType;
  isDeleting?: boolean;
  isSelected?: boolean;
  copyOperationId?: (id: string) => void;
  onClick: (simulationUuid: string, isFlightSimulation: boolean) => void;
  onSelected: (id: string) => void;
}

export default function ItemSimulation({
  simulationItem,
  isDeleting,
  isSelected,
  copyOperationId,
  onClick,
  onSelected,
}: ItemCMFProps) {
  return (
    <div
      className="w-full rounded-container-np flex-col mb-4 cursor-pointer"
      // onClick={() => onClick(simulationItem.simulation_uuid)}
    >
      <div className="flex p-2 items-center">
        <h5 className="font-medium mb-1 grow text-ellipsis">
          Created:{" "}
          {moment(simulationItem.date_created).format("DD/MM/YYYY hh:mm a")}
        </h5>
        <input
          type="checkbox"
          className="input-checkbox"
          disabled={isDeleting}
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSelected(simulationItem.simulation_uuid)}
        />
      </div>
      <div className="flex px-2 py-4 border-t dark:border-gray-600">
        <div className="flex flex-col px-4 grow">
          <div className="flex items-center mb-1">
            <p className="text-sm text-ellipsis overflow-hidden mr-2 font-medium">
              Flight Evaluation: {simulationItem.flight_simulation_eval || ""}
            </p>
          </div>
          <div className="flex items-center mb-1">
            <p className="text-sm text-ellipsis overflow-hidden mr-2 font-medium">
              Flight Simulation:{" "}
              {simulationItem.flight_simulation_uuid ? (
                <span
                  className="px-2 item cursor-pointer"
                  onClick={() => onClick(simulationItem.simulation_uuid, true)}
                >
                  <span className="ml-1 text-blue-500 text-sm font-medium">
                    Available
                  </span>
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="flex items-center mb-1">
            <p className="text-sm text-ellipsis overflow-hidden mr-2 font-medium">
              Mission Simulation:{" "}
              {simulationItem.mission_simulation_uuid ? (
                <span
                  className="px-2 item cursor-pointer"
                  onClick={() => onClick(simulationItem.simulation_uuid, false)}
                >
                  <span className="ml-1 text-blue-500 text-sm font-medium">
                    Available
                  </span>
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="flex items-center mb-1">
            <p className="text-sm text-ellipsis overflow-hidden mr-2 font-medium">
              {simulationItem.simulation_uuid}
            </p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                copyOperationId &&
                  copyOperationId(simulationItem.simulation_uuid);
              }}
            >
              <FaRegCopy size={14} color="gray" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

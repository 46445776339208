import React, { useEffect } from "react";
import { Operation } from "@/model/api/Operation";
import { Schedule } from "@/model/api/Schedule";
import moment from "moment";
import { PiDrone } from "react-icons/pi";

interface ItemOperationScheduleProps {
  operation?: Operation;
  schedule?: Schedule;
  isOperation: boolean;
  isDeleting?: boolean;
  isSelected?: boolean;
  copyOperationId?: (id: string) => void;
  onClick: (uuid?: string) => void;
  onSelected: (id: string) => void;
}

export default function ItemOperationSchedule({
  operation,
  schedule,
  isOperation,
  isDeleting,
  isSelected,
  copyOperationId,
  onClick,
  onSelected,
}: ItemOperationScheduleProps) {
  return (
    <div
      className="w-full rounded-container-np flex-col mb-4 cursor-pointer"
      onClick={() =>
        onClick(
          isOperation
            ? operation?.operation_json.reference.id
            : schedule?.schedule_uuid
        )
      }
    >
      <div className="flex p-2 items-center">
        <h5 className="font-medium mb-1 grow text-ellipsis">
          {isOperation
            ? operation?.operation_json.reference.intent
            : schedule?.request.intent}
        </h5>
        {/* <input
          type="checkbox"
          className="input-checkbox"
          disabled={isDeleting}
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          // onChange={() => onSelected(weatherAnalysisListItem.simulation_uuid)}
        /> */}
      </div>
      <div className="flex px-2 py-4 border-t dark:border-gray-600">
        <div className="rounded-lg bg-green-200 dark:bg-gray-500 h-16 w-16 content-center items-center">
          <PiDrone
            size={28}
            className="m-auto fill-green-500 dark:fill-gray-400"
          />
        </div>
        <div className="flex flex-col px-4 grow">
          {isOperation && operation && (
            <div>
              <p className="text-sm">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("dddd")}
              </p>
              <p className="text-sm">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("DD/MM/YYYY")}
              </p>
            </div>
          )}
          {!isOperation && schedule && (
            <div>
              <p className="text-sm">
                {moment(schedule.request.time_start).format("dddd")}
              </p>
              <p className="text-sm">
                {moment(schedule.request.time_start).format("DD/MM/YYYY")}
              </p>
            </div>
          )}
        </div>
        {isOperation && operation && (
          <div className="flex-col content-end items-end justify-end">
            <div className="flex items-center content-center">
              <span className="text-sm">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("HH:mm A")}
              </span>
              <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
            </div>
            <div className="flex items-center content-center mt-5">
              <span className="text-sm">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_end.value
                ).format("HH:mm A")}
              </span>
              <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
            </div>
          </div>
        )}
        {!isOperation && schedule && (
          <div className="flex-col content-end items-end justify-end">
            <div className="flex items-center content-center">
              <span className="text-sm">
                {moment(schedule.request.time_start).format("HH:mm A")}
              </span>
              <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
            </div>
            <div className="flex items-center content-center mt-5">
              <span className="text-sm">
                {moment(schedule.request.time_end).format("HH:mm A")}
              </span>
              <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

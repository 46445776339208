import { GroupedOperator, OperatorUser } from "@/model/api/GroupedOperator";
import { useSelector } from "react-redux";
import axios from "axios";
import UserAccess from "@/model/UserAccess";
import { User } from "@/model/api/User";
import React, { useEffect, useState } from "react";
import { FaPlus, FaEnvelope } from "react-icons/fa";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
// import ItemOperator from "./ItemCard/ItemOperator";
import UserItem from "./ItemCard/ItemUser";
import UserForm from "./UserForm";

type UserRoles =
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot";

interface ItemOperationProps {
  api: any;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

interface InviteUserProps {
  handleSubmit: (userUuid: string) => void;
  onCloseModal: () => void;
}
function InviteUserModal({ handleSubmit, onCloseModal }: InviteUserProps) {
  const [userUuid, setUserUuid] = useState("");
  return (
    <div className="flex-col">
      <label className="label">Enter the UUID of User to Invite</label>
      <input
        type="text"
        className="input-text mb-2"
        placeholder="User Uuid"
        onChange={(e) => setUserUuid(e.target.value)}
      />

      <div className="flex">
        <Button
          type="primary"
          size="small"
          fixedWidth={true}
          className="w-32"
          text="Submit"
          onClick={() => handleSubmit(userUuid)}
        />
        <span className="grow" />
        <Button
          type="light"
          size="small"
          fixedWidth={true}
          className="w-32 mr-2"
          text="Cancel"
          onClick={onCloseModal}
        />
      </div>
    </div>
  );
}

export default function UserManagement({
  api,
  showMessage,
  onConfirmation,
  onOpenModal,
  onCloseModal,
}: ItemOperationProps) {
  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* Privilege Data */
  const [organisationAvailablePrivileges, setOrganisationAvailablePrivileges] =
    useState<string[]>([]);
  const [availableRolesForAssignment, setAvailableRolesForAssignment] =
    useState<UserRoles[]>([]);

  /* User Data */
  const [usersList, setUsersList] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>({
    user_uuid: "",
    username: "",
    given_name: null,
    user_roles: [],
    current_user_role: "",
    email: "",
    contact: null,
    date_created: "",
  });
  const [checkedBoxUsers, setCheckedBoxUsers] = useState<string[]>([]);

  const [isDeleting, setIsDeleting] = useState(false);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const handleAddNew = () => {
    setIsAdd(true);
  };

  const handleInviteUser = () => {
    const handleSubmitInvite = async (userUuid: string) => {
      showMessage && showMessage("Submitting invite");
      try {
        await api.inviteUserToOrganisation(userUuid);
        showMessage && showMessage("Invite sent successfully", true);
        onCloseModal();
      } catch (err) {
        let message = "Failed to send invite";
        if (axios.isAxiosError(err)) {
          message = err.response?.data?.message || err.message;
        }
        showMessage && showMessage(message, false, true);
      }
    };
    const content = (
      <InviteUserModal
        handleSubmit={handleSubmitInvite}
        onCloseModal={onCloseModal}
      />
    );
    onOpenModal("Invite User to Organisation", content, false, true);
  };

  const handleSelectUser = (user: User) => {
    setSelectedUser(user);
    setIsAdd(true);
  };
  const handleReturnToList = () => {
    setIsAdd(false);
    setSelectedUser({
      user_uuid: "",
      username: "",
      given_name: null,
      user_roles: [],
      current_user_role: "",
      email: "",
      contact: null,
      date_created: "",
    });
  };

  const handleCheckItem = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setCheckedBoxUsers([...checkedBoxUsers, id]);
    } else {
      setCheckedBoxUsers(checkedBoxUsers.filter((itemId) => itemId !== id));
    }
  };

  const handleSaveSuccessful = () => {
    setIsAdd(false);
    setSelectedUser({
      user_uuid: "",
      username: "",
      given_name: null,
      user_roles: [],
      current_user_role: "",
      email: "",
      contact: null,
      date_created: "",
    });
    setIsLoading(true);
    getUsers();
  };

  const handleDeleteUser = async () => {
    const deleteUser = async () => {
      setIsDeleting(true);
      showMessage && showMessage("Removing users... Please wait");

      try {
        let isAPIError = false;

        const promises: Promise<void>[] = checkedBoxUsers.map(
          async (username) => {
            let success = false;
            success = await api.expelUser(username);
            if (!success) {
              isAPIError = true;
            }
          }
        );

        await Promise.all(promises);
        if (isAPIError) {
          showMessage && showMessage("Error expelling users", false, true);
        } else {
          showMessage && showMessage("Users removed successfully", true);
        }

        setIsDeleting(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setIsDeleting(false);
          showMessage &&
            showMessage(
              "User does not have rights to modify asset",
              false,
              true
            );
        } else {
          setIsDeleting(false);
          showMessage && showMessage("Error deleting items", false, true);
        }
      }
      setCheckedBoxUsers([]);
      getUsers();
    };

    onConfirmation(
      "Expel Confirmation",
      "Are you sure you want to remove the selected user from the organsation?",
      (result) => {
        if (result) {
          deleteUser();
        }
      }
    );
  };

  const getUsers = async () => {
    try {
      const response = await api.getOrganisationUsers();
      if (response.status === 200) {
        setUsersList(response.data);
        setIsLoading(false);
      }
    } catch (e) {
      if (showMessage) {
        showMessage((e as any).message, false, true);
      }
    }
  };

  const getAvailableRoles = async () => {
    try {
      const response = await api.getOrganisationRoles();
      if (response.status === 200) {
        setAvailableRolesForAssignment(response.data);
        setIsLoading(false);
      }
    } catch (e) {
      if (showMessage) {
        showMessage((e as any).message, false, true);
      }
    }
  };

  const getAvailablePrivileges = async () => {
    try {
      const response = await api.getOrganisationPrivilege();
      if (response.data) {
        setOrganisationAvailablePrivileges(response.data);
      }
    } catch (e) {
      if (showMessage) {
        showMessage((e as any).message, false, true);
      }
    }
  };
  useEffect(() => {
    if (userAccess.role !== "administrator") return;
    getUsers();
    getAvailableRoles();
    getAvailablePrivileges();
  }, [userAccess]);

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>User Management</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            {/* Content */}
            <div className="h-[92%] flex-col overflow-auto p-4">
              {/* USer List */}
              {usersList &&
                usersList.map((singleUser) => (
                  <UserItem
                    key={singleUser.username}
                    data={singleUser}
                    onClick={handleSelectUser}
                    onSelect={handleCheckItem}
                  />
                ))}

              {/* No Data Available */}
              {!isLoading && usersList && usersList.length === 0 && (
                <p className="p-8 text-center">No Data Available</p>
              )}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          <div className="h-[10%] px-4 py-2 items-center content-center">
            {checkedBoxUsers.length > 0 &&
              userAccess.privileges.includes("general.organisation.write") && (
                <Button
                  type="danger"
                  size="medium"
                  text="Remove User from Organisation"
                  isLoading={isDeleting}
                  disabled={isDeleting}
                  onClick={handleDeleteUser}
                />
              )}
            {checkedBoxUsers.length === 0 &&
              userAccess.privileges.includes("general.user.write") && (
                <div className="w-full flex items-center">
                  <Button
                    className="mr-5"
                    type="primaryDark"
                    size="medium"
                    text="Create User"
                    icon={<FaPlus size={12} color="white" />}
                    onClick={handleAddNew}
                  />
                  <Button
                    type="primaryDark"
                    size="medium"
                    text="Invite User"
                    icon={<FaEnvelope size={12} color="white" />}
                    onClick={handleInviteUser}
                  />
                </div>
              )}
          </div>
        </div>
      )}
      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">Edit User</h5>
          </div>
          <hr />
          <UserForm
            api={api}
            user={selectedUser}
            showMessage={showMessage}
            onSaveSucess={handleSaveSuccessful}
            availableRolesForAssignment={availableRolesForAssignment}
            organisationAvailablePrivileges={organisationAvailablePrivileges}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
          />
        </div>
      )}
    </div>
  );
}

import Button from "@/components/v1/Common/Button";
import axios from "axios";
import MaintenanceRecord from "@/model/api/Maintenance";
import { useState } from "react";
import moment from "moment";

interface MaintenanceFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  maintenanceRecord?: MaintenanceRecord | null;
  platformUuid: string;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onSaveSucess?: () => void;
  hasWritePrivilege: boolean;
  isEditMode: boolean;
}

export default function MaintenanceForm({
  api,
  maintenanceRecord,
  platformUuid,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
  isEditMode,
}: MaintenanceFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    platform_uuid: platformUuid,
    maintenance_date:
      maintenanceRecord?.maintenance_date || moment().toISOString(),
    purpose: maintenanceRecord?.purpose || "",
    defect: maintenanceRecord?.defect || "",
    rectification: maintenanceRecord?.rectification || "",
    conducted_by: maintenanceRecord?.conducted_by || "",
  });

  const [formError, setFormError] = useState({
    platform_uuid: "",
    maintenance_date: "",
    purpose: "",
    defect: "",
    rectification: "",
    conducted_by: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = moment(e.target.value).toISOString();
    setForms({
      ...form,
      [e.target.id]: formattedDate,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      platform_uuid: "",
      maintenance_date: "",
      purpose: "",
      defect: "",
      rectification: "",
      conducted_by: "",
    };

    // try {
    //   if (form.expiry !== "") {
    //     const date = moment(form.expiry, "DD/MM/YYYY", true);

    //     if (!date.isValid()) {
    //       throw new Error("Invalid date");
    //     }
    //   }
    // } catch {
    //   error.expiry = "Invalid date";
    //   isValid = false;
    // }

    if (form.purpose === "") {
      error.purpose = "Maintenance reason is required";
      isValid = false;
    }
    if (form.conducted_by === "") {
      error.conducted_by = "Maintenance person is required";
      isValid = false;
    }
    if (!form.maintenance_date) {
      error.conducted_by = "Maintenance date is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async () => {
    // validate if form has an empty field
    // no longer able to create pilot, can only create user and update user/pilot
    // all fields optional for update
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      if (isEditMode) {
        if (!maintenanceRecord?.record_uuid) {
          throw new Error("Error updating record : unable to obtain record ID");
        }
        await api.updateMaintenance(maintenanceRecord?.record_uuid, form);
        setIsLoading(false);
        showMessage && showMessage("Maintenance record updated", true);
        onSaveSucess && onSaveSucess();
      } else {
        await api.submitNewMaintenance(form);
        setIsLoading(false);
        showMessage && showMessage("New maintenance record updated", true);
        onSaveSucess && onSaveSucess();
      }
    } catch (err) {
      let message = "Error obtaining maintenance data";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      setIsLoading(false);
      showMessage && showMessage(message, false, true);
    }
  };

  return (
    <div className="flex-col p-4 w-full" onClick={(e) => e.stopPropagation()}>
      <div className="mb-2">
        <h5 className="font-medium">
          Platform UUID:{" "}
          <span className="text-blue-500 text-sm">{form.platform_uuid}</span>
        </h5>
      </div>
      <div>
        <div className="mb-2">
          <label htmlFor="name" className="label">
            Purpose
          </label>
          <input
            type="text"
            id="purpose"
            className="input-text"
            defaultValue={form.purpose}
            onChange={onChange}
          />
          {formError.purpose && (
            <p className="text-red-500 text-sm mt-1">{formError.purpose}</p>
          )}
        </div>
        <div className="mb-2">
          <label htmlFor="name" className="label">
            Defect
          </label>
          <input
            type="text"
            id="defect"
            className="input-text"
            defaultValue={form.defect}
            onChange={onChange}
          />
          {formError.defect && (
            <p className="text-red-500 text-sm mt-1">{formError.defect}</p>
          )}
        </div>
        <div className="mb-2">
          <label htmlFor="username" className="label">
            Rectification
          </label>
          <input
            type="email"
            id="rectification"
            className="input-text"
            defaultValue={form.rectification}
            onChange={onChange}
          />
          {formError.rectification && (
            <p className="text-red-500 text-sm mt-1">
              {formError.rectification}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label htmlFor="username" className="label">
            Conducted By
          </label>
          <input
            type="text"
            id="conducted_by"
            className="input-text"
            defaultValue={form.conducted_by}
            onChange={onChange}
          />
          {formError.conducted_by && (
            <p className="text-red-500 text-sm mt-1">
              {formError.conducted_by}
            </p>
          )}
        </div>
        <div className="mb-6">
          <label htmlFor="username" className="label">
            Maintenance Date
          </label>
          <input
            type="datetime-local"
            id="maintenance_date"
            className="input-text"
            value={moment(form.maintenance_date).format("YYYY-MM-DDTHH:mm")}
            onChange={onChangeDate}
          />
          {formError.maintenance_date && (
            <p className="text-red-500 text-sm mt-1">
              {formError.maintenance_date}
            </p>
          )}
        </div>
      </div>

      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}

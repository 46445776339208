/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Auth } from "aws-amplify";
import LatLng from "@/model/LatLng";
import Button from "@/components/v1/Common/Button";
import {
  convertDatetoInputMask,
  convertInputMasktoISODate,
} from "@/utils/date_utils";
import UserAccess from "@/model/UserAccess";
import { Content, Permit } from "@/model/api/Permit";
import InputMask from "react-input-mask";
import moment from "moment";

interface PlatformFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  selectedPermitContent?: Content | null;
  goToLocation: (point: LatLng) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onSaveSucess: () => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function PermitForm({
  api,
  selectedPermitContent,
  goToLocation,
  showMessage,
  onSaveSucess,
}: PlatformFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileRaw, setFileRaw] = useState<File>();
  // const [inputWaypointConfiguration, setInputWaypointConfiguration] = useState({
  //   keyLabel: "WP",
  //   drawIcon: <MdTimeline className="fill-blue-500" />,
  //   drawLabel: "Draw Waypoint",
  //   showRadius: false,
  //   allowMultiple: true,
  //   useTakeOffLandingPoint: false,
  //   minWaypointCount: 3,
  //   latLabel: "Latitude",
  //   lngLabel: "Longitude",
  // });

  const [form, setForms] = useState({
    fileName: "",
    wayPoints: [],
    activityStart: "",
    permitExpiry: "",
    altitude: "",
    location: "",
  });

  const [formError, setFormError] = useState({
    fileName: "",
    activityStart: "",
    permitExpiry: "",
  });

  const inputButtonRef = useRef<HTMLInputElement>(null);
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const getPermitMetaData = async () => {
    if (!selectedPermitContent) return;
    try {
      setIsLoading(true);
      const permitMetaDataResponse = await api.getPermitMetaData(
        selectedPermitContent.Key
      );
      const permitMetaData = permitMetaDataResponse?.data?.metaData;
      const permitData = {
        fileName:
          selectedPermitContent.Key.split("/")[
            selectedPermitContent.Key.split("/").length - 1
          ].replace(".pdf", "") || "",
        wayPoints: permitMetaData?.waypoints || [],
        activityStart: permitMetaData?.activitystart
          ? convertDatetoInputMask(permitMetaData.activitystart)
          : "",
        permitExpiry: permitMetaData?.permitexpiry
          ? convertDatetoInputMask(permitMetaData.permitexpiry)
          : "",
        altitude: permitMetaData?.altitude || "",
        location: permitMetaData?.location || "",
      };
      setForms(permitData);
      setIsLoading(false);
    } catch {
      showMessage && showMessage("Error obtaining permit data ", false, true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!selectedPermitContent) return;
    getPermitMetaData();
  }, [selectedPermitContent]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      fileName: "",
      activityStart: "",
      permitExpiry: "",
    };

    if (form.fileName === "") {
      error.fileName = "File Name is required";
      isValid = false;
    }

    if (!form.activityStart) {
      error.activityStart = "Start Date is required";
      isValid = false;
    }

    if (!form.permitExpiry) {
      error.permitExpiry = "Expiry Date is required";
      isValid = false;
    }

    if (!fileRaw) {
      error.fileName = "No File selected";
      isValid = false;
    }

    try {
      if (form.activityStart !== "") {
        const date = moment(form.activityStart, "DD/MM/YYYY", true);

        if (!date.isValid()) {
          throw new Error("Invalid date");
        }
      }
    } catch {
      error.activityStart = "Invalid date";
      isValid = false;
    }

    try {
      if (form.permitExpiry !== "") {
        const date = moment(form.permitExpiry, "DD/MM/YYYY", true);

        if (!date.isValid()) {
          throw new Error("Invalid date");
        }
      }
    } catch {
      error.permitExpiry = "Invalid date";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleImport = (e: any) => {
    if (e) {
      const file = e?.target.files[0];
      setFileRaw(file);
      // file.length > 0 && setFileBase64(e.target.result);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onloadend = (e2) => {
      //   setFileBase64(e2.target.result);
      // };
    }
  };

  const handleClickUpload = () => {
    if (inputButtonRef.current) {
      inputButtonRef.current.click();
    }
  };

  const handleDeleteImportedFile = () => {
    setFileRaw(undefined);
  };

  const handleDownloadPermit = async () => {
    if (!selectedPermitContent) return;
    try {
      const response = await api.getPermitDownloadURL(
        selectedPermitContent.Key
      );
      const URLResponse = response.data;
      window.open(URLResponse.uploadURL, "_blank");
      onSaveSucess();
    } catch {
      showMessage && showMessage("Unable to obtain permit data", false, true);
    }
  };

  const handleUploadPermit = async () => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }

    const activityStart = convertInputMasktoISODate(form.activityStart);
    const permitExpiry = convertInputMasktoISODate(form.permitExpiry);

    const getURL = async () => {
      if (!fileRaw) return;
      const user = await Auth.currentAuthenticatedUser();
      const fileName = `${form.fileName}.pdf`;
      showMessage && showMessage("Uploading permit");
      setIsLoading(true);
      try {
        const response = await api.getPermitUploadURL({
          loginUser: user.username,
          fileName,
          wayPoints: form.wayPoints && JSON.stringify(form.wayPoints),
          activityStart: form.activityStart && activityStart,
          permitExpiry: form.permitExpiry && permitExpiry,
          altitude: form.altitude && form.altitude,
          location: form.location && form.location,
        });
        // const URLResponse = JSON.parse(response.data);
        const URLResponse = response.data;
        const putResponse = await axios.put(URLResponse.uploadURL, fileRaw, {
          headers: { "Content-Type": "application/pdf" },
        });
        if (putResponse.statusText === "OK") {
          showMessage && showMessage("Permit uploaded sucessfully", true);
          setIsLoading(false);
          onSaveSucess();
        }
      } catch {
        showMessage && showMessage("Permit uploaded failed", false, true);
        setIsLoading(false);
      }
    };
    getURL();
  };

  // const handleWaypointChange = (waypoints: Waypoint[]) => {
  //   // handleOperationFormWaypointChange(waypoints, operationType);
  //   // setOperationWaypoints(waypoints);
  //   console.log("Waypoint Changed ", waypoints);
  // };

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="name" className="label">
          File Name
        </label>
        <input
          type="text"
          id="fileName"
          className="input-text"
          defaultValue={form.fileName}
          onChange={onChange}
        />
        {formError.fileName && (
          <p className="text-red-500 text-sm mt-1">{formError.fileName}</p>
        )}
      </div>

      {!fileRaw ? (
        <div className="mb-2">
          {!selectedPermitContent && (
            <p
              onClick={handleClickUpload}
              className="ml-1 text-blue-500 text-sm font-medium"
            >
              Select PDF File
              <input
                type="file"
                onChange={handleImport}
                hidden
                ref={inputButtonRef}
                accept=".pdf"
              />
            </p>
          )}
        </div>
      ) : (
        <div className="flex mb-2">
          <p
            onClick={handleDeleteImportedFile}
            className="ml-1 text-red-500 text-sm font-medium"
          >
            x{" "}
          </p>
          <p className="ml-1 text-blue-500 text-sm font-medium truncate">
            {fileRaw.name}
          </p>
        </div>
      )}
      {/* <div className="mb-2">
        <label htmlFor="username" className="label">
          Waypoints
        </label>
        <div className="rounded-container p-2 mb-4">
          <InputWaypoint
            keyLabel={inputWaypointConfiguration.keyLabel}
            allowMultiple={inputWaypointConfiguration.allowMultiple}
            showRadius={inputWaypointConfiguration.showRadius}
            minWaypointCount={inputWaypointConfiguration.minWaypointCount}
            latLabel={inputWaypointConfiguration.latLabel}
            lngLabel={inputWaypointConfiguration.lngLabel}
            useTakeOffLandingPoint={
              inputWaypointConfiguration.useTakeOffLandingPoint
            }
            // waypointsData={[[0][0]]}
            onWaypointChanged={handleWaypointChange}
            goToLocation={goToLocation}
          />
        </div>
      </div> */}
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Location
        </label>
        <input
          type="text"
          id="location"
          className="input-text"
          defaultValue={form.location}
          onChange={onChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Altitude
        </label>
        <input
          type="text"
          id="altitude"
          className="input-text"
          defaultValue={form.altitude}
          onChange={onChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Start Date Time (Local Time)
        </label>
        <InputMask
          id="activityStart"
          mask="99/99/9999"
          maskChar={null}
          placeholder="dd/mm/yyyy"
          className="input-text"
          value={form.activityStart}
          onChange={onChange}
        />
        {formError.activityStart && (
          <p className="text-red-500 text-sm mt-1">{formError.activityStart}</p>
        )}
      </div>
      <div className="mb-5">
        <label htmlFor="username" className="label">
          End Date Time (Local Time)
        </label>
        <InputMask
          id="permitExpiry"
          mask="99/99/9999"
          maskChar={null}
          placeholder="dd/mm/yyyy"
          className="input-text"
          value={form.permitExpiry}
          onChange={onChange}
        />
        {formError.permitExpiry && (
          <p className="text-red-500 text-sm mt-1">{formError.permitExpiry}</p>
        )}
      </div>
      {userAccess.privileges.includes("assets.permit.write") &&
        !selectedPermitContent && (
          <Button
            type="primaryDark"
            isLoading={isLoading}
            text="Upload Permit"
            onClick={handleUploadPermit}
          />
        )}
      {selectedPermitContent && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Download Permit"
          onClick={handleDownloadPermit}
        />
      )}
    </div>
  );
}

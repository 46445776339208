/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from "react";
import { getPrivilegeText } from "./getPrivilegeText";

type UserRolesArr = (
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot"
)[];

interface UserFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onSaveSucess: (newPrivileges: string[]) => void;
  userPrivileges: string[];
  handleClose: () => void;
  organisationAvailablePrivileges: string[];
  hasWritePrivilegeRights: boolean;
}

export default function PrivilegeForm({
  onSaveSucess,
  userPrivileges,
  handleClose,
  organisationAvailablePrivileges,
  hasWritePrivilegeRights,
}: UserFormProps) {
  const [
    organisationAvailablePrivilegesSorted,
    setOrganisationAvailablePrivilegesSorted,
  ] = useState<string[]>([]);
  const [currentUserPrivileges, setCurrentUserPrivileges] =
    useState<string[]>(userPrivileges);

  const previousPrivilegeCategory = useRef<string>("");

  const onChange = (singlePrivilege: string, isChecked: boolean) => {
    let tempCurrentUserPrivileges = [...currentUserPrivileges];

    if (isChecked) {
      tempCurrentUserPrivileges.push(singlePrivilege);
      const isWriteOperation =
        singlePrivilege.split(".")[singlePrivilege.split(".").length - 1] ===
        "write";

      // enables read scope if write scope is enabled
      if (isWriteOperation) {
        const readPrivilege = singlePrivilege.replace(".write", ".read");
        if (!tempCurrentUserPrivileges.includes(readPrivilege)) {
          tempCurrentUserPrivileges.push(readPrivilege);
        }
      }
    } else {
      tempCurrentUserPrivileges = tempCurrentUserPrivileges.filter(
        (currentPrivilege) => singlePrivilege !== currentPrivilege
      );
      const isReadOperation =
        singlePrivilege.split(".")[singlePrivilege.split(".").length - 1] ===
        "read";

      // disables write scope if read scope is disabled
      if (isReadOperation) {
        const writePrivilege = singlePrivilege.replace(".read", ".write");
        if (tempCurrentUserPrivileges.includes(writePrivilege)) {
          tempCurrentUserPrivileges = tempCurrentUserPrivileges.filter(
            (currentPrivilege) => writePrivilege !== currentPrivilege
          );
        }
      }
    }

    setCurrentUserPrivileges(tempCurrentUserPrivileges);
  };

  useEffect(() => {
    // group same type privileges together
    const priviledgeSort = (a: any, b: any) => {
      const aSegments = a.split(".");
      const bSegments = b.split(".");
      if (aSegments.length < bSegments.length) {
        return -1;
      }
      if (aSegments.length > bSegments.length) {
        return 1;
      }
      return 0;
    };
    const sortedPrivileges = organisationAvailablePrivileges;
    sortedPrivileges.sort(priviledgeSort);
    setOrganisationAvailablePrivilegesSorted(sortedPrivileges);
  }, [organisationAvailablePrivileges]);

  const getTooltipText = (singlePrivilege: string) => {
    const privilegeCategorySplit = singlePrivilege.split(".");
    privilegeCategorySplit.pop();
    const privilegeCategory = privilegeCategorySplit.join(".");
    if (privilegeCategory === previousPrivilegeCategory.current) return;
    previousPrivilegeCategory.current = privilegeCategory;
    const descriptionText = getPrivilegeText(privilegeCategory);
    return (
      <div className="mt-2">
        <p className="mt-2">{descriptionText}</p>
        <hr />
      </div>
    );
  };
  return (
    <div className="h-[70vh] flex flex-col p-4 overflow-auto">
      <div className="fixed flex w-full px-4">
        {hasWritePrivilegeRights && (
          <button onClick={() => onSaveSucess(currentUserPrivileges)}>
            <span className="text-primary-600 text-sm font-medium">
              Save Changes
            </span>
          </button>
        )}
        <span className="w-[40%]" />
        <button onClick={handleClose}>
          <span className="text-red-600 text-sm font-medium">
            Discard Changes
          </span>
        </button>
      </div>
      <div className="absolute h-[60vh] w-[85%] flex flex-col overflow-auto mt-10">
        {organisationAvailablePrivilegesSorted.map(
          (singlePrivilege: string) => {
            return (
              <div
                key={singlePrivilege}
                className="w-full flex-col cursor-pointer"
              >
                {getTooltipText(singlePrivilege)}
                <div className="rounded-container flex p-4 mb-1">
                  <span className="grow mx-2">{singlePrivilege}</span>

                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={singlePrivilege}
                      className="sr-only peer"
                      checked={currentUserPrivileges.includes(singlePrivilege)}
                      onChange={(e) =>
                        onChange(e.target.value, e.target.checked)
                      }
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  </label>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { FaPlus, FaArrowAltCircleDown } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosMore,
} from "react-icons/io";
import UserAccess from "@/model/UserAccess";
import { Operation, OperationJson } from "@/model/api/Operation";
import { Schedule, ScheduleForm } from "@/model/api/Schedule";
import Pilot from "@/model/api/Pilot";
import LatLng from "@/model/LatLng";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import Waypoint from "@/model/Waypoint";
import { CreateOperationType } from "@/enum/CreateOperationType";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import FlightPlan from "@/model/FlightPlan";
import { setOperationFormRequest } from "@/store/actions";
import moment from "moment";
import { IoEllipsisHorizontal } from "react-icons/io5";
import {
  handleKmlImport,
  handleJsonImport,
  downloadKML,
} from "@/services/json";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import ItemOperation from "./ItemCards/ItemOperation";
import ItemSchedule from "./ItemCards/ItemSchedule";
import CreateFlightPlanForm from "./CreateFlightPlanForm";
import CreateScheduleForm from "./CreateScheduleForm";
import RerouteRescheduleDialog from "../../Dialog/RerouteRescheduleDialog";

interface UserAccessState {
  userAccess: UserAccess;
}

interface FlightAuthorizationSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  /* Route Operation */
  selectedWaypoint: number[][];
  /* Polygon Operation */
  selectedAreaWaypoints: number[][];
  /* Circle Operation */
  selectedCircleWaypoints: number[][];
  setSelectedCircleWaypoints: (waypoints: number[][]) => void;
  selectedCircleProperties: SelectedCircleProperties;
  setSelectedCircleProperties: (properties: SelectedCircleProperties) => void;
  /*  */
  setIsFormOpen: (value: boolean) => void;
  onWaypointUpdated: (waypoints: number[][]) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  onCloseSidebar: () => void;
  handleSetEmergencyLanding: (waypoint: [number, number]) => void;
  emergencyLanding: number[];
  handleOperationFormWaypointChange: (
    formData: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => void;
  handleEditOperation: (operationId: string) => void;
  isAuthority?: boolean;
  isScheduleManagement?: boolean;
  handleSetSelectedWaypoints?: (waypoints: number[][]) => void;
  handleScheduleManagementDrawOutline?: (
    isClearDrawings: boolean,
    schedule?: Schedule
  ) => void;
}

const getOperationLimit = 20;
const getScheduleLimit = 30;

export default function FlightAuthorizationSidebar({
  api,
  selectedWaypoint,
  selectedCircleWaypoints,
  selectedAreaWaypoints,
  // setSelectedCircleWaypoints,
  selectedCircleProperties,
  // setSelectedCircleProperties,
  setIsFormOpen,
  onWaypointUpdated,
  onConfirmation,
  showMessage,
  goToLocation,
  onOpenModal,
  onCloseModal,
  onCloseSidebar,
  handleSetEmergencyLanding,
  emergencyLanding,
  handleOperationFormWaypointChange,
  handleEditOperation,
  isAuthority,
  isScheduleManagement,
  handleSetSelectedWaypoints,
  handleScheduleManagementDrawOutline,
}: FlightAuthorizationSidebarProps) {
  const [selectedOption, setSelectedOption] = useState("Route");
  const [isShoreToShipOperation, setIsShoreToShipOperation] = useState(false);
  const [selectedOperationType, setSelectedOperationType] =
    useState<CreateOperationType>(CreateOperationType.ROUTE);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditOperation, setIsEditOperation] = useState(false);

  /* Operation Data */
  const [upcomingOperations, setUpcomingOperations] = useState<Operation[]>([]);
  const [pastpOperations, setPastOperations] = useState<Operation[]>([]);
  const [operations, setOperations] = useState<Operation[]>([]);

  /* Schedule Data */
  const [upcomingSchedules, setUpcomingSchedules] = useState<Schedule[]>([]);
  const [pastSchedules, setPastSchedules] = useState<Schedule[]>([]);
  const [schedules, setSchedule] = useState<Schedule[]>([]);
  const [scheduleForm, setScheduleForm] = useState<ScheduleForm>({
    anchorage_details: {
      anchorage_id: "",
      anchorage_name: "",
    },
    vessel_details: {
      vessel_imo: "",
      vessel_mmsi: "",
      vessel_callsign: "",
      vessel_name: "",
    },
    unisphere_weather_simulation: false,
  });

  const [scheduleFormError, setScheduleFormError] = useState({
    anchorage_id: "",
    vessel_imo: "",
  });

  /* Assets */
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  /* Revision Dialog State */
  const [revisionAction, setRevisionAction] = useState({
    isDiscard: false,
    isEdit: false,
    isResubmit: false,
    isAccepted: false,
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [lastRefreshTime, setLastRefreshTime] = useState<string>("");
  // used to enable download more operations/scheddule button
  const [isDownloadMoreOperationsEnabled, setIsDownloadMoreOperationsEnabled] =
    useState(false);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const inputButtonRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const operationFormRequest = useSelector(
    (state: RootStateOrAny) => state.operations.operationFormRequest
  );

  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);
    setSelectedIds([]);
    setShowMoreOptions(false);

    if (option === 1) {
      fetchOperations(true);
    } else if (isScheduleManagement) {
      setSchedule(option === 0 ? upcomingSchedules : pastSchedules);
    } else {
      setOperations(option === 0 ? upcomingOperations : pastpOperations);
    }
  };

  const handleSelectedOption = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setIsShoreToShipOperation(false);

    switch (option) {
      case "Route":
        setSelectedOperationType(CreateOperationType.ROUTE);
        break;
      case "Circle":
        setSelectedOperationType(CreateOperationType.CIRCLE);
        break;
      case "Polygon":
        setSelectedOperationType(CreateOperationType.POLYGON);
        break;
      case "Shore to Ship":
        setIsShoreToShipOperation(true);
        setSelectedOperationType(CreateOperationType.ROUTE);
        break;
      default:
        setSelectedOperationType(CreateOperationType.ROUTE);
    }
  };

  const handleOperationClicked = (operation: Operation) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Operation</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.intent}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Departure</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Arrival</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_end.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPilotName(operation.operation_json.request.pilot_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Platform</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPlatformName(
                  operation.operation_json.request.platform_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Tracker</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getTrackerName(
                  operation.operation_json.request.tracker_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Status</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.details.state}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Operation ID</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.id}
              </td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Created On</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.reference.time_created.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Operation Detail", content);
  };

  /* Schedule Management */

  const handleScheduleClicked = (schedule: Schedule) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Operation</td>
              <th>:</th>
              <td className="px-4 py-2">{schedule.request.intent}</td>
            </tr>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Anchorage Name</td>
              <th>:</th>
              <td className="px-4 py-2">
                {schedule.request.anchorage_details.anchorage_name}
              </td>
            </tr>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Anchorage Id</td>
              <th>:</th>
              <td className="px-4 py-2">
                {schedule.request.anchorage_details.anchorage_id}
              </td>
            </tr>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Vessel Name</td>
              <th>:</th>
              <td className="px-4 py-2">
                {schedule.request.vessel_details.vessel_name}
              </td>
            </tr>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Vessel IMO</td>
              <th>:</th>
              <td className="px-4 py-2">
                {schedule.request.vessel_details.vessel_imo}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Departure</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(schedule.request.time_start).format(
                  "DD/MM/YYYY HH:mm A"
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Arrival</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(schedule.request.time_end).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPilotName(schedule.request.pilot_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Platform</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPlatformName(schedule.request.platform_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Tracker</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getTrackerName(schedule.request.tracker_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Status</td>
              <th>:</th>
              <td className="px-4 py-2">{schedule.schedule_state}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Schedule ID</td>
              <th>:</th>
              <td className="px-4 py-2">{schedule.schedule_uuid}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Schedule Detail", content);
  };

  const handleScheduleSelectTakeOffLandingPoint = (
    coordinate: number[][],
    isTakeOff: boolean
  ) => {
    if (selectedOption !== "Route" && selectedOption !== "Shore to Ship")
      return;
    const currentWaypoint = selectedWaypoint;
    let updatedWaypoint;
    if (isTakeOff) {
      currentWaypoint.length > 1 && currentWaypoint.shift();
      updatedWaypoint = [...coordinate, ...currentWaypoint];
    } else {
      currentWaypoint.length > 1 && currentWaypoint.pop();
      updatedWaypoint = [...currentWaypoint, ...coordinate];
    }
    // handleOperationFormWaypointChange(updatedWaypoint, CreateOperationType.ROUTE);
    handleSetSelectedWaypoints && handleSetSelectedWaypoints(updatedWaypoint);
  };

  const handlePromoteScheduleClick = () => {
    const content = (
      <div className="flex-col">
        <label className="label mb-5">
          Continue with Vessel in Anchorage Verification?
        </label>
        <div className="flex">
          <Button
            type="primaryDark"
            size="small"
            fixedWidth={true}
            className="w-32 mr-5"
            text="Continue"
            onClick={() => handlePromoteSchedule(false)}
          />
          <span className="grow" />
          <Button
            type="light"
            size="small"
            fixedWidth={true}
            className="w-full"
            text="Continue with Verfication Disabled"
            onClick={() => handlePromoteSchedule(true)}
          />
        </div>
      </div>
    );
    onOpenModal("Promote Operation", content, true, true);
  };

  const handlePromoteSchedule = (skipVesselCheck: boolean) => {
    const operationPromises = selectedIds.map((operationId) =>
      api.promoteSchedule(operationId, skipVesselCheck)
    );
    showMessage && showMessage("Promoting Schedule, Please Wait");
    onCloseModal();
    setIsDisplaying(true);

    Promise.all(operationPromises)
      .then(() => {
        showMessage && showMessage("Operation Promoted", true);
        setSelectedIds([]);
      })
      .catch((e) => {
        const message =
          e?.response?.data?.message || "Unable to Promote Operation";
        showMessage(message, false, true);
      })
      .finally(() => {
        fetchOperations();
        setIsDisplaying(false);
      });
  };

  const handleOperationSelected = (operationId: string) => {
    const index = selectedIds.indexOf(operationId);
    if (index === -1) {
      setSelectedIds([...selectedIds, operationId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== operationId));
    }
    setShowMoreOptions(false);
  };

  const handleDeleteSchedule = () => {
    setShowMoreOptions(false);

    onConfirmation(
      "Delete Schedule",
      "Are you sure you want to delete the selected schedule? Action cannot be undone",
      (result) => {
        if (result) {
          const operationPromises = selectedIds.map((scheduleId) =>
            api.deleteSchedule(scheduleId)
          );

          setIsDeleting(true);
          showMessage("Deleting Schedule, Please Wait");

          Promise.all(operationPromises)
            .then(() => {
              showMessage && showMessage("Schedule Deleted", true);
              setSelectedIds([]);
              fetchOperations(selectedTab === 1);
            })
            .catch(() => {
              showMessage("Unable to Delete Schedule", false, true);
            })
            .finally(() => setIsDeleting(false));
        }
      }
    );
  };

  const handleEditScheduleClick = async () => {
    setShowMoreOptions(false);
    if (selectedIds.length !== 1) {
      showMessage && showMessage("Select 1 schedule", false, true);
      return;
    }
    setIsEditOperation(true);
    const selectedScheduleDetails = schedules.filter((singleSchedule) => {
      return singleSchedule.schedule_uuid === selectedIds[0];
    });
    if (!selectedScheduleDetails.length) {
      showMessage && showMessage("Unable to obtain schedule data", false, true);
      return;
    }
    const { request } = selectedScheduleDetails[0];
    dispatch(
      setOperationFormRequest({
        id: selectedScheduleDetails[0].schedule_uuid,
        type: "edit",
        request,
      })
    );
    setScheduleForm({
      anchorage_details: {
        anchorage_id: request.anchorage_details.anchorage_id,
        anchorage_name: request.anchorage_details.anchorage_name,
      },
      vessel_details: {
        vessel_imo: request.vessel_details.vessel_imo,
        vessel_mmsi: request.vessel_details.vessel_mmsi,
        vessel_callsign: request.vessel_details.vessel_callsign,
        vessel_name: request.vessel_details.vessel_name,
      },
      unisphere_weather_simulation: request.unisphere_weather_simulation,
    });
  };

  const handleExportScheduleKML = () => {
    try {
      const selectedScheduleDetails = schedules.filter((singleSchedule) => {
        return selectedIds.includes(singleSchedule.schedule_uuid);
      });
      selectedScheduleDetails.forEach((singleSelectedSchedule) => {
        if (singleSelectedSchedule.request.waypoints) {
          const alt = singleSelectedSchedule.request.altitude;
          const waypoints = singleSelectedSchedule.request.waypoints.map(
            (singleWaypoint) => {
              return {
                lon: singleWaypoint[0],
                lat: singleWaypoint[1],
                alt,
              };
            }
          );
          const scheduleWaypoints = {
            operation_json: {
              details: { waypoints },
            },
          };
          downloadKML(
            scheduleWaypoints,
            singleSelectedSchedule.request.intent,
            singleSelectedSchedule.request.intent
          );
        }
      });
    } catch {
      showMessage && showMessage("Error downloading kml file", false, true);
    }
  };

  /* Schedule Management End */

  const handleDeleteOperation = () => {
    setShowMoreOptions(false);

    onConfirmation(
      "Delete Operation",
      "Are you sure you want to delete the selected operation? Action cannot be undone",
      (result) => {
        if (result) {
          const operationPromises = selectedIds.map((operationId) =>
            api.deleteOperation(operationId)
          );

          setIsDeleting(true);
          showMessage("Deleting Operation, Please Wait");

          Promise.all(operationPromises)
            .then(() => {
              showMessage && showMessage("Operation Deleted", true);
              setSelectedIds([]);
              fetchOperations(selectedTab === 1);
            })
            .catch(() => {
              showMessage("Unable to Delete Operation", false, true);
            })
            .finally(() => setIsDeleting(false));
        }
      }
    );
  };

  const handleDisplayOperation = () => {
    const operationPromises = selectedIds.map((operationId) =>
      api.addOperationSelection(operationId)
    );

    setIsDisplaying(true);

    Promise.all(operationPromises)
      .then(() => {
        showMessage && showMessage("Operation Displayed", true);
        setSelectedIds([]);
      })
      .catch(() => {
        showMessage("Unable to Display Operation", false, true);
      })
      .finally(() => setIsDisplaying(false));
  };

  const handleAddNew = () => {
    // clear waypoints, used in scenerio where user click schedule then create new schedule/operation
    if (selectedOperationType === CreateOperationType.CIRCLE) {
      onWaypointUpdated([[0, 0]]);
    } else if (selectedOperationType === CreateOperationType.ROUTE) {
      onWaypointUpdated([
        [0, 0],
        [0, 0],
      ]);
    } else if (selectedOperationType === CreateOperationType.POLYGON) {
      onWaypointUpdated([
        [0, 0],
        [0, 0],
      ]);
    }
    setIsAdd(true);
    setIsFormOpen(true);
  };

  /* Import Operation */
  const handleImportButtonClick = () => {
    if (inputButtonRef.current) {
      inputButtonRef.current.click();
    }
  };

  const handleImportOperation = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<string | undefined> => {
    try {
      if (!e.target.files) return "";
      const [file] = e.target.files;
      e.target.files = null; // Clear the input field
      const importFile = (reader: FileReader) => {
        return new Promise((resolve) => {
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
          if (file) {
            reader.readAsText(file);
          }
        });
      };
      if (file.type === "application/json") {
        const reader = new FileReader();
        const rawData = await importFile(reader);
        const data = typeof rawData === "string" ? JSON.parse(rawData) : "";
        handleJsonImport(data, dispatch);
      } else if (file.type === "") {
        const reader = new FileReader();
        const kmlData = await importFile(reader);
        handleKmlImport(kmlData, dispatch);
      } else {
        showMessage &&
          showMessage("Import must be a JSON or KML file.", false, true);
      }
    } catch {
      showMessage("Invalid file", false, true);
    }
  };

  useEffect(() => {
    if (!operationFormRequest) return;
    if (operationFormRequest.type === "edit") {
      setIsAdd(true);
      setIsFormOpen(true);
      setIsEditOperation(true);
    }
    if (operationFormRequest.type === "duplicate") {
      setIsAdd(true);
      setIsFormOpen(true);
    }
    // change tab based on operation type (route, circle, area)
    if (operationFormRequest?.isShipToShoreOperation) {
      handleSelectedOption("Shore to Ship");
      if (operationFormRequest?.request?.anchorage_details) {
        const { request } = operationFormRequest;
        setScheduleForm({
          anchorage_details: {
            anchorage_id: request?.anchorage_details?.anchorage_id,
            anchorage_name: request?.anchorage_details?.anchorage_name,
          },
          vessel_details: {
            vessel_imo: request?.vessel_details?.vessel_imo,
            vessel_mmsi: request?.vessel_details?.vessel_mmsi,
            vessel_callsign: request?.vessel_details?.vessel_callsign,
            vessel_name: request?.vessel_details?.vessel_name,
          },
          unisphere_weather_simulation: request?.unisphere_weather_simulation,
        });
      }
    } else if (!operationFormRequest?.request?.properties) {
      handleSelectedOption("Route");
    } else if (operationFormRequest?.request?.properties?.isCircle) {
      handleSelectedOption("Circle");
    } else {
      handleSelectedOption("Polygon");
    }
  }, [operationFormRequest]);

  /* End of Import Operation */

  /* Editing Operation */
  const handleEditOperationClick = async () => {
    setIsEditOperation(true);
    await handleEditOperation(selectedIds[0]);
    setShowMoreOptions(false);
    setIsAdd(true);
    setIsFormOpen(true);
  };

  const handleReturnToList = () => {
    setIsAdd(false);
    setIsEditOperation(false);
    setIsFormOpen(false);
    dispatch(setOperationFormRequest(null));
    setRevisionAction({
      isDiscard: false,
      isEdit: false,
      isResubmit: false,
      isAccepted: false,
    });
    if (isAuthority) onCloseModal();
  };

  const handleOnOperationSubmitted = () => {
    setIsAdd(false);
    setIsEditOperation(false);
    setIsFormOpen(false);
    onWaypointUpdated([]);
    dispatch(setOperationFormRequest(null));
    fetchOperations();
    setRevisionAction({
      isDiscard: false,
      isEdit: false,
      isResubmit: false,
      isAccepted: false,
    });
    if (isAuthority) onCloseModal();
  };

  const handleOnOperationRevision = (
    isRerouted: boolean,
    isRescheduled: boolean,
    operationData: OperationJson,
    flightPlan: FlightPlan
  ) => {
    const content = (
      <RerouteRescheduleDialog
        isRerouted={isRerouted}
        isRescheduled={isRescheduled}
        compareData={operationData}
        flightPlan={flightPlan}
        onAcceptChanges={() => handleRevisionDialogAction("isAccepted")}
        onDiscardPlan={() => handleRevisionDialogAction("isDiscard")}
        onResubmitPlan={() => handleRevisionDialogAction("isResubmit")}
        onEditPlan={() => handleRevisionDialogAction("isEdit")}
      />
    );

    onOpenModal("Operation Was Re Routed", content, false, true);
  };

  const handleRevisionDialogAction = (action: string) => {
    setRevisionAction({
      ...revisionAction,
      [action]: true,
    });
    onCloseModal();
  };

  // lock schedule management to only waypoint ops
  // undo once area schedule implemented
  const options = isScheduleManagement
    ? ["Route"]
    : [
        "Route",
        "Circle",
        "Polygon",
        "Shore to Ship",
        // "Upload Mission (Coming Soon)",
      ];

  const fetchAdditionalUpcomingOperationsSchedules = async () => {
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);
    try {
      if (isScheduleManagement) {
        const currentSchedules = upcomingSchedules;
        const timeStamp = new Date().getTime();
        const allSchedules = await api.getSchedules(
          timeStamp,
          currentSchedules.length
        );
        if (allSchedules.error || allSchedules.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          if (allSchedules.data.length !== getScheduleLimit) {
            setIsDownloadMoreOperationsEnabled(false);
          }
          const combinedSchedules = [...currentSchedules, ...allSchedules.data];
          const allSchedulesSorted = combinedSchedules.sort(
            (a: Schedule, b: Schedule) => {
              const timeStartA = new Date(a.request.time_start);
              const timeStartB = new Date(b.request.time_start);
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );

          setUpcomingSchedules(allSchedulesSorted);
          setSchedule(allSchedulesSorted);
        }
      } else {
        const currentOperations = upcomingOperations;
        const upcomingResponse = await api.getOperations({
          offset: currentOperations.length,
          isOperations: true,
        });
        if (upcomingResponse.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          if (upcomingResponse.data.length !== getOperationLimit) {
            setIsDownloadMoreOperationsEnabled(false);
          }
          const combinedUpcomingOperations = [
            ...currentOperations,
            ...upcomingResponse.data,
          ];
          const UpcomingOperationsSorted = combinedUpcomingOperations.sort(
            (a: Operation, b: Operation) => {
              const timeStartA = new Date(
                a.operation_json.interuss.operational_intent_reference.time_start.value
              );
              const timeStartB = new Date(
                b.operation_json.interuss.operational_intent_reference.time_start.value
              );
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );

          setUpcomingOperations(UpcomingOperationsSorted);
          setOperations(UpcomingOperationsSorted);
        }
      }
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
    setIsLoading(false);
  };

  const fetchOperations = async (all?: boolean) => {
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);
    try {
      if (isScheduleManagement) {
        const timeStamp = new Date().getTime();
        const upcomingSchedules = await api.getSchedules(
          timeStamp,
          false,
          false,
          false
        );
        const allSchedules = await api.getSchedules();
        if (allSchedules.error || upcomingSchedules.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          const allSchedulesSorted = allSchedules.data.sort(
            (a: Schedule, b: Schedule) => {
              const timeStartA = new Date(a.request.time_start);
              const timeStartB = new Date(b.request.time_start);
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );
          const upcomingSchedulesFiltered = upcomingSchedules.data.sort(
            (a: Schedule, b: Schedule) => {
              const timeStartA = new Date(a.request.time_start);
              const timeStartB = new Date(b.request.time_start);
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );
          if (upcomingSchedulesFiltered.length === getScheduleLimit) {
            setIsDownloadMoreOperationsEnabled(true);
          }
          setUpcomingSchedules(upcomingSchedulesFiltered);
          setPastSchedules(allSchedulesSorted);
          if (all) {
            setSchedule(allSchedulesSorted);
          } else {
            setSchedule(upcomingSchedulesFiltered);
          }
        }
      } else {
        const upcomingResponse = await api.getOperations({
          isOperations: true,
        });
        const isPastResponse = await api.getOperations({
          isPast: true,
          isOperations: true,
        });
        if (upcomingResponse.error || isPastResponse.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          const allOperations = [
            ...upcomingResponse.data,
            ...isPastResponse.data,
          ];
          const UpcomingOperationsSorted = upcomingResponse.data.sort(
            (a: Operation, b: Operation) => {
              const timeStartA = new Date(
                a.operation_json.interuss.operational_intent_reference.time_start.value
              );
              const timeStartB = new Date(
                b.operation_json.interuss.operational_intent_reference.time_start.value
              );
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );
          const allOperationsSorted = allOperations
            .sort((a: Operation, b: Operation) => {
              const timeStartA = new Date(
                a.operation_json.interuss.operational_intent_reference.time_start.value
              );
              const timeStartB = new Date(
                b.operation_json.interuss.operational_intent_reference.time_start.value
              );
              return timeStartB.getTime() - timeStartA.getTime();
            })
            .reduce((acc: Operation[], current: Operation) => {
              const key = current.operation_json.reference.id;

              if (!acc.some((op) => op.operation_json.reference.id === key)) {
                acc.push(current);
              }

              return acc;
            }, []);

          if (UpcomingOperationsSorted.length === getOperationLimit) {
            setIsDownloadMoreOperationsEnabled(true);
          }

          const upcomingOperationsRemoveEnded = UpcomingOperationsSorted.filter(
            (singleOperation: Operation) =>
              singleOperation.operation_json.details.state !== "Ended"
          );
          setPastOperations(allOperationsSorted);
          setUpcomingOperations(upcomingOperationsRemoveEnded);
          if (all) {
            setOperations(allOperationsSorted);
          } else {
            setOperations(upcomingOperationsRemoveEnded);
          }
        }
      }
      const currentTime = moment(new Date()).format("HH:mm A");

      setLastRefreshTime(currentTime);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const fetchPilots = async () => {
    if (userAccess.privileges.includes("assets.pilot.read")) {
      const response = await api.getPilots();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Pilots Data", false, true);
        setIsError(true);
      } else {
        setPilots(response.data);
      }
    }
  };

  const fetchPlatforms = async () => {
    if (userAccess.privileges.includes("assets.platform.read")) {
      const response = await api.getPlatforms();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Platforms Data", false, true);
      } else {
        setPlatforms(response.data);
      }
    }
  };

  const fetchTrackers = async () => {
    if (userAccess.privileges.includes("assets.tracker.read")) {
      const response = await api.getTracker();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Trackers Data", false, true);
      } else {
        setTrackers(response.data);
      }
    }
  };

  const copyOperationId = (operationId: string) => {
    navigator.clipboard.writeText(operationId);
    showMessage && showMessage("Operation ID Copied", true);
  };

  const getPilotName = (pilotId: string) => {
    const pilot = pilots.find((p) => p.pilot_uuid === pilotId);
    return pilot ? pilot.pilot_username : "Unknown Pilot";
  };

  const getPlatformName = (platformId: string) => {
    const platform = platforms.find((p) => p.platform_uuid === platformId);
    return platform ? platform.platform_callsign : "Unknown Platform";
  };

  const getTrackerName = (trackerId: string) => {
    const tracker = trackers.find((t) => t.tracker_uuid === trackerId);
    return tracker ? tracker.tracker_name : "Unknown Tracker";
  };

  const resetScheduleForm = () => {
    setScheduleForm({
      anchorage_details: {
        anchorage_id: "",
        anchorage_name: "",
      },
      vessel_details: {
        vessel_imo: "",
        vessel_mmsi: "",
        vessel_callsign: "",
        vessel_name: "",
      },
      unisphere_weather_simulation: false,
    });
    setScheduleFormError({
      anchorage_id: "",
      vessel_imo: "",
    });
  };

  useEffect(() => {
    if (api) {
      setIsLoading(true);
      fetchPilots().then(() => fetchOperations());
      fetchPlatforms();
      fetchTrackers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    return () => {
      // used to clear schedule drawings when different tab selected
      handleScheduleManagementDrawOutline &&
        handleScheduleManagementDrawOutline(true);
    };
  }, []);

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>
                {isScheduleManagement
                  ? "Schedule Management"
                  : "Flight Authorization"}
              </h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            <div className="flex-col px-4">
              {/* Main Tab */}
              <div className="tab-rounded mt-2">
                <span
                  className={`${selectedTab === 0 && "tab-active"} mr-1`}
                  onClick={() => handleSelectedTab(0)}
                >
                  Upcoming
                </span>
                <span
                  className={`${selectedTab === 1 && "tab-active"} ml-1`}
                  onClick={() => handleSelectedTab(1)}
                >
                  All
                </span>
              </div>
              <div className="flex px-4 py-2 mt-2">
                <h5 className="font-medium ml-2">
                  {isScheduleManagement ? "Schedules" : "Operations"}
                </h5>
                {isDownloadMoreOperationsEnabled && (
                  <button onClick={fetchAdditionalUpcomingOperationsSchedules}>
                    <FaArrowAltCircleDown
                      size={14}
                      color="#3b82f6"
                      className="ml-1"
                    />
                  </button>
                )}
                <span className="grow" />
                {/* <button
                  onClick={() => showMessage && showMessage("Coming Soon")}
                >
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Sort by : Date
                  </span>
                </button> */}
                <button onClick={() => fetchOperations(selectedTab === 1)}>
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Last Refreshed : {lastRefreshTime}
                  </span>
                </button>
              </div>
            </div>
            {/* Content */}
            <div className="h-[80%] flex-col overflow-auto p-4">
              {/* Operation List */}
              {!isLoading &&
                !isScheduleManagement &&
                operations
                  // .filter((operation) => {
                  //   if (selectedTab === 1) return true;
                  //   return operation.is_upcoming === true;
                  // })
                  .map((operation) => (
                    <ItemOperation
                      key={operation.operation_json.reference.id}
                      operation={operation}
                      pilotName={getPilotName(
                        operation.operation_json.request.pilot_uuid[0]
                      )}
                      trackerName={getTrackerName(
                        operation.operation_json.request.tracker_uuid[0]
                      )}
                      isDeleting={isDeleting}
                      isSelected={selectedIds.includes(
                        operation.operation_json.reference.id
                      )}
                      copyOperationId={copyOperationId}
                      onClick={handleOperationClicked}
                      onSelected={handleOperationSelected}
                    />
                  ))}

              {/* No Data Available */}
              {!isLoading &&
                operations.length === 0 &&
                !isScheduleManagement && (
                  <p className="p-8 text-center">No Data Available</p>
                )}

              {/* Operation List */}
              {!isLoading &&
                isScheduleManagement &&
                schedules.map((schedule) => (
                  <ItemSchedule
                    key={schedule.schedule_uuid}
                    schedule={schedule}
                    pilotName={getPilotName(schedule.request.pilot_uuid[0])}
                    trackerName={getTrackerName(
                      schedule.request.tracker_uuid[0]
                    )}
                    isDeleting={isDeleting}
                    isSelected={selectedIds.includes(schedule.schedule_uuid)}
                    copyOperationId={copyOperationId}
                    onClick={handleScheduleClicked}
                    onSelected={handleOperationSelected}
                    handleScheduleManagementDrawOutline={
                      handleScheduleManagementDrawOutline
                    }
                  />
                ))}

              {/* No Data Available */}
              {!isLoading && schedules.length === 0 && isScheduleManagement && (
                <p className="p-8 text-center">No Data Available</p>
              )}
              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          <div className="h-[10%] px-4 py-2 items-center content-center">
            {isScheduleManagement &&
              !userAccess.privileges.includes("schedules.write") && (
                <Button
                  type={selectedIds.length === 0 ? "light" : "primaryDark"}
                  size="medium"
                  text="Export KML"
                  className="mr-2"
                  isLoading={isDisplaying}
                  disabled={
                    isDeleting || isDisplaying || selectedIds.length === 0
                  }
                  onClick={handleExportScheduleKML}
                />
              )}
            {selectedIds.length > 0 ? (
              <div className="flex">
                {isScheduleManagement &&
                  userAccess.privileges.includes("schedules.write") && (
                    <Button
                      type={
                        selectedTab === 0 &&
                        userAccess.privileges.includes("operations.write")
                          ? "primaryDark"
                          : "light"
                      }
                      size="medium"
                      text="Promote Schedule"
                      className="mr-2"
                      isLoading={isDisplaying}
                      disabled={
                        isDeleting ||
                        isDisplaying ||
                        selectedTab === 1 ||
                        !userAccess.privileges.includes("operations.write")
                      }
                      onClick={handlePromoteScheduleClick}
                    />
                  )}
                {!isScheduleManagement && (
                  <Button
                    type="primaryDark"
                    size="medium"
                    text="Display"
                    className="mr-2"
                    isLoading={isDisplaying}
                    disabled={isDeleting || isDisplaying}
                    onClick={handleDisplayOperation}
                  />
                )}
                {((!isScheduleManagement &&
                  userAccess.privileges.includes("operations.write")) ||
                  (isScheduleManagement &&
                    userAccess.privileges.includes("schedules.write"))) && (
                  <Button
                    type="light"
                    size="medium"
                    fixedWidth={true}
                    className="mr-2 w-20"
                    isLoading={isDisplaying || isDeleting}
                    icon={<IoEllipsisHorizontal size={24} />}
                    onClick={() => setShowMoreOptions(!showMoreOptions)}
                    // isLoading={isDeleting}
                    // disabled={isDeleting || isDisplaying}
                    // onClick={handleDeleteOperation}
                  />
                )}
              </div>
            ) : (
              <div className="flex mt-4">
                {!isScheduleManagement && (
                  <button
                    className="mr-4 btn-primary-dark w-full h-12"
                    onClick={handleImportButtonClick}
                  >
                    Import
                    <input
                      type="file"
                      onChange={handleImportOperation}
                      hidden
                      ref={inputButtonRef}
                    />
                  </button>
                )}

                {((!isScheduleManagement &&
                  userAccess.privileges.includes("operations.write")) ||
                  (isScheduleManagement &&
                    userAccess.privileges.includes("schedules.write"))) && (
                  <Button
                    type="primaryDark"
                    size="medium"
                    text={
                      isScheduleManagement
                        ? "Create Schedule"
                        : "Create Flight Plan"
                    }
                    icon={<FaPlus size={12} color="white" />}
                    onClick={handleAddNew}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            {isScheduleManagement ? (
              <h5 className="font-medium ml-2">Create Schedule</h5>
            ) : (
              <h5 className="font-medium ml-2">Create Flight Plan</h5>
            )}
          </div>
          <hr />

          <h5 className="font-medium ml-6 mt-4 mb-2">
            Select Flight Plan Type
          </h5>
          {/* Dropdown Option Picker */}
          <div className="relative px-4 mb-4">
            <button
              className="input-select"
              type="button"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span className="grow">{selectedOption}</span>
              {isDropdownOpen ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </button>

            {isDropdownOpen && (
              <div className="absolute top-14 w-[95%] z-[150]">
                <ul className="dropdown-ul">
                  {options.map((option) => (
                    <li
                      key={option}
                      className="dropdown-li"
                      onClick={() => handleSelectedOption(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {(isScheduleManagement || isShoreToShipOperation) && (
            <div className="px-4">
              <h5 className="font-medium mt-4 mb-1">
                Vessel and Anchorage Details
              </h5>
              <div className="rounded-container p-2 mb-4">
                <CreateScheduleForm
                  scheduleForm={scheduleForm}
                  setScheduleForm={setScheduleForm}
                  handleScheduleSelectTakeOffLandingPoint={
                    handleScheduleSelectTakeOffLandingPoint
                  }
                  scheduleFormError={scheduleFormError}
                  isRouteOperation={
                    selectedOption === "Route" ||
                    selectedOption === "Shore to Ship"
                  }
                />
              </div>
            </div>
          )}

          {/* Form */}
          <CreateFlightPlanForm
            api={api}
            selectedWaypoint={selectedWaypoint}
            selectedAreaWaypoints={selectedAreaWaypoints}
            selectedCircleWaypoint={selectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            pilots={pilots}
            platforms={platforms}
            trackers={trackers}
            operationType={selectedOperationType}
            revisionAction={revisionAction}
            showMessage={showMessage}
            onConfirmation={onConfirmation}
            goToLocation={goToLocation}
            onOperationSubmitted={handleOnOperationSubmitted}
            onOperationRevision={handleOnOperationRevision}
            onCloseSidebar={onCloseSidebar}
            handleSetEmergencyLanding={handleSetEmergencyLanding}
            emergencyLanding={emergencyLanding}
            operationFormRequest={operationFormRequest}
            handleOperationFormWaypointChange={
              handleOperationFormWaypointChange
            }
            isEditOperation={isEditOperation}
            setIsEditOperation={setIsEditOperation}
            isAuthority={isAuthority}
            hasOperationWriteRight={userAccess.privileges.includes(
              "operations.write"
            )}
            isScheduleManagement={isScheduleManagement}
            scheduleForm={scheduleForm}
            resetScheduleForm={resetScheduleForm}
            setScheduleFormError={setScheduleFormError}
            isShoreToShipOperation={isShoreToShipOperation}
          />
        </div>
      )}

      {/* Button Options */}
      {showMoreOptions && (
        <div
          className={`w-40 ${
            isScheduleManagement ? "h-27" : "h-18"
          } absolute bottom-40 right-6 popup-menu`}
        >
          {isScheduleManagement ? (
            <ul className="flex-col">
              <li onClick={handleExportScheduleKML}>Export KML</li>
              <li onClick={handleEditScheduleClick}>Edit</li>
              <li onClick={handleDeleteSchedule}>Delete</li>
            </ul>
          ) : (
            <ul className="flex-col">
              <li onClick={handleEditOperationClick}>Edit</li>
              <li onClick={handleDeleteOperation}>Delete</li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

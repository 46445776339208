import moment from "moment";

const formatToDisplayDate = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const getFutureDate = (minutes: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

const convertDatetoInputMask = (date: string) => {
  try {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      throw new Error("Invalid date");
    }

    const formattedDate = moment(date).format("DD/MM/YYYY");
    return formattedDate;
  } catch {
    return "";
  }
};

const convertInputMasktoISODate = (dateString: string) => {
  if (!dateString) return "";
  const newDate = moment(dateString, "DD/MM/YYYY", true).utc().toISOString();
  return newDate;
};

export {
  formatToDisplayDate,
  getFutureDate,
  convertDatetoInputMask,
  convertInputMasktoISODate,
};
